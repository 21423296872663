import React, { useEffect, useState } from 'react';
// import Image from 'next/image';
// import BannerBackground from '../../publicimages_p/banner-img.png';
import styles from './slider.module.css';

const Hero = ({ isMobileView }: any) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const totalSlides = 3;
  const slideDuration = 10; // Duration for each slide in seconds
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    let animationTimeout: any;
    let slideTimeout = setTimeout(() => {
      setIsAnimating(true); // Start the exit animation
      animationTimeout = setTimeout(() => {
        setActiveSlide((prevSlide) => (prevSlide + 1) % totalSlides);
        // setActiveSlide(2);
        setIsAnimating(false); // End the exit animation, start the enter animation
      }, 800); // This timeout should match your CSS animation duration
    }, slideDuration * 1000); // Start the exit animation a bit before changing slides

    return () => {
      clearTimeout(slideTimeout);
      clearTimeout(animationTimeout);
    };
  }, [activeSlide]);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const safari = /^((?!chrome|android).)*safari/i.test(userAgent);
    setIsSafari(safari);
  }, []);

  const handleProgressBarClick = (index: number) => {
    setActiveSlide(index); // Set active slide to the clicked index
  };

  const handleRedirect = () => {
    window.location.href = 'https://app.primetrader.com/auth/signup';
  };

  return (
    <>
      {isMobileView ? (
        <section className='mob-hero'>
          <div className='liner-shade'>
            <div className='mob-container'>
              <div className='hero-wrapper' id='overview'>
                <div className='banner-img'>
                  <img
                    src={'/images_p/hero-section-light.webp'}
                    alt='banner-img'
                    fetchpriority='high'
                  />
                </div>
                <div className='txt-box'>
                  <h2>The Future of Crypto Trading</h2>
                  <h1>PrimeTrader</h1>

                  <div className={styles['es-slider']}>
                    <div className={styles['es-slides-container']}>
                      {/* Slide 1 */}
                      <div
                        key={0}
                        className={`slide ${0 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}
                        style={{
                          display: activeSlide === 0 ? 'block' : 'none'
                        }}
                      >
                        <div className='content-box'>
                          <h4>Gamified On-Chain Social Trading</h4>
                          <p>
                            Discover the thrill of gamified social trading. Dive
                            into engaging trading challenges, innovative
                            learn-to-earn rewards, and exclusive co-investment
                            opportunities for unparalleled growth potential.
                          </p>
                        </div>
                      </div>

                      {/* Slide 2 */}
                      <div
                        key={1}
                        className={`slide ${1 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}
                        style={{
                          display: activeSlide === 1 ? 'block' : 'none'
                        }}
                      >
                        <div className='content-box'>
                          <h4>Easiest Financial Onboarding</h4>
                          <p>
                            Step into the future of crypto and Web3 effortlessly
                            with our Smart NextGen Wallet Solution. Enjoy
                            seamless dApp interactions, free from the
                            complexities of gas fees and nonces.
                          </p>
                        </div>
                      </div>

                      {/* Slide 3 */}
                      <div
                        key={2}
                        className={`slide ${2 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}
                        style={{
                          display: activeSlide === 2 ? 'block' : 'none'
                        }}
                      >
                        <div className='content-box'>
                          <h4>Trading Powered by Innovation</h4>
                          <p>
                            Revolutionize your trading with our AI-powered
                            Virtual Trader Assistant: voice-activated trades,
                            tailored news, and analysis, all customized to your
                            portfolio and trading behavior.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`slider-bar ${isSafari ? 'safari' : ''}`}>
                    <div className={styles['es-progress-bars-container']}>
                      {Array.from({ length: totalSlides }).map((_, index) => (
                        <div
                          key={index}
                          className={styles['es-progress-bar-container']}
                          onClick={() => handleProgressBarClick(index)}
                        >
                          <div
                            className={`${styles['es-progress-bar']} ${index === activeSlide ? styles['es-slide-active'] : styles['es-slide-inactive']}`}
                            style={{
                              animationDuration: `${index === activeSlide ? slideDuration : 0}s`
                            }}
                          ></div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <a href='#' className='banner-btn'>
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className='main-banner' id='overview'>
          <div className='liner-shade'>
            <div className='container'>
              <div
                className='txt-box'
                style={{
                  scale: 0.8,
                  marginLeft: -63
                }}
              >
                <div className='content-box' style={{ marginBottom: 28 }}>
                  <h2
                    style={{
                      fontSize: '42.4px',
                      marginBottom: '-4px',
                      marginLeft: 9
                    }}
                  >
                    The Future of Crypto Trading
                  </h2>
                  <h1
                    style={{
                      letterSpacing: '3px',
                      fontWeight: '500'
                    }}
                  >
                    PrimeTrader
                  </h1>
                </div>
                <div className={styles['es-slider']}>
                  <div className={styles['es-slides-container']}>
                    {/* Slide 1 */}
                    <div
                      key={0}
                      className={`slide ${0 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}
                      style={{
                        display: activeSlide === 0 ? 'block' : 'none'
                      }}
                    >
                      <div className='content-box'>
                        <h4>Gamified On-Chain Social Trading</h4>
                        <p>
                          Discover the thrill of gamified social trading. Dive
                          into engaging trading challenges, innovative
                          learn-to-earn rewards, and exclusive co-investment
                          opportunities for unparalleled growth potential.
                        </p>
                      </div>
                    </div>

                    {/* Slide 2 */}
                    <div
                      key={1}
                      className={`slide ${1 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}
                      style={{
                        display: activeSlide === 1 ? 'block' : 'none'
                      }}
                    >
                      <div className='content-box'>
                        <h4>Easiest Financial Onboarding</h4>
                        <p>
                          Step into the future of crypto and Web3 effortlessly
                          with our Smart NextGen Wallet Solution. Enjoy seamless
                          dApp interactions, free from the complexities of gas
                          fees and nonces.
                        </p>
                      </div>
                    </div>

                    {/* Slide 3 */}
                    <div
                      key={2}
                      className={`slide ${2 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}
                      style={{
                        display: activeSlide === 2 ? 'block' : 'none'
                      }}
                    >
                      <div className='content-box'>
                        <h4>Trading Powered by Innovation</h4>
                        <p>
                          Revolutionize your trading with our AI-powered Virtual
                          Trader Assistant: voice-activated trades, tailored
                          news, and analysis, all customized to your portfolio
                          and trading behavior.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`slider-bar ${isSafari ? 'safari' : ''}`}>
                  <div className={styles['es-progress-bars-container']}>
                    {Array.from({ length: totalSlides }).map((_, index) => (
                      <div
                        key={index}
                        className={styles['es-progress-bar-container']}
                        onClick={() => handleProgressBarClick(index)}
                      >
                        <div
                          className={`${styles['es-progress-bar']} ${index === activeSlide ? styles['es-slide-active'] : styles['es-slide-inactive']}`}
                          style={{
                            animationDuration: `${index === activeSlide ? slideDuration : 0}s`
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className='LaunchNavBtn'
                  style={{
                    width: '244px',
                    marginTop: '50px'
                  }}
                  onClick={handleRedirect}
                >
                  <label style={{ fontSize: '18px' }}>Get Started</label>
                </div>
              </div>
              <div className='banner-img'>
                <img
                  src={'/images/hero_hand_coin/Avalanche_3D.webp'}
                  alt='banner-img'
                  loading='eager'
                  className='Avalanche_3D'
                />
                <img
                  src={'/images/hero_hand_coin/Bitcoin_3D.webp'}
                  alt='banner-img'
                  loading='eager'
                  className='Bitcoin_3D'
                />
                <img
                  src={'/images/hero_hand_coin/Polygon_3D.webp'}
                  alt='banner-img'
                  loading='eager'
                  className='Polygon_3D'
                />
                <img
                  src={'/images/hero_hand_coin/USD Coin_3D.webp'}
                  alt='banner-img'
                  loading='eager'
                  className='USD_Coin_3D'
                />
                <img
                  src={'/images/hero_hand_coin/USD Coin_3D (1).webp'}
                  alt='banner-img'
                  loading='eager'
                  className='USD_Coin_3D_1'
                />
                <img
                  src={'/images/hero_hand_coin/Solana_3D.webp'}
                  alt='banner-img'
                  loading='eager'
                  className='Solana_3D'
                />
                <img
                  src={'/images_p/hero-section-img.webp'}
                  alt='banner-img'
                  loading='lazy'
                  className='hand'
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Hero;
