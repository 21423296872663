import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

const CounterNumber = ({ targetNumber, title }: any) => {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    setCurrentNumber(0);

    let start = 0;
    const end = targetNumber;

    if (start === end) return;

    const totalDuration = 2000;
    let increment = Math.max(1, Math.floor(end / totalDuration) * 10);

    const updateCounter = () => {
      if (start < end) {
        start += increment;
        if (start > end) {
          start = end;
        }
        setCurrentNumber(start);

        const remaining = end - start;
        let incrementTime = totalDuration / (remaining + 10);
        incrementTime = Math.max(10, incrementTime);

        setTimeout(updateCounter, incrementTime);
      }
    };

    updateCounter();
  }, [targetNumber]);

  const displayNumber = () => {
    if (currentNumber >= 1_000_000) {
      return `${(currentNumber / 1_000_000).toFixed(1)}M+`;
    } else if (currentNumber >= 1_000) {
      return `${(currentNumber / 1_000).toFixed(1)}K+`;
    }
    return currentNumber.toLocaleString();
  };

  return (
    <div className={styles.count}>
      <div className={styles.number} data-animate='true'>
        <span>{displayNumber()}</span>
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default CounterNumber;
