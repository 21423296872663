import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import CounterNumber from '@/components/PtNumbers/CounterNumber';

const PtNumbers = () => {
  const [stats, setStats] = useState({
    total_transactions: 0,
    total_active_wallets: 0,
    total_staked: '0',
    total_claimed: '0'
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(
          'https://tempprod.primetrader.com/api/v1/stats/dashboard'
        );
        if (response.ok) {
          const data = await response.json();
          setStats(data.data);
        } else {
          console.error('Failed to fetch stats:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, []);

  return (
    <>
      <section className={styles.PtWrapper}>
        <div className={styles.linerShade}>
          <div className={styles.container}>
            <div className={styles.stats}>
              <CounterNumber
                targetNumber={
                  stats.total_active_wallets
                    ? stats.total_active_wallets + 2760
                    : stats.total_active_wallets
                }
                title={'Active Wallets'}
              />
            </div>
            <div className={styles.stats}>
              <CounterNumber
                targetNumber={stats.total_transactions}
                title={'Transactions'}
              />
            </div>
            <div className={styles.stats}>
              <CounterNumber
                targetNumber={parseInt(stats.total_staked)}
                title={'Tokens Staked'}
              />
            </div>
            <div className={styles.stats}>
              <CounterNumber
                targetNumber={parseInt(stats.total_claimed)}
                title={'Claimed Tokens'}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PtNumbers;
