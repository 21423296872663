import React, {
  Suspense,
  useRef,
  useState,
  useEffect,
  useLayoutEffect
} from 'react';
import { Canvas } from '@react-three/fiber';
import {
  Html,
  CameraControls,
  PerspectiveCamera,
  Stage
} from '@react-three/drei';
import { metadata } from './data';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const Body = React.lazy(() => import('./Body'));
const Cloth = React.lazy(() => import('./Cloth'));
const Face = React.lazy(() => import('./Face'));
const Head = React.lazy(() => import('./Head'));

import { LeftSidebar } from './LeftSidebar';
import { RightSidebar } from './RightSidebar';
import { usePreloadAssets } from './Hooks/usePreloadAssets';
import { useAssets } from './Hooks/useAssets';
import {
  preloadArm,
  preloadHeadGear,
  preloadClothing,
  preloadFaces
} from './assetsPreLoading';
import styles from '../../styles/builder/nftbuildermob.module.scss';
import { LeftSidebarMob } from './LeftSidebarMob';
import { RightSidebarMob } from './RightSidebarMob';
import dynamic from 'next/dynamic';
// import ResetModal from './ResetModal/ResetModal';
const ResetModal = dynamic(() => import('./ResetModal/ResetModal'), {
  ssr: false
});
const AssetLoading = () => {
  return (
    <div style={{ zIndex: '999', pointerEvents: 'none' }}>
      <span className='loader'></span>
      <h4 className='loaderText'>Loading Assets..</h4>
    </div>
  );
};

export const Scene = (props) => {
  const controlsRef = useRef();
  const cameraRef = useRef();
  const canvasRef = useRef();

  const [character, setCharacter] = useState('');
  const [characterCode, setCharacterCode] = useState('');
  const [category, setCategory] = useState('Characters');
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [selectedButton, setSelectedButton] = useState(null);
  const [categoryItem, setCategoryType] = useState(null);

  const [characterSelectionScreen, setCharacterSelectionScreen] =
    useState(true);
  const [leftSidebar, setLeftSidebar] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [scene, setScene] = useState(false);
  const [resetDialogue, setResetDialogue] = useState(false);

  const [loadingAssets, setLoadingAssets] = useState(false);

  const [loadedCharacters, setLoadedCharacters] = useState({});
  const [loadedFaces, setLoadedFaces] = useState({});
  const [loadedArm, setLoadedArm] = useState({});
  const [loadedHeadGear, setLoadedHeadGear] = useState({});
  const [loadedClothing, setLoadedClothing] = useState({});

  const getFaceScene = (key) => loadedFaces[key];
  const getArmScene = (key) => loadedArm[key];
  const getHeadGearScene = (key) => loadedHeadGear[key];
  const getClothingScene = (key) => loadedClothing[key];

  const [isMobile, setIsMobile] = useState(false);
  const [isResetModalOpen, setResetModalOpen] = useState(false);

  const openResetModal = () => {
    setResetModalOpen(true);
  };

  const closeResetModal = () => {
    setResetModalOpen(false);
  };

  const handleModalReset = () => {
    setCategory('Characters');
    handleResetState();
    closeResetModal();
  };

  const isNextDisabled =
    characterSelectionScreen || categoryIndex >= metadata.categories.length - 1;

  const isLastCharacter = categoryIndex === metadata.categories.length - 1;

  let initialPosition = isMobile ? -170 : -170;
  let initialZoom = isMobile ? 1.5 : 1.8;

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
      initialPosition = isMobile ? -210 : -170;
      initialZoom = isMobile ? 1 : 1.8;
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log('-- character', character, categoryIndex);
  }, [character, categoryIndex]);

  useEffect(() => {
    const preloadDefaultAssets = async () => {
      setLoadingAssets(true);
      await Promise.all([
        // Preload assets for Wesley
        preloadFaces(metadata.Characters[0].default.Face, setLoadedFaces),
        preloadHeadGear(
          metadata.Characters[0].default['Head Gear'],
          setLoadedHeadGear
        ),
        preloadArm(metadata.Characters[0].default.Arm, setLoadedArm),
        preloadClothing(
          metadata.Characters[0].default.Clothing,
          setLoadedClothing
        ),
        //For rodrgio
        preloadFaces(metadata.Characters[1].default.Face, setLoadedFaces),
        preloadHeadGear(
          metadata.Characters[1].default['Head Gear'],
          setLoadedHeadGear
        ),
        preloadArm(metadata.Characters[1].default.Arm, setLoadedArm),
        preloadClothing(
          metadata.Characters[1].default.Clothing,
          setLoadedClothing
        )
      ]);
      setLoadingAssets(false);
    };
    // Call the function to preload
    preloadDefaultAssets();
  }, []);
  const handleSelectedChange = () => {
    if (category == 'Characters') {
      setSelectedButton(null);
    } else if (category == 'Face') {
      setSelectedButton(
        metadata[category].findIndex((item) => item.key === face?.path)
      );
    } else if (category == 'Head Gear') {
      setSelectedButton(
        metadata[category].findIndex((item) => item.key === head?.path)
      );
    } else if (category == 'Arm') {
      setSelectedButton(
        metadata[category].findIndex((item) => item.key === body?.path)
      );
    } else if (category == 'Clothing') {
      setSelectedButton(
        metadata[category].findIndex((item) => item.key === cloth?.path)
      );
    }
  };
  const handleSelectedChangeFn = (value) => {
    if (!value.includes(characterCode)) {
      setCharacterCode(characterCode == '#R' ? '#W' : '#R');
    }
    if (category == 'Characters') {
      setSelectedButton(null);
    } else if (category == 'Face') {
      setSelectedButton(
        metadata[category].findIndex((item) => item.key === value)
      );
    } else if (category == 'Head Gear') {
      setSelectedButton(
        metadata[category].findIndex((item) => item.key === value)
      );
    } else if (category == 'Arm') {
      setSelectedButton(
        metadata[category].findIndex((item) => item.key === value)
      );
    } else if (category == 'Clothing') {
      setSelectedButton(
        metadata[category].findIndex((item) => item.key === value)
      );
    }
  };
  const {
    face,
    body,
    cloth,
    head,
    history,
    setHistory,
    setFuture,
    updateCharacterInHistory,
    updateFace,
    updateBody,
    updateCloth,
    updateHead,
    handleResetState,
    undo,
    redo,
    undoDisable,
    redoDisable
  } = useAssets({
    getFaceScene,
    getArmScene,
    getHeadGearScene,
    getClothingScene,
    setCharacterSelectionScreen,
    handleSelectedChangeFn,
    category
  });
  useEffect(() => {
    handleSelectedChange();
  }, [category]);
  usePreloadAssets({
    category,
    categoryItem,
    setLoadingAssets,
    updateFace,
    updateBody,
    updateCloth,
    updateHead,
    setLoadedCharacters,
    setLoadedFaces,
    setLoadedArm,
    setLoadedHeadGear,
    setLoadedClothing
  });
  const handleRandom = async () => {
    let chr =
      metadata['Characters'][
        getRandomInt(0, metadata['Characters'].length - 1)
      ];
    function filterAssets(data) {
      return data.filter((item) => item.key.includes(chr.code));
    }
    let tempFace = filterAssets(metadata['Face']);
    let tempBody = filterAssets(metadata['Arm']);
    let tempCloth = filterAssets(metadata['Clothing']);
    let tempHead = filterAssets(metadata['Head Gear']);
    let randomFace = getRandomInt(0, tempFace.length - 1);
    let randomBody = getRandomInt(0, tempBody.length - 1);
    let randomCloth = getRandomInt(0, tempCloth.length - 1);
    let randomHead = getRandomInt(0, tempHead.length - 1);

    const face = await preloadFaces(
      tempFace[randomFace],
      setLoadedFaces,
      setLoadingAssets
    );
    const head = await preloadHeadGear(
      tempHead[randomHead],
      setLoadedHeadGear,
      setLoadingAssets
    );
    const arm = await preloadArm(
      tempBody[randomBody],
      setLoadedArm,
      setLoadingAssets
    );
    const cloth = await preloadClothing(
      tempCloth[randomCloth],
      setLoadedClothing,
      setLoadingAssets
    );
    updateFace({
      scene: face,
      path: tempFace[randomFace]?.key
    });
    updateHead({
      scene: head,
      path: tempHead[randomHead]?.key
    });
    updateBody({
      scene: arm,
      path: tempBody[randomBody].key
    });
    updateCloth({
      scene: cloth,
      path: tempCloth[randomCloth].key
    });
    setHistory((prevHistory) => [
      ...prevHistory,
      {
        Face: tempFace[randomFace].key,
        'Head Gear': tempHead[randomHead].key,
        Arm: tempBody[randomBody].key,
        Clothing: tempCloth[randomCloth].key,
        Character: null
      }
    ]);
    setFuture([]);
    setCharacterCode(chr.code);
    setLoadingAssets(false);
    setCharacterSelectionScreen(false);
    updateCategory('Face');
    setSelectedButton(
      metadata[category].findIndex(
        (item) => item.key === tempFace[randomFace]?.key
      )
    );
    setCategoryIndex(1);
    setRightSidebar(true);
    setScene(true);
  };
  const updateCharacter = (newCharacter) => {
    setCharacter(newCharacter.key);
    setCharacterCode(newCharacter.code);
    // if (history.length !== 0) {
    //   updateCharacterInHistory(newCharacter);
    // } else {
    //Setting default character
    const Face = newCharacter.default.Face;
    const Head = newCharacter.default['Head Gear'];
    const Arm = newCharacter.default.Arm;
    const Clothing = newCharacter.default.Clothing;

    updateFace({ scene: getFaceScene(Face.key), path: Face.key });
    updateHead({ scene: getHeadGearScene(null), path: null });
    updateBody({ scene: getArmScene(Arm.key), path: Arm.key });
    updateCloth({
      scene: getClothingScene(Clothing.key),
      path: Clothing.key
    });
    //setting history
    setHistory((prevHistory) => [
      // ...prevHistory,
      {
        Face: Face.key,
        'Head Gear': null,
        Arm: Arm.key,
        Clothing: Clothing.key,
        Character: newCharacter.key
      }
    ]);
    setFuture([]);
    // }
  };

  const updateCategory = (newCategory) => {
    setCategory(newCategory);
  };

  const handleCharacterClick = (name) => {
    updateCharacter(name);
    setCharacterSelectionScreen(false);
    updateCategory('Face');
    setCategoryIndex(1);
    setRightSidebar(true);
    setScene(true);
  };

  const handleCharacterNextClick = () => {
    if (categoryIndex < metadata.categories.length - 1) {
      setCharacterSelectionScreen(false);
      updateCategory(metadata.categories[categoryIndex + 1]);
      setCategoryIndex(categoryIndex + 1);
      setRightSidebar(true);
      setScene(true);
    }
  };

  const handleLeftSidebarClick = (index) => {
    if (index !== 0) {
      // Category other than character
      // because we are showing character selection
      updateCategory(metadata.categories[index]);
      setCategoryIndex(index);
    } else {
      setCharacterSelectionScreen(true);
      updateCategory(metadata.categories[index]);
      setCategoryIndex(index);
    }
  };

  const handleWheel = (event) => {
    event.preventDefault();
    if (isMobile) return;
    if (event.deltaY < 0) {
      controlsRef.current?.zoomTo(2.8, true);
      controlsRef.current?.setFocalOffset(0, -20, 0, true);
    } else {
      controlsRef.current?.setFocalOffset(0, 0, 0, true);
      controlsRef.current?.zoomTo(1.8, true);
    }
  };
  const handleNextButtonClick = () => {
    redo();
  };

  const handlePreviousButtonClick = () => {
    undo();
  };

  const onPointerEnter = () => {
    canvasRef?.current?.addEventListener('wheel', handleWheel);
  };

  const onPointerExit = () => {
    canvasRef?.current?.removeEventListener('wheel', handleWheel);
  };

  return isMobile ? (
    <>
      <ResetModal
        isOpen={isResetModalOpen}
        onClose={closeResetModal}
        onReset={handleModalReset}
      />
      <div className={styles.builderWrapper}>
        <div className={styles.container}>
          {/* Top Avatar change area */}
          <div className={styles.customizeAvatar}>
            <LeftSidebarMob
              assetsLoading={loadingAssets}
              leftSidebar={leftSidebar}
              categories={metadata.categories}
              categoriesImages={metadata.categoryImages}
              selectedCategory={category}
              handleButtonClick={handleLeftSidebarClick}
              characterSelectionScreen={characterSelectionScreen}
            />

            <RightSidebarMob
              characterCode={characterCode}
              selectedButton={selectedButton}
              setSelectedButton={setSelectedButton}
              assetsLoading={loadingAssets}
              category={category}
              handleResetState={openResetModal}
              updateCategory={updateCategory}
              updateCurrentCategoryItem={setCategoryType}
              metadata={metadata}
              rightSidebar={rightSidebar}
              characterSelection={characterSelectionScreen}
              undo={undo}
              redo={redo}
              undoDisable={undoDisable}
              redoDisable={redoDisable}
              characters={metadata.Characters}
              characterSelectionScreen={characterSelectionScreen}
              handleButtonClickChar={handleCharacterClick}
              categories={metadata.categories}
            />

            {characterSelectionScreen && (
              <div className={styles.avatarDescription}>
                Customize your avatar completely with our rich and detailed
                avatar builder. <br /> You have to trade in style, right? Start
                here by choosing your character.
              </div>
            )}
          </div>

          <div className={styles.avatarBuilder}>
            {/* <ToastContainer /> */}

            {characterSelectionScreen && (
              <img
                className={styles.welcome}
                src='/builder/unity-assets-new/welcomeAvatarMob.webp'
              />
            )}

            {loadingAssets && <AssetLoading />}
            {!characterSelectionScreen && (
              <Canvas ref={canvasRef} shadows>
                <Stage
                  intensity={1.5}
                  shadows={true}
                  preset={'rembrandt'}
                  adjustCamera={false}
                ></Stage>
                <Suspense fallback={null}>
                  {/* <ambientLight intensity={1} 
                    castShadow={true}
                    />
                    <directionalLight
                      rotateX={Math.PI}
                      position={[-3, 2, 0]}
                      color='white'
                      intensity={1}
                      castShadow={true}
                    />
                    <spotLight
                      rotateX={-Math.PI/2}
                      position={[-5, 40, -25]}
                      color='white'
                      intensity={10}
                      castShadow={true}
                      angle={Math.PI / 6}
                      decay={0.15}
                      
                    /> */}
                  <group
                    onPointerEnter={onPointerEnter}
                    onPointerExit={onPointerExit}
                    position={[0, initialPosition, 0]}
                  >
                    {!characterSelectionScreen && (
                      <>
                        <Body body={body} />
                        <Cloth cloth={cloth} />
                        <Face face={face} />
                        <Head head={head} />
                      </>
                    )}
                  </group>
                </Suspense>
                <CameraControls
                  enabled={true}
                  makeDefault={true}
                  minPolarAngle={Math.PI / 2}
                  maxPolarAngle={Math.PI / 2}
                  ref={controlsRef}
                  mouseButtons={{
                    wheel: 0,
                    left: 1
                  }}
                  touches={{
                    one: 32,
                    two: 0, // Disable touch zoom
                    three: 0 // Disable touch panning
                  }}
                />

                <PerspectiveCamera
                  zoom={initialZoom}
                  makeDefault={true}
                  ref={cameraRef}
                  fov={60}
                  position={[0, 0, 160]}
                />
              </Canvas>
            )}

            <div className={styles.controls}>
              {/* <div className={styles.threeDButtons}>
                <DotsV
                  onClick={() => !loadingAssets && openResetModal()}
                  disabled={undoDisable}
                />

                {!characterSelectionScreen && <Rotate3D />}
              </div> */}

              <div className={styles.historyButtons}>
                {/* <div>
                  <HistoryBack
                    onClick={() =>
                      !loadingAssets && handlePreviousButtonClick()
                    }
                    disabled={undoDisable}
                  />
                </div>
                <div>
                  <HistoryForward
                    onClick={() => !loadingAssets && handleNextButtonClick()}
                    disabled={redoDisable}
                  />
                </div> */}
              </div>

              {!characterSelectionScreen && (
                <div className={styles.randomButtons}>
                  <div
                    className={styles.random}
                    onClick={() => {
                      handleRandom();
                    }}
                  >
                    <RandomIcon2 />
                  </div>
                  {isLastCharacter ? (
                    <div
                      className={styles.next}
                      onClick={() => {
                        window.location.href =
                          'https://app.primetrader.com/auth/signup/';
                      }}
                    >
                      Finish
                    </div>
                  ) : (
                    <div
                      className={styles.next}
                      onClick={(character) => {
                        if (!loadingAssets && !isNextDisabled) {
                          handleCharacterNextClick(character);
                        }
                      }}
                      style={{
                        cursor: isNextDisabled ? 'not-allowed' : 'pointer',
                        opacity: isNextDisabled ? 0.5 : 1
                      }}
                    >
                      Next
                      <NextIcon2 />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* ./EnD Top Avatar change area */}
        </div>
      </div>
    </>
  ) : (
    <>
      <img
        className='builder-watermark'
        src='/builder/unity-assets-new/ptBuilderLogo.webp'
        alt='builder-nft'
      />

      {!characterSelectionScreen && (
        <div className='builder-3d-icon'>
          <img
            src='/images_p/builder/3dicon.svg'
            alt=''
            width={59}
            height={59}
          />
        </div>
      )}

      <ResetModal
        isOpen={isResetModalOpen}
        onClose={closeResetModal}
        onReset={handleModalReset}
      />

      {characterSelectionScreen && (
        <div className='character-container'>
          <h1>Welcome to the Prime trader nft builder</h1>
          <h2>
            Please select your character to start customizing or randomize your
            avatar
          </h2>
          <div
            className='character-btn-welcome'
            onClick={() => {
              handleRandom();
            }}
          >
            {' '}
            <RandomIcon /> Randomize
          </div>
          <img
            className='welcome'
            src='/builder/unity-assets-new/welcomeAvatar.webp'
          />
        </div>
      )}
      {loadingAssets && <AssetLoading />}
      {!characterSelectionScreen && (
        <Canvas ref={canvasRef} shadows style={{ width: '60%' }}>
          <Stage
            intensity={1.5}
            shadows={true}
            preset={'rembrandt'}
            adjustCamera={false}
          ></Stage>
          <Suspense fallback={null}>
            <group
              onPointerEnter={onPointerEnter}
              onPointerExit={onPointerExit}
              position={[0, initialPosition, 0]}
            >
              {!characterSelectionScreen && (
                <>
                  <Body body={body} />
                  <Cloth cloth={cloth} />
                  <Face face={face} />
                  <Head head={head} />
                </>
              )}
            </group>
          </Suspense>
          <CameraControls
            enabled={true}
            makeDefault={true}
            minPolarAngle={Math.PI / 2}
            maxPolarAngle={Math.PI / 2}
            ref={controlsRef}
            mouseButtons={{
              wheel: 0,
              left: 1
            }}
            touches={{
              one: 32,
              two: 0, // Disable touch zoom
              three: 0 // Disable touch panning
            }}
          />

          <PerspectiveCamera
            zoom={initialZoom}
            makeDefault={true}
            ref={cameraRef}
            fov={60}
            position={[0, 0, 160]}
          />
        </Canvas>
      )}

      <div className='right-container-wrapper'>
        <LeftSidebar
          assetsLoading={loadingAssets}
          leftSidebar={leftSidebar}
          categories={metadata.categories}
          categoriesImages={metadata.categoryImages}
          selectedCategory={category}
          handleButtonClick={handleLeftSidebarClick}
          characterSelectionScreen={characterSelectionScreen}
        />
        <RightSidebar
          characterCode={characterCode}
          handleRandom={handleRandom}
          selectedButton={selectedButton}
          setSelectedButton={setSelectedButton}
          assetsLoading={loadingAssets}
          category={category}
          updateCategory={updateCategory}
          updateCurrentCategoryItem={setCategoryType}
          metadata={metadata}
          rightSidebar={rightSidebar}
          characterSelection={characterSelectionScreen}
          undo={undo}
          redo={redo}
          undoDisable={undoDisable}
          redoDisable={redoDisable}
          handleResetState={openResetModal}
          characters={metadata.Characters}
          characterSelectionScreen={characterSelectionScreen}
          handleButtonClickChar={handleCharacterClick}
          categories={metadata.categories}
        />

        <div className='character-footer'>
          <div
            className='random-btn'
            onClick={() => {
              handleRandom();
            }}
          >
            <RandomIcon />
          </div>
          {isLastCharacter ? (
            <div
              className={'next-btn'}
              onClick={() => {
                window.location.href =
                  'https://app.primetrader.com/auth/signup/';
              }}
            >
              Finish
            </div>
          ) : (
            <div
              className={isNextDisabled ? 'next-btn disabled' : 'next-btn'}
              onClick={(character) => {
                if (!loadingAssets && !isNextDisabled) {
                  handleCharacterNextClick(character);
                }
              }}
              style={{
                cursor: isNextDisabled ? 'not-allowed' : 'pointer'
              }}
            >
              Next <NextIcon />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const RandomIcon2 = ({ onClick, disabled }) => {
  return <img src='/public/images_p/builder/randomIcon.svg' alt='' />;
};

const NextIcon2 = ({ onClick, disabled }) => {
  return (
    <img
      onClick={!disabled ? onClick : null}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.5 : 1
      }}
      src='/images_p/builder/nexticon.svg'
      alt='next'
    />
  );
};

const NextIcon = ({ onClick, disabled }) => {
  return (
    <img
      onClick={!disabled ? onClick : null}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.5 : 1
      }}
      src='/images_p/builder/nexticon1.svg'
      alt='next'
    />
  );
};

const RandomIcon = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative' }}
    >
      <img src='/images_p/builder/randomIcon.svg' alt='' />
      {showTooltip && <h2>Randomize Category</h2>}
    </div>
  );
};

export default function Loader() {
  return (
    <>
      <Html prepend center>
        <div>Loading..</div>
      </Html>
    </>
  );
}
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
