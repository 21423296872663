import React from 'react';

const Avatar3D = ({ isMobileView }: any) => {
  return (
    <>
      {isMobileView ? (
        <section className='ready-trader-section'>
          <div
            className='liner-shade'
            style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 33 }}
          >
            <div className='container' style={{ paddingBottom: 0 }}>
              <h4 style={{ fontSize: 32 }}>The PrimeTrader Avatars</h4>
              <p style={{ fontSize: 14 }}>
                Begin your PrimeTrader adventure by making a unique 3D avatar
                (ERC-6220 token), which opens up access to exciting features
                like Copy Trading, fun Trading Games, and a smart AI Trading
                Assistant.
              </p>

              <div className='img-box' id='Ready'>
                <img
                  className='responsive'
                  src='/images_p/Avatar3dMob.webp'
                  alt='ready-trader-img'
                  style={{ scale: 1.1 }}
                />
              </div>
              <div className='avatar'>
                <div className='ready-trader-row'>
                  <div className='ready-trader-column'>
                    <h2>+10</h2>
                    <h2>Billion Possible Combinations</h2>
                  </div>
                  <div className='ready-trader-column'>
                    <h2>+300</h2>
                    <h2>Traders With Different Body Assets</h2>
                  </div>
                </div>
                <div className='ready-trader-column'>
                  <h2>12</h2>
                  <h2>Traders</h2>
                </div>
              </div>
              <div className='avatar-content'>
                <p style={{ marginTop: 19, fontSize: 14 }}>
                  Your avatar evolves along with your achievements, securely
                  storing your trading successes, investment performance, and
                  assets under management (AUM) in a blockchain-recorded
                  profile.
                </p>
                <p style={{ fontSize: 14 }}>
                  As you climb the ranks from an Investment Analyst to an
                  Investment Associate, and eventually to management levels like
                  Portfolio and Hedge Fund Manager, your avatar develops too.
                </p>
                <p style={{ fontSize: 14, paddingBottom: 31 }}>
                  The higher your rank, the more options and features you unlock
                  on the platform, expanding your trading capabilities and
                  opportunities. Plus, you can venture into virtual worlds like
                  Decentraland with your avatar, where we&apos;re setting up
                  special spaces for traders to meet and share experiences,
                  making trading profitable and a fun community activity.
                </p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className='ready-trader-section'>
          <div className='liner-shade'>
            <div className='container' style={{ paddingBottom: 0 }} id='Ready'>
              <h3>The PrimeTrader Avatars</h3>
              <p>
                Begin your PrimeTrader adventure by making a unique 3D avatar
                (ERC-6220 token), which opens up access to exciting features
                like Copy Trading, fun Trading Games, and a smart AI Trading
                Assistant.
              </p>

              <div className='img-box'>
                <img
                  className='responsive'
                  src='/images_p/Avatar3d.webp'
                  alt='ready-trader-img'
                />
              </div>
              <div className='ready-trader-row'>
                <div className='ready-trader-column'>
                  <h2>12</h2>
                  <h2>Traders</h2>
                </div>
                <div className='ready-trader-column'>
                  <h2>+300</h2>
                  <h2>Traders With Different Body Assets</h2>
                </div>
                <div className='ready-trader-column'>
                  <h2>+10</h2>
                  <h2>Billion Possible Combinations</h2>
                </div>
              </div>
              <p style={{ marginTop: 31 }}>
                Your avatar evolves along with your achievements, securely
                storing your trading successes, investment performance, and
                assets under management (AUM) in a blockchain-recorded profile.
                As you climb the ranks from an Investment Analyst to an
                Investment Associate, and eventually to management levels like
                Portfolio and Hedge Fund Manager, your avatar develops too. The
                higher your rank, the more options and features you unlock on
                the platform, expanding your trading capabilities and
                opportunities. Plus, you can venture into virtual worlds like
                Decentraland with your avatar, where we&apos;re setting up
                special spaces for traders to meet and share experiences, making
                trading profitable and a fun community activity.
              </p>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Avatar3D;
