import React, { useState } from 'react';

export const RightSidebar = ({
  category,
  updateCategory,
  characterCode,
  metadata,
  rightSidebar,
  characterSelection,
  updateCurrentCategoryItem,
  assetsLoading,
  undo,
  redo,
  undoDisable,
  redoDisable,
  characterSelectionScreen,
  characters,
  handleResetState,
  handleRandom,
  handleButtonClickChar,
  selectedButton,
  setSelectedButton
}) => {
  const handleButtonClick = (index) => {
    setSelectedButton(index);
    updateCurrentCategoryItem(metadata[category][index]);
  };

  const handleNextButtonClick = () => {
    redo();
  };

  const handlePreviousButtonClick = () => {
    undo();
  };

  return (
    rightSidebar && (
      <>
        {!characterSelection && !characterSelectionScreen && (
          <div className='unity-frame-new'>
            <h1>
              {category ? (category == 'Arm' ? 'Body' : category) : 'Character'}
            </h1>

            <div className='character-container-new'>
              {metadata[category].map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: item.key.includes(characterCode) ? '' : 'none'
                  }}
                  className={
                    selectedButton === index
                      ? 'character-item active'
                      : 'character-item'
                  }
                  onClick={() =>
                    !assetsLoading &&
                    selectedButton !== index &&
                    handleButtonClick(index)
                  }
                >
                  <img src={item.icon} alt='icon' />
                </div>
              ))}
            </div>

            <div className='controls'>
              <BackIcon
                onClick={() => !assetsLoading && handlePreviousButtonClick()}
                disabled={undoDisable}
              />
              <NextIcon
                onClick={() => !assetsLoading && handleNextButtonClick()}
                disabled={redoDisable}
              />
              <ResetIcon
                disabled={characterSelectionScreen}
                onClick={() => handleResetState()}
              />
              <RandomIcon
                onClick={() => {
                  handleRandom();
                }}
                disabled={false}
              />
            </div>
          </div>
        )}

        {characterSelectionScreen && (
          <div className='unity-frame-new'>
            <h1>
              {category ? (category == 'Arm' ? 'Body' : category) : 'Character'}
            </h1>
            <div className='character-container-new'>
              {characters.map((_character, idx) => (
                <div
                  key={idx}
                  className={
                    selectedButton === idx
                      ? 'character-item active'
                      : 'character-item'
                  }
                  onClick={() => {
                    if (!assetsLoading) {
                      setSelectedButton(idx);
                      handleButtonClickChar(_character);
                    }
                  }}
                >
                  <img
                    style={{
                      width: _character.icon.includes('charW') ? '225px' : ''
                    }}
                    src={_character.icon}
                    alt='icon'
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {!characterSelectionScreen &&
          metadata.categoryVariants[category].length > 0 && (
            <div className='no-varients'>
              <h1>Variants</h1>
              <div className='list'>
                {metadata.categoryVariants[category].map((item, index) => (
                  <img
                    className='item'
                    key={index}
                    src={item}
                    alt={`Variant ${index}`}
                  />
                ))}
              </div>
            </div>
          )}
      </>
    )
  );
};

const BackIcon = ({ onClick, disabled }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative', height: 41 }}
    >
      <img
        onClick={!disabled ? onClick : null}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          opacity: disabled ? 0.5 : 1
        }}
        src='/images_p/builder/backicon.svg'
        alt='backicon'
        width={20}
        height={20}
      />
      {showTooltip && !disabled && <h2>Undo</h2>}
    </div>
  );
};

const NextIcon = ({ onClick, disabled }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative', height: 41 }}
    >
      <img
        onClick={!disabled ? onClick : null}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          opacity: disabled ? 0.5 : 1
        }}
        src='/images_p/builder/nexticon3.svg'
        alt=''
        width={20}
        height={20}
      />
      {showTooltip && !disabled && <h2>Redo</h2>}
    </div>
  );
};

const ResetIcon = ({ onClick, disabled }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative', height: 41 }}
    >
      <img
        onClick={!disabled ? onClick : null}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          opacity: disabled ? 0.5 : 1,
          scale: 1.2
        }}
        src='/images_p/builder/reseticon.svg'
        alt='reset icon'
        width={20}
        height={20}
      />
      {showTooltip && !disabled && <h2>Reset</h2>}
    </div>
  );
};

const RandomIcon = ({ onClick, disabled }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative', height: 41 }}
    >
      <img
        onClick={!disabled ? onClick : null}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          opacity: disabled ? 0.5 : 1
        }}
        src='/images_p/builder/randomIcon.svg'
        alt=''
        width={20}
        height={20}
      />
      {showTooltip && !disabled && <h2>Randomize All</h2>}
    </div>
  );
};
