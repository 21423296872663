import { useState } from 'react';
import { getNewStateAndChangedAssets } from '../utils';
// import { toast } from 'react-toastify';

export const useAssets = ({
  getFaceScene,
  getArmScene,
  getHeadGearScene,
  getClothingScene,
  setCharacterSelectionScreen,
  handleSelectedChangeFn,
  category
}) => {
  const [face, setFace] = useState(null);
  const [body, setBody] = useState(null);
  const [head, setHead] = useState(null);
  const [cloth, setCloth] = useState(null);

  const [history, setHistory] = useState([]);
  const [future, setFuture] = useState([]);

  const keySetterMap = {
    Face: setFace,
    'Head Gear': setHead,
    Arm: setBody,
    Clothing: setCloth
  };
  const keyAssetGetterMap = {
    Face: getFaceScene,
    'Head Gear': getHeadGearScene,
    Arm: getArmScene,
    Clothing: getClothingScene
  };

  const applyChangedAssets = (newHistory, keysChanged) => {
    keysChanged.forEach((_asset) => {
      const setterFn = keySetterMap[_asset];
      const assetGetterFn = keyAssetGetterMap[_asset];
      const value = newHistory[_asset];
      if (!value) {
        setterFn(null);
      } else {
        setterFn({
          scene: assetGetterFn(value),
          path: value
        });
      }
    });
  };

  const updateCharacterInHistory = (newCharacter) => {
    if (newCharacter) {
      const { newHistory, keysChanged } = getNewStateAndChangedAssets(
        history,
        { path: newCharacter.key },
        'Character'
      );
      if (keysChanged.length) {
        let compString =
          'Following Assets have been updated due to compatibiliy:\n';
        compString += keysChanged.join(',\n');
        // toast(compString);
      }

      applyChangedAssets(newHistory, keysChanged);
      setHistory((prevHistory) => [...prevHistory, newHistory]);
      setFuture([]);
    }
  };

  const updateFace = (newFace) => {
    if (face) {
      const { newHistory, keysChanged } = getNewStateAndChangedAssets(
        history,
        newFace,
        'Face'
      );
      let canApplyChangedAssets = true;
      if (keysChanged.length) {
        if (keysChanged.length === 1 && keysChanged[0] === 'Character') {
          let compString = `Selected asset is not compatibile with ${history[history.length - 1].Character}\n`;
          // toast(compString);
          canApplyChangedAssets = false;
        } else {
          let compString =
            'Following Assets have been updated due to compatibiliy:\n';
          compString += keysChanged.join(',\n');
          // toast(compString);
        }
      }
      if (canApplyChangedAssets) {
        applyChangedAssets(newHistory, keysChanged);
        setHistory((prevHistory) => [...prevHistory, newHistory]);
        setFuture([]);
        setFace(newFace);
      }
    } else {
      setFace(newFace);
    }
  };

  const updateBody = (newBody) => {
    if (body) {
      const { newHistory, keysChanged } = getNewStateAndChangedAssets(
        history,
        newBody,
        'Arm'
      );
      let canApplyChangedAssets = true;
      if (keysChanged.length) {
        if (keysChanged.length === 1 && keysChanged[0] === 'Character') {
          let compString = `Selected asset is not compatibile with ${history[history.length - 1].Character}\n`;
          // toast(compString);
          canApplyChangedAssets = false;
        } else {
          let compString =
            'Following Assets have been updated due to compatibiliy:\n';
          compString += keysChanged.join(',\n');
          // toast(compString);
        }
      }
      if (canApplyChangedAssets) {
        applyChangedAssets(newHistory, keysChanged);
        setHistory((prevHistory) => [...prevHistory, newHistory]);
        setFuture([]);
        setBody(newBody);
      }
    } else {
      setBody(newBody);
    }
  };

  const updateHead = (newHead) => {
    if (head) {
      const { newHistory, keysChanged } = getNewStateAndChangedAssets(
        history,
        newHead,
        'Head Gear'
      );
      let canApplyChangedAssets = true;
      if (keysChanged.length) {
        if (keysChanged.length === 1 && keysChanged[0] === 'Character') {
          let compString = `Selected asset is not compatibile with ${history[history.length - 1].Character}\n`;
          // toast(compString);
          canApplyChangedAssets = false;
        } else {
          let compString =
            'Following Assets have been updated due to compatibiliy:\n';
          compString += keysChanged.join(',\n');
          // toast(compString);
        }
      }
      if (canApplyChangedAssets) {
        applyChangedAssets(newHistory, keysChanged);
        setHistory((prevHistory) => [...prevHistory, newHistory]);
        setFuture([]);
        setHead(newHead);
      }
    } else {
      setHead(newHead);
    }
  };

  const updateCloth = (newCloth) => {
    if (cloth) {
      const { newHistory, keysChanged } = getNewStateAndChangedAssets(
        history,
        newCloth,
        'Clothing'
      );
      let canApplyChangedAssets = true;
      if (keysChanged.length) {
        if (keysChanged.length === 1 && keysChanged[0] === 'Character') {
          let compString = `Selected asset is not compatibile with ${history[history.length - 1].Character}\n`;
          // toast(compString);
          canApplyChangedAssets = false;
        } else {
          let compString =
            'Following Assets have been updated due to compatibiliy:\n';
          compString += keysChanged.join(',\n');
          // toast(compString);
        }
      }
      if (canApplyChangedAssets) {
        applyChangedAssets(newHistory, keysChanged);
        setHistory((prevHistory) => [...prevHistory, newHistory]);
        setFuture([]);
        setCloth(newCloth);
      }
    } else {
      setCloth(newCloth);
    }
  };

  const handleResetState = () => {
    if (history.length) {
      setFace(null);
      setBody(null);
      setHead(null);
      setCloth(null);
      setHistory([]);
      setFuture([]);
      setCharacterSelectionScreen(true);
    }
  };

  const applyState = (state) => {
    Object.keys(state).forEach((k) => {
      const setterFn = keySetterMap[k];
      if (k !== 'Character') {
        const assetGetterFn = keyAssetGetterMap[k];
        const value = state[k];
        setterFn({
          scene: assetGetterFn(value),
          path: value
        });
        if (category == k) handleSelectedChangeFn(value);
      }
    });
  };
  const undo = () => {
    if (history.length > 1) {
      const currentState = history.pop();
      const newFuture = [...future];
      newFuture.push(currentState);
      setFuture(newFuture);
      const newCurrentState = history.slice(-1);
      applyState(newCurrentState[0]);
    }
  };

  const redo = () => {
    if (future.length) {
      const newCurrentState = future.pop();
      setHistory((prevHist) => [...prevHist, newCurrentState]);
      applyState(newCurrentState);
    }
  };

  const undoDisable = history.length < 2;
  const redoDisable = future.length === 0;

  return {
    face,
    body,
    cloth,
    head,
    history,
    setHistory,
    setFuture,
    updateCharacterInHistory,
    updateFace,
    updateBody,
    updateCloth,
    updateHead,
    handleResetState,
    undo,
    redo,
    undoDisable,
    redoDisable
  };
};
