import { metadata } from "./data";

const getKey = (firstPart, secondPart) => {
//Get the key combination
  const key = `${firstPart}_${secondPart}`;
  const mirrorKey = `${secondPart}_${firstPart}`;
  let finalKey = null;
  if (metadata["compatibility"].hasOwnProperty(key)) {
    return key;
  } else if (metadata["compatibility"].hasOwnProperty(mirrorKey)) {
    return mirrorKey;
  }
  console.log("Key that exist is history", finalKey);
};

const findCompatibleValueInHistory = (keyNotCompatible, newItem, history) => {
//Find the compatible value of NotCompatible key with newItem from the history 
  for (let i = history.length - 1; i >= 0; i--) {
    const state = history[i];
    const compatibilityKey = getKey(state[keyNotCompatible], newItem);
    if (metadata["compatibility"][compatibilityKey] === 1) {
      return state[keyNotCompatible];
    }
  }
  return null;
};

export const getNewStateAndChangedAssets = (history, newItem, forKey) => {
  const newHistory = {
    [forKey]: newItem.path,
  };
  const keysChanged = [];
  const state = history[history.length - 1];
  for (const key of Object.keys(state)) {
    if (key != forKey) {
      const compatibilityKey = getKey(state[key], newItem.path);
      if (false) {
      // if (metadata["compatibility"][compatibilityKey] !== 1) {
        // const compatible = findCompatibleValueInHistory(key, newItem.path, history);
        // //commented for now but will be back for future use
        // // keysChanged.push(key);
        // newHistory[key] = compatible;
      } else {
        newHistory[key] = state[key];
      }
    }
  }
  return { newHistory, keysChanged };
};
