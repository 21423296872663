import React from 'react';

export const LeftSidebar = ({
  leftSidebar,
  categories,
  selectedCategory,
  handleButtonClick = null,
  assetsLoading,
  categoriesImages,
  characterSelectionScreen
}) => {
  return (
    leftSidebar && (
      <div className='unity-buttons'>
        {categories.map((button, index) => (
          <div
            className={
              index === categories.indexOf(selectedCategory)
                ? 'unity-btn active'
                : 'unity-btn'
            }
            key={index}
            onClick={
              characterSelectionScreen
                ? null
                : () => !assetsLoading && handleButtonClick(index)
            }
          >
            <img src={categoriesImages[button]} />
          </div>
        ))}
      </div>
    )
  );
};
