import { Background } from './Background';
import { Container } from './Container';
import { Heading } from './Heading';
import { Reset } from './Reset';
import { Scene } from './Scene';

export default function Builder() {
  return (
    <>
      <Container>
        <Background />
        <Scene />
      </Container>
    </>
  );
}
