// Define interfaces for the data structure
interface YearData {
  title: string;
  subtitle: string;
  points: string[];
}

interface YearDataCollection {
  [key: number]: YearData;
}

// Define the data for each year
const Y2023: YearDataCollection = {
  0: {
    title: 'Q2023',
    subtitle: 'Exchange License by the Bank of Spain',
    points: [
      'Exchange License by the Bank of Spain',
      'Brand Strategy, Registration and IP',
      'Prime Avatars 3D Modeling',
      'PTT Staking V1.0 (testnet)',
      'NFT Logic V1.0 (testnet)',
      'Gamification System'
    ]
  },
  1: {
    title: 'Q2023',
    subtitle: 'PTT Stacking V1.0',
    points: [
      'Exchange License by the Bank of Spain',
      'Brand Strategy, Registration and IP',
      'Prime Avatars 3D Modeling',
      'PTT Staking V1.0 (testnet)',
      'NFT Logic V1.0 (testnet)',
      'Gamification System'
    ]
  },
  2: {
    title: 'Q2023',
    subtitle: 'NFT Logic V1.0',
    points: [
      'Exchange License by the Bank of Spain',
      'Brand Strategy, Registration and IP',
      'Prime Avatars 3D Modeling',
      'PTT Staking V1.0 (testnet)',
      'NFT Logic V1.0 (testnet)',
      'Gamification System'
    ]
  },
  3: {
    title: 'Q2023',
    subtitle: 'Gamification System',
    points: [
      'Exchange License by the Bank of Spain',
      'Brand Strategy, Registration and IP',
      'Prime Avatars 3D Modeling',
      'PTT Staking V1.0 (testnet)',
      'NFT Logic V1.0 (testnet)',
      'Gamification System'
    ]
  }
};

const Y2024: YearDataCollection = {
  0: {
    title: 'Q12024',
    subtitle: 'Setting the Foundation',
    points: [
      'Backend Whitebox WebApp Pentest by Halborn',
      'Account Abstraction Framework v1.0',
      'Community Building',
      'Integration of Liquidity Providers (centralized)',
      'Chatbot V1.0',
      'Tokenomics v1.0 (audited)'
    ]
  },
  1: {
    title: 'Q22024',
    subtitle: 'Open Beta Launch',
    points: [
      'PrimeBirdy (Public Beta testnet)',
      'Learn & Earn Quizzes',
      'Predictions Game',
      'Ambassadors Program',
      'PrimeTrader Litepaper v1.0',
      'Clash of Traders (Trading Battles - Public Beta)',
      'Customize Your Prime Avatar in 3D Builder',
      'Virtual Trading Assistant (Public Beta)',
      'On-chain Reputation (Close Alpha)',
      'Trading View Integration',
    ]
  },
  2: {
    title: 'Q32024',
    subtitle: 'Social Trading Protocol',
    points: [
      'On-Chain CopyTrading (Public Beta)',
      'Trader VS Market (Public Beta)',
      'Trade & Climb the Rankings',
      'Hybrid (CeDeFi) Liquidity Full Integration (Public Beta)',
      'AI-driven Trading Signals (Public Beta)',
      'Gamified Trading',
      'Integration Into Farcaster, Lens and Social dApps',
      'AI-based Portfolio Management'
    ]
  },
  3: {
    title: 'Q42024',
    subtitle: 'Virtual Trading Assistant',
    points: [
      'AI-driven Copy Trading (Close Alpha)',
      'Algorithmic Trading (Close Beta)',
      'Interoperability to compatible chains',
      'NFT Trading Integration (Including Ordinals)',
      'Breeding Mechanics Revealed',
      'Metaverse Roadmap'
    ]
  }
};

const Y2025: YearDataCollection = {
  0: {
    title: 'Q12025',
    subtitle: 'Integration of NFTs, RWAs, and other digital assets',
    points: [
      'PrimeTrader Litepaper 2.0',
      'Integration of NFTs, RWAs, and other digital assets',
      'Virtual Trading Assistant in AR/VR',
      'AI-driven Copy Trading (Public Beta)',
      'Personalized Trading Insights (Public Beta)',
      'Fractionalization of Investment Vaults',
      'Phygital Experiences',
      'Community Contributions'
    ]
  },
  1: {
    title: 'Q22025',
    subtitle: 'Personalized trading Insights (Public Beta)',
    points: [
      'PrimeTrader Litepaper 2.0',
      'Integration of NFTs, RWAs, and other digital assets',
      'Virtual Trading Assistant in AR/VR',
      'AI-driven Copy Trading (Public Beta)',
      'Personalized Trading Insights (Public Beta)',
      'Fractionalization of Investment Vaults',
      'Phygital Experiences',
      'Community Contributions'
    ]
  },
  2: {
    title: 'Q32025',
    subtitle: 'Fractionalization of Investment Vaults',
    points: [
      'PrimeTrader Litepaper 2.0',
      'Integration of NFTs, RWAs, and other digital assets',
      'Virtual Trading Assistant in AR/VR',
      'AI-driven Copy Trading (Public Beta)',
      'Personalized Trading Insights (Public Beta)',
      'Fractionalization of Investment Vaults',
      'Phygital Experiences',
      'Community Contributions'
    ]
  },
  3: {
    title: 'Q42025',
    subtitle: 'Phygital Experiences & Community Contributions',
    points: [
      'PrimeTrader Litepaper 2.0',
      'Integration of NFTs, RWAs, and other digital assets',
      'Virtual Trading Assistant in AR/VR',
      'AI-driven Copy Trading (Public Beta)',
      'Personalized Trading Insights (Public Beta)',
      'Fractionalization of Investment Vaults',
      'Phygital Experiences',
      'Community Contributions'
    ]
  }
};

// Export the data
export { Y2023, Y2024, Y2025 };

