import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import styles from './es_slider.module.css';

const HowToTrade = ({ isMobileView }) => {
  return isMobileView ? <MobileView /> : <DesktopViewWithoutBootstrap />;
};


const MobileView = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const totalSlides = 3;
  const slideDuration = 10; // Duration for each slide in seconds
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    let animationTimeout;
    let slideTimeout = setTimeout(() => {
      setIsAnimating(true); // Start the exit animation
      animationTimeout = setTimeout(() => {
        setActiveSlide((prevSlide) => (prevSlide + 1) % totalSlides);
        // setActiveSlide(2);
        setIsAnimating(false); // End the exit animation, start the enter animation
      }, 800); // This timeout should match your CSS animation duration
    }, slideDuration * 1000); // Start the exit animation a bit before changing slides

    return () => {
      clearTimeout(slideTimeout);
      clearTimeout(animationTimeout);
    };
  }, [activeSlide]);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const safari = /^((?!chrome|android).)*safari/i.test(userAgent);
    setIsSafari(safari);
  }, []);

  const handleProgressBarClick = (index) => {
    setActiveSlide(index); // Set active slide to the clicked index
  };

  return (
    <>
      <section className='how-prime-section_mob' id='HowItWork'>
        <div className='liner-shade'>
          <div className='spider-graph'>
            <div className='content'>
              <div className='mainHeading'>
                <label>How PrimeTrader Works</label>
              </div>
              <div className='stepsCount'>
                <div className={`slider-bar ${isSafari ? 'safari' : ''}`}>
                  <div className={styles['es-progress-bars-container']}>
                    {Array.from({ length: totalSlides }).map((_, index) => (
                      <div
                        key={index}
                        className={styles['es-progress-bar-container']}
                        onClick={() => handleProgressBarClick(index)}
                      >
                        <div
                          className={`${styles['es-progress-bar']} ${index === activeSlide ? styles['es-slide-active'] : styles['es-slide-inactive']}`}
                          style={{
                            animationDuration: `${index === activeSlide ? slideDuration : 0}s`
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles['es-slider']}>
                <div className={styles['es-slides-container']}>
                  {/* Slide 1 */}
                  <div
                    key={0}
                    className={`slide ${0 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}
                    style={{
                      display: activeSlide === 0 ? 'block' : 'none'
                    }}
                  >
                    <div className='content-box' style={{ marginRight: 10 }}>
                      <img
                        src='/images/HowPTWork/howPrimeTraderSlide1.svg'
                        alt='newmob'
                        style={{
                          minHeight: '315px',
                          minWidth: '360px',
                          maxWidth: '373px',
                          marginRight: 20
                        }}
                      />
                      <div className='titlePoints'>
                        <label>One-Click to Web3</label>
                      </div>
                      <div className='bullentsPoints'>
                        <ul style={{ listStyleType: 'square' }}>
                          <li
                            style={{
                              listStyleType: 'square',
                              borderRadius: '5px',
                              color: '#fff'
                            }}
                          >
                            Our innovative wallet solution offers a simple,
                            one-click pathway to crypto trading, decentralized
                            finance, and Web3.
                          </li>
                          <br />
                          <li
                            style={{
                              listStyleType: 'square',
                              borderRadius: '5px',
                              color: '#fff'
                            }}
                          >
                            Users can create a unique Trading Avatar that
                            records their trading history, investments, and game
                            performance, all securely tokenized under their
                            digital identity.
                          </li>
                          <br />
                          <li
                            style={{
                              listStyleType: 'square',
                              borderRadius: '5px',
                              color: '#fff'
                            }}
                          >
                            Enjoy full financial integration of DeFi and CeFi
                            with traditional financial services, allowing you to
                            spend crypto and in-game rewards immediately in the
                            real economy.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* Slide 2 */}
                  <div
                    key={1}
                    className={`slide ${1 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}
                    style={{
                      display: activeSlide === 1 ? 'block' : 'none'
                    }}
                  >
                    <div className='content-box'>
                      <img
                        src='/images/HowPTWork/howPrimeTraderSlide2.webp'
                        alt='newmob'
                        style={{
                          minHeight: '317px',
                          minWidth: '360px',
                          maxWidth: '360px'
                        }}
                      />
                      <div className='titlePoints'>
                        <label>Build Your Investment Empire</label>
                      </div>
                      <div className='bullentsPoints'>
                        <ul style={{ listStyleType: 'square' }}>
                          <li
                            style={{
                              listStyleType: 'square',
                              borderRadius: '5px',
                              color: '#fff'
                            }}
                          >
                            Investment Managers create crypto investment
                            portfolio for co-investments.
                          </li>
                          <br />
                          <li
                            style={{
                              listStyleType: 'square',
                              borderRadius: '5px',
                              color: '#fff'
                            }}
                          >
                            On-Chain Reputation allows investors to verify
                            investment performance.
                          </li>
                          <br />
                          <li
                            style={{
                              listStyleType: 'square',
                              borderRadius: '5px',
                              color: '#fff'
                            }}
                          >
                            Investors copy the best trading strategies with just
                            one-click.
                          </li>
                          <li
                            style={{
                              listStyleType: 'square',
                              borderRadius: '5px',
                              color: '#fff'
                            }}
                          >
                            Investment Managers earn performance-based
                            compensation, akin to Hedge Fund Managers on Wall
                            Street.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* Slide 3 */}
                  <div
                    key={2}
                    className={`slide ${2 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}
                    style={{
                      display: activeSlide === 2 ? 'block' : 'none'
                    }}
                  >
                    <div className='content-box'>
                      <img
                        src='/images/HowPTWork/howPrimeTraderSlide3.webp'
                        alt='newmob'
                        style={{
                          minHeight: '335px',
                          minWidth: '360px',
                          maxWidth: '388px'
                        }}
                      />
                      <div className='titlePoints'>
                        <label>
                          Gamified Crypto Trading: Trade, Play and Earn
                        </label>
                      </div>
                      <div className='bullentsPoints'>
                        <ul style={{ listStyleType: 'square' }}>
                          <li
                            style={{
                              listStyleType: 'square',
                              borderRadius: '5px',
                              color: '#fff'
                            }}
                          >
                            Select favorite cryptos for outperforming
                            competitors and the market in trading challenges.
                          </li>
                          <br />
                          <li
                            style={{
                              listStyleType: 'square',
                              borderRadius: '5px',
                              color: '#fff'
                            }}
                          >
                            Compete against other traders in exciting trading
                            competitions, win crypto and XP, and ascend the
                            rankings.
                          </li>
                          <br />
                          <li
                            style={{
                              listStyleType: 'square',
                              borderRadius: '5px',
                              color: '#fff'
                            }}
                          >
                            Participate in daily quests, earn Rewards and XP
                            points, level-up your digital avatar, and unlock new
                            trading features and benefits.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const DesktopViewWithoutBootstrap = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const totalSlides = 3;
  const slideDuration = 9.8;
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    let animationTimeout;
    let slideTimeout = setTimeout(() => {
      setIsAnimating(true);
      animationTimeout = setTimeout(() => {
        setActiveSlide((prevSlide) => (prevSlide + 1) % totalSlides);
        setIsAnimating(false);
      }, 800);
    }, slideDuration * 1000);

    return () => {
      clearTimeout(slideTimeout);
      clearTimeout(animationTimeout);
    };
  }, [activeSlide]);

  useEffect(() => {
    setActiveSlide(1)
    const userAgent = navigator.userAgent;
    const safari = /^((?!chrome|android).)*safari/i.test(userAgent);
    setIsSafari(safari);
  }, []);

  const handleProgressBarClick = (index) => {
    setActiveSlide(index);
  };


  return (
    <>
      <div className={style.HowPTWorkSection} id='HowItWork'>
        <div className={style.topspider} />
        <div className={style.btmspider} />
        <div className={style.leftspider} />
        <div className={style.rightspider} />
        <div className={style.slider} style={{ scale: 0.9 }}>
          <div className={style.loaderBar}>
            <label className={style.heading}>How PrimeTrader Works</label>
            <div className={style.barsWrapper}>
              {Array.from({ length: totalSlides }).map((_, index) => (
                <div
                  key={index}
                  className={`${style.bar} ${index === activeSlide && style.activeBar}`}
                  onClick={() => handleProgressBarClick(index)}
                >
                  <div className={style.loadingBar} />
                </div>
              ))}
            </div>
          </div>
          <div className='slider-without-bt'
          >
            <div key={0} style={{
              display: activeSlide === 0 ? 'block' : 'none'
            }} className={`slide ${0 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}>
              <div className={style.slide}>
                <div className={style.left}>
                  <img
                    src='/images_p/HowPTWork/slide1.webp'
                    alt='one click to web3'
                  />
                </div>

                <div className={style.right}>
                  <div className={style.con}>
                    <label className={style.subheading}>
                      One-Click to Web3
                    </label>
                    <ul>
                      <li>
                        Our innovative wallet solution offers a simple,
                        one-click pathway to crypto trading, decentralized
                        finance, and Web3.
                      </li>
                      <br />
                      <li>
                        Users can create a unique Trading Avatar that records
                        their trading history, investments, and game
                        performance, all securely tokenized under their digital
                        identity.{' '}
                      </li>
                      <br />

                      <li>
                        Enjoy full financial integration of DeFi and CeFi with
                        traditional financial services, allowing you to spend
                        crypto and in-game rewards immediately in the real
                        economy.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div key={1} style={{
              display: activeSlide === 1 ? 'block' : 'none'
            }} className={`slide ${1 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}>
              <div className={style.slide}>
                <div className={style.left}>
                  <img
                    src='/images_p/HowPTWork/slide2.webp'
                    alt='gamified crypto'
                  />
                </div>

                <div className={style.right}>
                  <div className={style.con}>
                    <label className={style.subheading}>
                      Gamified Crypto Trading: Trade, Play and Earn
                    </label>
                    <ul>
                      <li>
                        Select favorite cryptos for outperforming competitors
                        and the market in trading challenges.
                      </li>
                      <br />
                      <li>
                        Compete against other traders in exciting trading
                        competitions, win crypto and XP, and ascend the
                        rankings.
                      </li>
                      <br />

                      <li>
                        Participate in daily quests, earn Rewards and XP points,
                        level-up your digital avatar, and unlock new trading
                        features and benefits.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div key={2} style={{
              display: activeSlide === 2 ? 'block' : 'none'
            }} className={`slide ${2 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}>
              <div className={style.slide}>
                <div className={style.left}>
                  <img
                    src='/images_p/HowPTWork/slide3.webp'
                    alt='build your investment'
                  />
                </div>

                <div className={style.right}>
                  <div className={style.con}>
                    <label className={style.subheading}>
                      Build Your Investment Empire
                    </label>
                    <ul>
                      <li>
                        Investment Managers create crypto investment portfolio
                        for co-investments
                      </li>
                      <br />
                      <li>
                        On-Chain Reputation allows investors to verify
                        investment performance
                      </li>
                      <br />

                      <li>
                        Investors copy the best trading strategies with just
                        one-click.
                      </li>
                      <br />

                      <li>
                        Investment Managers earn performance-based compensation,
                        akin to Hedge Fund Managers on Wall Street.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default HowToTrade;
