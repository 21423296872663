import { GLTFLoader } from 'three/addons/loaders/GLTFLoader';

const preloadCharacters = async (
  characters,
  setLoadedCharacters,
  setLoading = true,
) => {
  const loader = new GLTFLoader();
  const loadedCharacterScenes = {};

  for (const character of characters) {
    try {
      const { scene: characterScene } = await new Promise((resolve, reject) =>
        loader.load(character.path, (gltf) => resolve(gltf), undefined, reject),
      );
      loadedCharacterScenes[character.key] = characterScene;
    } catch (e) {
      loadedCharacterScenes[character.key] = { scene: null };
      console.error(`${character.key} has wrong URL: ${character.path}`);
    }
  }
  if (setLoading) {
    console.log('setting loader in preload character');
    loadedCharacterScenes['loaded'] = true;
  }

  setLoadedCharacters(loadedCharacterScenes);
};

const preloadFaces = async (face, setLoadedFaces, setLoading = ()=>{}) => {
  const loader = new GLTFLoader();
  const loadedFaceScenes = {};
  setLoading(true);
  try {
    const { scene: faceScene } = await new Promise((resolve, reject) =>
      loader.load(face.path, (gltf) => resolve(gltf), undefined, reject),
    );
    loadedFaceScenes[face?.key] = faceScene;
  } catch (e) {
    loadedFaceScenes[face?.key] = { scene: null };
    console.error(`${face?.key} has wrong URL: ${face?.path}`);
  }
  setLoadedFaces((prevState) => ({ ...prevState, ...loadedFaceScenes })); // Update the state with new data
  return loadedFaceScenes[face?.key]
};

const preloadArm = async (arm, setLoadedArm, setLoading= ()=>{}) => {
  const loader = new GLTFLoader();
  const loadedArmScenes = {};
  setLoading(true);
  try {
    const { scene: armScene } = await new Promise((resolve, reject) =>
      loader.load(arm.path, (gltf) => resolve(gltf), undefined, reject),
    );
    loadedArmScenes[arm.key] = armScene;
  } catch (e) {
    loadedArmScenes[arm.key] = { scene: null };
    console.error(`${arm.key} has wrong URL: ${arm.path}`);
  }

  setLoadedArm((prevState) => ({ ...prevState, ...loadedArmScenes }));
  return loadedArmScenes[arm.key]
};

const preloadHeadGear = async (
  headGear,
  setLoadedHeadGear,
  setLoading= ()=>{},
) => {
  const loader = new GLTFLoader();
  const loadedHeadGearScenes = {};
  setLoading(true);
  try {
    const { scene: headGearScene } = await new Promise((resolve, reject) =>
      loader.load(headGear.path, (gltf) => resolve(gltf), undefined, reject),
    );
    loadedHeadGearScenes[headGear.key] = headGearScene;
  } catch (e) {
    loadedHeadGearScenes[headGear.key] = { scene: null };
    console.error(`${headGear.key} has wrong URL: ${headGear.path}`);
  }
  setLoadedHeadGear((prevState) => ({ ...prevState, ...loadedHeadGearScenes }));
  return loadedHeadGearScenes[headGear.key]
};

const preloadClothing = async (
  clothing,
  setLoadedClothing,
  setLoading= ()=>{},
) => {
  const loader = new GLTFLoader();
  const loadedClothingScenes = {};
  setLoading(true);
  try {
    const { scene: clothingScene } = await new Promise((resolve, reject) =>
      loader.load(clothing.path, (gltf) => resolve(gltf), undefined, reject),
    );
    loadedClothingScenes[clothing.key] = clothingScene;
  } catch (e) {
    loadedClothingScenes[clothing.key] = { scene: null };
    console.error(`${clothing.key} has wrong URL: ${clothing.path}`);
    return loadedClothingScenes[clothing.key]
  }
  setLoadedClothing((prevState) => ({ ...prevState, ...loadedClothingScenes }));
  return loadedClothingScenes[clothing.key]
};

export {
  preloadCharacters,
  preloadFaces,
  preloadArm,
  preloadHeadGear,
  preloadClothing,
};
