'use client';
import React, { useEffect, useState } from 'react';
import styles from './slider.module.css';

const EcoGettingStarted = ({ isMobileView }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const totalSlides = 3;
  const slideDuration = 10; // Duration for each slide in seconds
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    let animationTimeout: any;
    let slideTimeout = setTimeout(() => {
      setIsAnimating(true); // Start the exit animation
      animationTimeout = setTimeout(() => {
        setActiveSlide((prevSlide) => (prevSlide + 1) % totalSlides);
        // setActiveSlide(2);
        setIsAnimating(false); // End the exit animation, start the enter animation
      }, 800); // This timeout should match your CSS animation duration
    }, slideDuration * 1000); // Start the exit animation a bit before changing slides

    return () => {
      clearTimeout(slideTimeout);
      clearTimeout(animationTimeout);
    };
  }, [activeSlide]);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const safari = /^((?!chrome|android).)*safari/i.test(userAgent);
    setIsSafari(safari);
  }, []);

  const handleProgressBarClick = (index: number) => {
    setActiveSlide(index); // Set active slide to the clicked index
  };

  const handleRedirect = () => {
    window.location.href = 'https://app.primetrader.com/auth/signup'; // Replace 'https://example.com' with your desired external URL
  };

  return (
    <>
      {isMobileView ? (
        <section className='mob-get-started-section' id='benefits'>
          <div className='liner-shade'>
            <div className='get-started-container'>
              <h3>Getting Started</h3>
              <p>
                Follow our straightforward guide to start your journey to being
                a crypto trading master.
              </p>
              <div className='get-started-slider'>
                <div className={`slider-bar ${isSafari ? 'safari' : ''}`}>
                  <div className={styles['es-progress-bars-container']}>
                    {Array.from({ length: totalSlides }).map((_, index) => (
                      <div
                        key={index}
                        className={styles['es-progress-bar-container']}
                        onClick={() => handleProgressBarClick(index)}
                      >
                        <div
                          className={`${styles['es-progress-bar']} ${index === activeSlide ? styles['es-slide-active'] : styles['es-slide-inactive']}`}
                          style={{
                            animationDuration: `${index === activeSlide ? slideDuration : 0}s`
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles['es-slider']}>
              <div className={styles['es-slides-container']}>
                {/* Slide 1 */}
                <div
                  key={0}
                  className={`slide ${0 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}
                  style={{
                    display: activeSlide === 0 ? 'block' : 'none'
                  }}
                >
                  <div className='get-started-signup-section'>
                    <h4>Sign up</h4>
                    <ul>
                      <li>Sign up & join the airdrop</li>
                      <li>
                        Create your trading avatar & claim your first free NFT
                      </li>
                      <li>
                        Claim your stake in the 200M PTT airdrop and referral
                        pool
                      </li>
                    </ul>
                    <a href='#'>Sign Up</a>
                    <div className='signupWithGlow'>
                      <div className='signupGlowMob'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='644'
                          height='643'
                          viewBox='0 0 644 643'
                          fill='none'
                        >
                          <g opacity='0.3' filter='url(#filter0_f_7803_9871)'>
                            <circle
                              cx='322.169'
                              cy='321.389'
                              r='121.159'
                              transform='rotate(75 322.169 321.389)'
                              fill='url(#paint0_radial_7803_9871)'
                            />
                            <circle
                              cx='322.169'
                              cy='321.389'
                              r='121.159'
                              transform='rotate(75 322.169 321.389)'
                              fill='url(#paint1_linear_7803_9871)'
                              fillOpacity='0.9'
                              style={{ mixBlendMode: 'overlay' }}
                            />
                          </g>
                          <defs>
                            <filter
                              id='filter0_f_7803_9871'
                              x='0.979492'
                              y='0.199219'
                              width='642.379'
                              height='642.379'
                              filterUnits='userSpaceOnUse'
                              colorInterpolationFilters='sRGB'
                            >
                              <feFlood
                                floodOpacity='0'
                                result='BackgroundImageFix'
                              />
                              <feBlend
                                mode='normal'
                                in='SourceGraphic'
                                in2='BackgroundImageFix'
                                result='shape'
                              />
                              <feGaussianBlur
                                stdDeviation='100'
                                result='effect1_foregroundBlur_7803_9871'
                              />
                            </filter>
                            <radialGradient
                              id='paint0_radial_7803_9871'
                              cx='0'
                              cy='0'
                              r='1'
                              gradientUnits='userSpaceOnUse'
                              gradientTransform='translate(386.829 361.776) rotate(-92.637) scale(614.02 275.371)'
                            >
                              <stop stopColor='#602EA6' />
                              <stop offset='1' stopColor='#C977D6' />
                            </radialGradient>
                            <linearGradient
                              id='paint1_linear_7803_9871'
                              x1='322.169'
                              y1='144.699'
                              x2='322.169'
                              y2='442.549'
                              gradientUnits='userSpaceOnUse'
                            >
                              <stop stopColor='#FEC8F1' />
                              <stop
                                offset='0.403918'
                                stopColor='#FEC8F1'
                                stopOpacity='0'
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <img
                        src='/images_p/gettingStart/gettingStartedSlidev1.webp'
                        alt=''
                        style={{ width: '110%' }}
                      />
                    </div>
                  </div>
                </div>

                {/* Slide 2 */}
                <div
                  key={1}
                  className={`slide ${1 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}
                  style={{
                    display: activeSlide === 1 ? 'block' : 'none'
                  }}
                >
                  <div className='get-started-signup-section'>
                    <h4>Trade</h4>
                    <div className='tradeWithGlow'>
                      <div className='tradeGlowMob'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='695'
                          height='695'
                          viewBox='0 0 695 695'
                          fill='none'
                        >
                          <g opacity='0.4' filter='url(#filter0_f_7800_9825)'>
                            <circle
                              cx='347.5'
                              cy='347.188'
                              r='147.123'
                              transform='rotate(75 347.5 347.188)'
                              fill='url(#paint0_radial_7800_9825)'
                            />
                            <circle
                              cx='347.5'
                              cy='347.188'
                              r='147.123'
                              transform='rotate(75 347.5 347.188)'
                              fill='url(#paint1_linear_7800_9825)'
                              fillOpacity='0.9'
                              style={{ mixBlendMode: 'overlay' }}
                            />
                          </g>
                          <defs>
                            <filter
                              id='filter0_f_7800_9825'
                              x='0.341064'
                              y='0.0292969'
                              width='694.318'
                              height='694.318'
                              filterUnits='userSpaceOnUse'
                              colorInterpolationFilters='sRGB'
                            >
                              <feFlood
                                floodOpacity='0'
                                result='BackgroundImageFix'
                              />
                              <feBlend
                                mode='normal'
                                in='SourceGraphic'
                                in2='BackgroundImageFix'
                                result='shape'
                              />
                              <feGaussianBlur
                                stdDeviation='100'
                                result='effect1_foregroundBlur_7800_9825'
                              />
                            </filter>
                            <radialGradient
                              id='paint0_radial_7800_9825'
                              cx='0'
                              cy='0'
                              r='1'
                              gradientUnits='userSpaceOnUse'
                              gradientTransform='translate(426.016 396.229) rotate(-92.637) scale(745.598 334.38)'
                            >
                              <stop stopColor='#602EA6' />
                              <stop offset='1' stopColor='#C977D6' />
                            </radialGradient>
                            <linearGradient
                              id='paint1_linear_7800_9825'
                              x1='347.5'
                              y1='132.634'
                              x2='347.5'
                              y2='494.31'
                              gradientUnits='userSpaceOnUse'
                            >
                              <stop stopColor='#FEC8F1' />
                              <stop
                                offset='0.403918'
                                stopColor='#FEC8F1'
                                stopOpacity='0'
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <img
                        src='/images_p/gettingStart/gettingStartedSlidev2.webp'
                        alt=''
                      />
                    </div>
                  </div>
                </div>

                {/* Slide 3 */}
                <div
                  key={2}
                  className={`slide ${2 === activeSlide ? (isAnimating ? styles['es-slide-exit'] : styles['es-slide-enter']) : ''}`}
                  style={{
                    display: activeSlide === 2 ? 'block' : 'none'
                  }}
                >
                  <div className='get-started-signup-section'>
                    <h4>Earn</h4>
                    <div className='earnWithGlow'>
                      <div className='earnGlowMob'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='695'
                          height='695'
                          viewBox='0 0 695 695'
                          fill='none'
                        >
                          <g opacity='0.4' filter='url(#filter0_f_7800_9825)'>
                            <circle
                              cx='347.5'
                              cy='347.188'
                              r='147.123'
                              transform='rotate(75 347.5 347.188)'
                              fill='url(#paint0_radial_7800_9825)'
                            />
                            <circle
                              cx='347.5'
                              cy='347.188'
                              r='147.123'
                              transform='rotate(75 347.5 347.188)'
                              fill='url(#paint1_linear_7800_9825)'
                              fillOpacity='0.9'
                              style={{ mixBlendMode: 'overlay' }}
                            />
                          </g>
                          <defs>
                            <filter
                              id='filter0_f_7800_9825'
                              x='0.341064'
                              y='0.0292969'
                              width='694.318'
                              height='694.318'
                              filterUnits='userSpaceOnUse'
                              colorInterpolationFilters='sRGB'
                            >
                              <feFlood
                                floodOpacity='0'
                                result='BackgroundImageFix'
                              />
                              <feBlend
                                mode='normal'
                                in='SourceGraphic'
                                in2='BackgroundImageFix'
                                result='shape'
                              />
                              <feGaussianBlur
                                stdDeviation='100'
                                result='effect1_foregroundBlur_7800_9825'
                              />
                            </filter>
                            <radialGradient
                              id='paint0_radial_7800_9825'
                              cx='0'
                              cy='0'
                              r='1'
                              gradientUnits='userSpaceOnUse'
                              gradientTransform='translate(426.016 396.229) rotate(-92.637) scale(745.598 334.38)'
                            >
                              <stop stopColor='#602EA6' />
                              <stop offset='1' stopColor='#C977D6' />
                            </radialGradient>
                            <linearGradient
                              id='paint1_linear_7800_9825'
                              x1='347.5'
                              y1='132.634'
                              x2='347.5'
                              y2='494.31'
                              gradientUnits='userSpaceOnUse'
                            >
                              <stop stopColor='#FEC8F1' />
                              <stop
                                offset='0.403918'
                                stopColor='#FEC8F1'
                                stopOpacity='0'
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <img
                        src='/images_p/gettingStart/gettingStartedSlidev3.webp'
                        alt=''
                      />
                    </div>

                    <a href='#'>Sign Up</a>
                  </div>
                </div>
              </div>
            </div>

            <div className='bridging-the-world'>
              <h3>Bridging the world to Web3</h3>
              <img
                className='responsive'
                src={'/images_p/bridge-img.webp'}
                alt='bridge-img'
                loading='eager'
              />
              <p>
                Seamless and Early access to the latest and trending tokens and
                projects all in one platform thanks to liquidity aggregator. We
                bring down the barriers of centralized exchanges and Web3
                friction while integrating crypto into the financial layer.{' '}
              </p>
              <a href='#'>Get Started</a>
            </div>

            <div className='bridging-the-world-child'>
              <h3>Gamified Trade</h3>
              <img
                className='responsive gamified-trade-img'
                src={'/images_p/gamified-trade-img.webp'}
                alt='gamified-trade-img'
                loading='eager'
              />
              <p>
                Our revolutionary platform saves you time and gives you more
                confidence in your investment decisions so you can have a
                greater chance of success. Invest with confidence with lightning
                speed, reacting instantly to changes in the market.{' '}
              </p>
              <a href='#'>Get Started</a>
            </div>

            <div className='bridging-the-world-child'>
              <h3>Trade With The Best</h3>
              <img
                className='responsive trade-with-the-best-img'
                src={'/images_p/trade-best-img.webp'}
                alt='bridge-img'
                loading='eager'
              />
              <p>
                Have a first-mover advantage by co-investing with industry
                leading crypto experts and fund managers. You profit from one of
                the fastest and exciting growing industries in the world.{' '}
              </p>
              <a href='#'>Get Started</a>
            </div>
          </div>
        </section>
      ) : (
        <>
          <section className='get-started-section'>
            <div className='liner-shade'>
              <div
                className='container0'
                id='benefits'
                style={{ paddingTop: '50px' }}
              >
                <h3>Getting Started</h3>
                <p>
                  Follow our straightforward guide to start your journey to
                  being a crypto trading master.
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 33,
                    background: 'transparent'
                  }}
                >
                  <div
                    onClick={() => setCurrentIndex(0)}
                    className={`${currentIndex == 0 ? 'RoadMapBtnSelected' : 'RoadMapBtnOutline'}`}
                    style={{ margin: '0 10px' }}
                  >
                    Sign Up
                  </div>

                  <div
                    onClick={() => setCurrentIndex(1)}
                    className={`${currentIndex == 1 ? 'RoadMapBtnSelected' : 'RoadMapBtnOutline'}`}
                    style={{ margin: '0 10px' }}
                  >
                    Trade
                  </div>
                  <div
                    onClick={() => setCurrentIndex(2)}
                    className={`${currentIndex == 2 ? 'RoadMapBtnSelected' : 'RoadMapBtnOutline'}`}
                    style={{ margin: '0 10px' }}
                  >
                    Earn
                  </div>
                </div>
                <div className='myOwnConSlider'>
                  <div
                    className='Con'
                    style={{
                      alignItems: 'flex-start',
                      transform: `translateX(calc(100dvw * ${currentIndex} * -1))`
                    }}
                  >
                    <div className='slide'>
                      <div className='signupSlide'>
                        <div className='img-box'>
                          <img
                            src={'/images_p/get-started-img.webp'}
                            alt='get-started-img'
                            loading='eager'
                            className='geting-started-image'
                          />
                        </div>
                        <div
                          className='SignupBox'
                          style={{
                            marginTop: '-50px'
                          }}
                        >
                          <h4>Sign Up</h4>
                          <ul>
                            <li>
                              Claim your stake in the 200M PTT airdrop and
                              referral pool
                            </li>
                            <li>
                              Create your trading avatar & claim your first free
                              NFT
                            </li>
                            <li>
                              Stay ahead of the market with our AI-powered news
                            </li>
                          </ul>
                          <div
                            className='LaunchNavBtn'
                            style={{ width: '100%' }}
                            onClick={handleRedirect}
                          >
                            <label>Sign Up</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='slide'>
                      <div className='TradeSlide'>
                        <img
                          src={'/images_p/gettingStart/slide2d.webp'}
                          alt='get-started-img'
                          // className='responsive'
                          loading='eager'
                        />
                      </div>
                    </div>
                    <div className='slide'>
                      <div className='EarnSlide'>
                        <div className='imgTop'>
                          <div className='backDrop'></div>
                          <img
                            src={'/images_p/gettingStart/slide3d.webp'}
                            alt='get-started-img'
                            className='responsive'
                            loading='eager'
                          />
                        </div>
                        <div
                          className='LaunchNavBtn'
                          style={{ width: '373px', zIndex: '500' }}
                          onClick={handleRedirect}
                        >
                          <label>Sign Up</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='bridging-sections'>
            <div className='liner-shade'>
              <div className='container'>
                <div className='flex-box'>
                  <div className='txt-box'>
                    <h3>Bridging the world to Web3</h3>
                    <p className='web3-p'>
                      Seamless and Early access to the latest and trending
                      tokens and projects all in one platform thanks to
                      liquidity aggregator. We bring down the barriers of
                      centralized exchanges and Web3 friction while integrating
                      crypto into the financial layer.{' '}
                    </p>
                    <div className='LaunchNavBtn' style={{ width: '141px' }}>
                      <label>Get Started</label>
                    </div>
                  </div>
                  <div className='img-box'>
                    <img
                      className='responsive'
                      src={'/images_p/bridge-img.webp'}
                      alt='bridge-img'
                      loading='eager'
                    />
                  </div>
                </div>
                <div className='gamified-trade-box' id='game'>
                  <div className='flex-box'>
                    <div className='img-box'>
                      <img
                        className='responsive gamified-trade-img'
                        src={'/images_p/gamified-trade-img.webp'}
                        alt='gamified-trade-img'
                        loading='eager'
                      />
                    </div>
                    <div className='txt-box'>
                      <h3>Gamified Trade</h3>
                      <p className='web3-p'>
                        Our revolutionary platform saves you time and gives you
                        more confidence in your investment decisions so you can
                        have a greater chance of success. Invest with confidence
                        with lightning speed, reacting instantly to changes in
                        the market.
                      </p>
                      <div className='LaunchNavBtn' style={{ width: '141px' }}>
                        <label>Get Started</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex-box'>
                  <div className='txt-box'>
                    <h3>Trade With The Best</h3>
                    <p className='web3-p'>
                      Have a first-mover advantage by co-investing with industry
                      leading crypto experts and fund managers.You profit from
                      one of the fastest and exciting growing industries in the
                      world
                    </p>
                    <div className='LaunchNavBtn' style={{ width: '141px' }}>
                      <label>Get Started</label>
                    </div>
                  </div>
                  <div className='img-box'>
                    <img
                      className='responsive trade-with-the-best-img'
                      style={{
                        width: 400,
                        marginRight: '22px'
                      }}
                      src={'/images_p/trade-best-img.webp'}
                      alt='bridge-img'
                      loading='eager'
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default EcoGettingStarted;
