/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
export default function MeetTrading({ isMobileView }: any) {
  // const [currentImage, setCurrentImage] = useState(0);
  // const [isLoading, setIsLoading] = useState(true);
  // const [images, setImages] = useState([]);
  // let tol = 0;
  // let Complete = false;
  // let minX = 0;
  // const productViewerRef = useRef(null);

  // const handleScroll = () => {
  //   const element = productViewerRef.current;
  //   if (element) {
  //     // Ensure the ref is current and the images are loaded
  //     // if (!productViewerRef.current || images.length === 0) {
  //     //   return;
  //     // }

  //     const element = productViewerRef.current;
  //     const windowHeight = window.innerHeight;
  //     const { top, bottom, height } = element.getBoundingClientRect();
  //     // Check if the element is within the viewport
  //     if (top < windowHeight && bottom >= 0 && top > 0) {
  //       // Determine the scroll progress within the element
  //       const visibleHeight = Math.min(windowHeight, bottom) - Math.max(0, top);
  //       const scrollProgress = visibleHeight / height;

  //       // Calculate and set the current image based on scroll progress
  //       // Adjust totalImages to the length of your images array
  //       const totalImages = 90;
  //       const imgIndex = Math.floor(scrollProgress * (totalImages - 1));
  //       if (imgIndex > 90) {
  //         setCurrentImage(90);
  //         Complete = true;
  //       } else if (imgIndex <= 0) {
  //         setCurrentImage(1);
  //       } else {
  //         setCurrentImage(imgIndex);
  //         if (imgIndex > 87) {
  //           Complete = true;
  //         }
  //       }
  //     }
  //   }
  // };
  // const readFilesFromFolder = async (folderPath: any) => {
  //   let files = [];
  //   for (let i = 0; i < 90; i++) {
  //     files.push(
  //       `Trading_Meets_Innovation_Seq_02_000${String(i).padStart(2, '0')}.png`
  //     );
  //   }
  //   return files;
  // };
  // const readFileAsBase64 = async (filePath: any) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       resolve(event.target.result);
  //     };
  //     reader.onerror = (error) => {
  //       reject(error);
  //     };
  //     // Read the file as base64
  //     fetch(filePath)
  //       .then((response) => response.blob())
  //       .then((blob) => reader.readAsDataURL(blob))
  //       .catch((error) => reject(error));
  //   });
  // };
  // useEffect(() => {
  //   if (!isMobileView) {
  //     const productViewer = productViewerRef.current;
  //     minX = productViewer.getBoundingClientRect().left;
  //     tol = (productViewer.getBoundingClientRect().right - minX) / 120;
  //     // productViewer.addEventListener('touchmove', handleTouchMove);
  //     // productViewer.addEventListener('mousemove', handleMouseMove);
  //     document.addEventListener('scroll', handleScroll);
  //     // ////////////////////////////////////////////////////////////////////////
  //     const fetchImages = async () => {
  //       try {
  //         const folderPath = `/assets`;
  //         const files = await readFilesFromFolder(folderPath);
  //         // Read each file and convert it to base64
  //         const imagePromises = files.map(async (file) => {
  //           const base64 = await readFileAsBase64(`${folderPath}/${file}`);
  //           return {
  //             name: file,
  //             data: base64
  //           };
  //         });
  //         // Wait for all promises to resolve and update the state
  //         Promise.all(imagePromises).then((imageData) => {
  //           // console.log(imageData);
  //           setImages(imageData);
  //           setIsLoading(false);
  //         });
  //       } catch (error) {
  //         console.error('Error reading images:', error);
  //       }
  //     };
  //     fetchImages();
  //     // ///////////////////////////////////////////////////////////////
  //   }
  //   return () => {};
  // }, []);
  return (
    <>
      {isMobileView ? (
        <section className='mob-trading-meets-section'>
          <div className='liner-shade'>
            <div className='meets-container'>
              <h2>Trading meets Innovation</h2>
              <div className='top-meets'>
                <h3 style={{ fontSize: 16 }}>BLOCKCHAIN</h3>
                <ul style={{ fontSize: 14 }}>
                  <li>Account Abstraction</li>
                  <li>Digital Collectibles</li>
                  <li>{'Hybrid Liquidity (CeFi<>DeFi)'}</li>
                  <li>On-chain Copy Trading</li>
                  <li>On-chain Trading Reputation</li>
                </ul>
              </div>
              <img
                src='/images_p/mobile-trading-meets.webp'
                alt=''
                style={{ marginTop: '-39px' }}
              />
              <div className='bottom-meets'>
                <h3>Artifical Intelligence</h3>
                <ul>
                  <li>AI-driven Copy Trading</li>
                  <li>Virtual Trading Assistant</li>
                  <li>Predictive Social Sentiment Analysis</li>
                  <li>Personalized Portfolio & Risk Management</li>
                  <li>Personalized Learning and Onboarding</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <>
          <section className='trading-meets-section'>
            <div className='liner-shade'>
              <div className='container'>
                <h3>Trading meets Innovation</h3>
                <div className='img-box'>
                  <img
                    style={{
                      width: '100%',
                      transition: 'transform 0.5s ease-in-out'
                    }}
                    src={'/images_p/trading-meet-final.webp'}
                    alt='Product'
                  />
                </div>
              </div>
            </div>
          </section>
          {/* <section className='trading-meets-section'>
            <div className='liner-shade'>
              <div className='container'>
                <h3>Trading meets Innovation</h3>
                <div className='img-box'></div>
              </div>
            </div>

            <div
              style={{
                width: '100%',
                height: 'auto',
                overflow: 'hidden',
                background: 'black',
                margin: 'auto',
                textAlign: 'center'
              }}
              ref={productViewerRef}
            >
              {!isLoading && (
                <img
                  style={{
                    width: '100%',
                    transition: 'transform 0.5s ease-in-out'
                  }}
                  src={images[currentImage]?.data}
                  alt='Product'
                />
              )}
            </div>
          </section> */}
        </>
      )}
    </>
  );
}
/* eslint-enable */
