export const metadata = {
  categories: ['Characters', 'Face', 'Head Gear', 'Arm', 'Clothing'],
  categoryImages: {
    Characters: '/builder/unity-assets-new/charactersIcon.webp',
    Face: '/builder/unity-assets-new/faceIcon.webp',
    'Head Gear': '/builder/unity-assets-new/headgareIcon.webp',
    Arm: '/builder/unity-assets-new/armIcon.webp',
    Clothing: '/builder/unity-assets-new/clothingIcon.webp'
  },

  categoryVariants: {
    Characters: [],
    Face: [],
    'Head Gear': [
      '/builder/characters/variants/headgare1.webp',
      '/builder/characters/variants/headgare2.webp'
    ],
    Arm: [],
    Clothing: [
      '/builder/characters/variants/clothes1.webp',
      '/builder/characters/variants/clothes2.webp',
      '/builder/characters/variants/clothes3.webp',
    ],
  },

  Characters: [
    {
      key: 'Rodrigo',
      code: '#R',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B1_31.glb',
      icon: '/builder/characters/charR.webp',
      default: {
        Face: {
          key: '#R_Human _Face',
          path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Faces/%23R_Human_Face.glb',
          icon: 'builder/unity-assets/face1.webp'
        },
        Arm: {
          key: '#R_Human_Body',
          path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Body/%23R_Human_Body.glb',
          icon: '/builder/unity-assets/arm1.webp'
        },
        'Head Gear': {
          key: 'HA_None',
          path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B1_31.glb',
          icon: '/builder/unity-assets/helmet1.png'
        },
        Clothing: {
          key: '#G_TCM_Default',
          path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_Default.glb',
          icon: '/builder/unity-assets/cloth1.png'
        }
      }
    },
    {
      code: '#W',
      key: 'Wesley',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Body/%23W_Human_Body.glb',
      icon: '/builder/characters/charW.webp',
      default: {
        Face: {
          key: '#W_Human _Face',
          path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Faces/%23W_Human_Face.glb',
          icon: 'builder/unity-assets/face1.webp'
        },
        Arm: {
          key: '#W_Human_Body',
          path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Body/%23W_Human_Body.glb',
          icon: '/builder/unity-assets/arm1.webp'
        },
        'Head Gear': {
          key: 'HA_None',
          path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B1_31.glb',
          icon: '/builder/unity-assets/helmet1.png'
        },
        Clothing: {
          key: '#G_TCM_Default',
          path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_Default.glb',
          icon: '/builder/unity-assets/cloth1.png'
        }
      }
    }
  ],
  Face: [
    {
      key: '#R_Human _Face',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Faces/%23R_Human_Face.glb',
      icon: '/builder/characters/R/Face/Rodrigo - Face - Normal.webp'
    },
    {
      key: '#W_Human _Face',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Faces/%23W_Human_Face.glb',
      icon: '/builder/characters/W/Face/Wesley - Face - Normal.webp'
    },
    {
      key: '#W_Magna_Face',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Faces/%23W_Magna_Face.glb',
      icon: '/builder/characters/W/Face/Wesley - Face - Cyborg.webp'
    },
    {
      key: '#R_Magna_Face',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Faces/%23R_Magna_Face.glb',
      icon: '/builder/characters/R/Face/Rodrigo - Face - Cyborg.webp'
    }
    // {
    //   key: "#R_Magna_Face",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Faces/%23R_Magna_Face.glb",
    //   icon: "/builder/unity-assets/face1.webp",
    // },
    // {
    //   key: "#W_Magna_Face",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Faces/%23W_Magna_Face.glb",
    //   icon: "/builder/unity-assets/face1.webp",
    // },
    // {
    //   key: "Rodrigo_Hair",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Faces/%23R_Human_Face.glb",
    //   icon: "/builder/unity-assets/face1.webp",
    // },
    // {
    //   key: "Wesley_Hair",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Faces/%23W_Human_Face.glb",
    //   icon: "/builder/unity-assets/face1.webp",
    // },
  ],
  Arm: [
    {
      key: '#R_Human_Body',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Body/%23R_Human_Body.glb',
      icon: '/builder/characters/R/Body/Rodrigo - Body - Normal.webp'
    },
    {
      key: '#W_Human_Body',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Body/%23W_Human_Body.glb',
      icon: '/builder/characters/W/Body/Wesley - Body - Normal.webp'
    },
    {
      key: '#R_Robotic Arm 03',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Body/%23R_Robotic+Arm+03.glb',
      icon: '/builder/characters/R/Body/Rodrigo - Body - Cyborg Body.webp'
    },
    {
      key: '#W_Robotic Arm 03',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Body/%23W_Robotic+Arm+03.glb',
      icon: '/builder/characters/W/Body/Wesley - Body - Cyborg.webp'
    }
    // {
    //   key: "#R_Robotic Arm 05",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Body/%23R_Robotic+Arm+05.glb",
    //   icon: "/builder/unity-assets/arm2.webp",
    // },
    // {
    //   key: "#W_Robotic Arm 05",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Body/%23W_Robotic+Arm+05.glb",
    //   icon: "/builder/unity-assets/arm1.webp",
    // },
    // {
    //   key: "#R_Magna body",
    //   path: "",
    //   icon: "/builder/unity-assets/arm2.webp",
    // },
    // {
    //   key: "#W_Magna body",
    //   path: "",
    //   icon: "/builder/unity-assets/arm1.webp",
    // },
  ],
  'Head Gear': [
    {
      key: '#G_HAM_B1_31#W',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B1_31.glb',
      icon: '/builder/characters/W/Head Gear/Wesley - Head Assets - B1_31.webp'
    },
    {
      key: '#G_HAM_B1_32#W',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B1_32.glb',
      icon: '/builder/characters/W/Head Gear/Wesley - Head Assets - B1_32.webp'
    },
    {
      key: '#23G_HAM_B4_02#W',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B4_02.glb',
      icon: '/builder/characters/W/Head Gear/Wesley - Head Assets - B4_02.webp'
    },
    {
      key: '#G_HAM_B4_06#W',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B4_06.glb',
      icon: '/builder/characters/W/Head Gear/Wesley - Head Assets - B4_06.webp'
    },
    {
      key: '#G_HAM_B1_31#R',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B1_31.glb',
      icon: '/builder/characters/R/Head Gear/Rodrigo - Head Assets - B1_31.webp'
    },
    {
      key: '#G_HAM_B1_32#R',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B1_32.glb',
      icon: '/builder/characters/R/Head Gear/Rodrigo - Head Assets - B1_32.webp'
    },
    {
      key: '#23G_HAM_B4_02#R',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B4_02.glb',
      icon: '/builder/characters/R/Head Gear/Rodrigo - Head Assets - B4_02.webp'
    },
    {
      key: '#G_HAM_B4_06#R',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B4_06.glb',
      icon: '/builder/characters/R/Head Gear/Rodrigo - Head Assets - B4_06.webp'
    }
    // {
    //   key: "#G_HAM_B2_14",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B2_14.glb",
    //   icon: "/builder/unity-assets/helmet4.png",
    // },
    // {
    //   key: "#G_HAM_B2_20",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B2_20.glb",
    //   icon: "/builder/unity-assets/helmet5.png",
    // },
    // {
    //   key: "#R_HAM_B2_25",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23R_HAM_B2_25.glb",
    //   icon: "/builder/unity-assets/helmet6.png",
    // },
    // {
    //   key: "#W_HAM_B2_25",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23W_HAM_B2_25.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#R_HAM_B3_05",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23R_HAM_B3_05.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#W_HAM_B3_05",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23W_HAM_B3_05.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#G_HAM_B3_06",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B3_06.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#R_HAM_B3_08",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23R_HAM_B3_08.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#W_HAM_B3_08",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23W_HAM_B3_08.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#G_HAM_B3_09",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B3_09.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#G_HAM_B3_11",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B3_11.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#G_HAM_B3_22",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B3_22.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#R_HAM_B4_01",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23R_HAM_B4_01.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#W_HAM_B4_01",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23W_HAM_B4_01.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#G_HAM_B4_02",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B4_02.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#G_HAM_B4_05",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B4_05_001.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
    // {
    //   key: "#G_HAM_B4_06",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Head+Assets/%23G_HAM_B4_06.glb",
    //   icon: "/builder/unity-assets/helmet1.png",
    // },
  ],
  Clothing: [
    {
      key: '#G_TCM_59_02#W',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_59_02.glb',
      icon: '/builder/characters/W/Clothing/Wesley - Clothing - TCM_59_02.webp'
    },
    {
      key: '#G_TCM_82#W',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_82.glb',
      icon: '/builder/characters/W/Clothing/Wesley - Clothing - TCM_82.webp'
    },
    {
      key: '#G_TCM_89#W',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_89.glb',
      icon: '/builder/characters/W/Clothing/Wesley - Clothing - TCM_89.webp'
    },
    {
      key: '#G_TCM_94#W',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_94.glb',
      icon: '/builder/characters/W/Clothing/Wesley - Clothing - TCM_94.webp'
    },
    {
      key: '#G_TCM_59_02#R',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_59_02.glb',
      icon: '/builder/characters/R/Clothing/Rodrigo - Clothing - TCM_59_02.webp'
    },
    {
      key: '#G_TCM_82#R',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_82.glb',
      icon: '/builder/characters/R/Clothing/Rodrigo - Clothing - TCM_82.webp'
    },
    {
      key: '#G_TCM_89#R',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_89.glb',
      icon: '/builder/characters/R/Clothing/Rodrigo - Clothing - TCM_89.webp'
    },
    {
      key: '#G_TCM_94#R',
      path: 'https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_94.glb',
      icon: '/builder/characters/R/Clothing/Rodrigo - Clothing - TCM_94.webp'
    }
    // {
    //   key: "#G_TCM_40",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_40.glb",
    //   icon: "/builder/unity-assets/cloth4.png",
    // },
    // {
    //   key: "#G_TCM_55",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_55.glb",
    //   icon: "/builder/unity-assets/cloth5.png",
    // },
    // {
    //   key: "#G_TCM_59_01",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_59_01.glb",
    //   icon: "/builder/unity-assets/cloth6.png",
    // },
    // {
    //   key: "#G_TCM_59_02",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_59_02.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
    // {
    //   key: "#G_TCM_59_03",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_59_03.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
    // {
    //   key: "#G_TCM_74",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_74.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
    // {
    //   key: "#G_TCM_81",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_81.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
    // {
    //   key: "#G_TCM_82",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_82.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
    // {
    //   key: "#G_TCM_85",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_85.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
    // {
    //   key: "#G_TCM_86",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_86.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
    // {
    //   key: "#G_TCM_88",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_88.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
    // {
    //   key: "#G_TCM_89",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_89.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
    // {
    //   key: "#G_TCM_91",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_91.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
    // {
    //   key: "#G_TCM_92",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_92.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
    // {
    //   key: "#G_TCM_94",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_94.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
    // {
    //   key: "#G_TCM_97",
    //   path: "https://prime-traders-dev.s3.eu-west-3.amazonaws.com/Assets/Clothes/%23G_TCM_97.glb",
    //   icon: "/builder/unity-assets/cloth1.png",
    // },
  ],
  compatibility: {
    Wesley_Rodrigo: 0,
    'Wesley_#R_Human _Face': 0,
    'Wesley_#W_Human _Face': 1,
    'Wesley_#R_CyborgTrader': 0,
    'Wesley_#W_CyborgTrader': 1,
    'Wesley_#R_Magna_Face': 0,
    'Wesley_#W_Magna_Face': 1,
    Wesley_Wesley_Hair: 1,
    Wesley_Rodrigo_Hair: 0,
    'Wesley_#R_Human_Body': 0,
    'Wesley_#W_Human_Body': 1,
    'Wesley_#R_Robotic Arm 03': 0,
    'Wesley_#W_Robotic Arm 03': 1,
    'Wesley_#R_Robotic Arm 05': 0,
    'Wesley_#W_Robotic Arm 05': 1,
    'Wesley_#R_Magna body': 0,
    'Wesley_#W_Magna body': 1,
    Wesley_HA_None: 1,
    'Wesley_#G_HAM_B1_22': 1,
    'Wesley_#G_HAM_B1_31': 1,
    'Wesley_#G_HAM_B1_32': 1,
    'Wesley_#G_HAM_B2_14': 1,
    'Wesley_#G_HAM_B2_20': 1,
    'Wesley_#R_HAM_B2_25': 0,
    'Wesley_#W_HAM_B2_25': 1,
    'Wesley_#R_HAM_B3_05': 0,
    'Wesley_#W_HAM_B3_05': 1,
    'Wesley_#G_HAM_B3_06': 1,
    'Wesley_#R_HAM_B3_08': 0,
    'Wesley_#W_HAM_B3_08': 1,
    'Wesley_#G_HAM_B3_09': 1,
    'Wesley_#G_HAM_B3_11': 1,
    'Wesley_#G_HAM_B3_22': 1,
    'Wesley_#R_HAM_B4_01': 0,
    'Wesley_#W_HAM_B4_01': 1,
    'Wesley_#G_HAM_B4_02': 1,
    'Wesley_#G_HAM_B4_05': 1,
    'Wesley_#G_HAM_B4_06': 1,
    'Wesley_#G_TCM_Default': 1,
    'Wesley_#G_TCM_00': 1,
    'Wesley_#G_TCM_07': 1,
    'Wesley_#G_TCM_27': 1,
    'Wesley_#G_TCM_40': 1,
    'Wesley_#G_TCM_55': 1,
    'Wesley_#G_TCM_59_01': 1,
    'Wesley_#G_TCM_59_02': 1,
    'Wesley_#G_TCM_59_03': 1,
    'Wesley_#G_TCM_74': 1,
    'Wesley_#G_TCM_81': 1,
    'Wesley_#G_TCM_82': 1,
    'Wesley_#G_TCM_85': 1,
    'Wesley_#G_TCM_86': 1,
    'Wesley_#G_TCM_88': 1,
    'Wesley_#G_TCM_89': 1,
    'Wesley_#G_TCM_91': 1,
    'Wesley_#G_TCM_92': 1,
    'Wesley_#G_TCM_94': 1,
    'Wesley_#G_TCM_97': 1,
    'Rodrigo_#R_Human _Face': 1,
    'Rodrigo_#W_Human _Face': 0,
    'Rodrigo_#R_CyborgTrader': 1,
    'Rodrigo_#W_CyborgTrader': 0,
    'Rodrigo_#R_Magna_Face': 1,
    'Rodrigo_#W_Magna_Face': 0,
    Rodrigo_Wesley_Hair: 0,
    Rodrigo_Rodrigo_Hair: 1,
    'Rodrigo_#R_Human_Body': 1,
    'Rodrigo_#W_Human_Body': 0,
    'Rodrigo_#R_Robotic Arm 03': 1,
    'Rodrigo_#W_Robotic Arm 03': 0,
    'Rodrigo_#R_Robotic Arm 05': 1,
    'Rodrigo_#W_Robotic Arm 05': 0,
    'Rodrigo_#R_Magna body': 1,
    'Rodrigo_#W_Magna body': 0,
    Rodrigo_HA_None: 1,
    'Rodrigo_#G_HAM_B1_22': 1,
    'Rodrigo_#G_HAM_B1_31': 1,
    'Rodrigo_#G_HAM_B1_32': 1,
    'Rodrigo_#G_HAM_B2_14': 1,
    'Rodrigo_#G_HAM_B2_20': 1,
    'Rodrigo_#R_HAM_B2_25': 1,
    'Rodrigo_#W_HAM_B2_25': 0,
    'Rodrigo_#R_HAM_B3_05': 1,
    'Rodrigo_#W_HAM_B3_05': 0,
    'Rodrigo_#G_HAM_B3_06': 1,
    'Rodrigo_#R_HAM_B3_08': 1,
    'Rodrigo_#W_HAM_B3_08': 0,
    'Rodrigo_#G_HAM_B3_09': 1,
    'Rodrigo_#G_HAM_B3_11': 1,
    'Rodrigo_#G_HAM_B3_22': 1,
    'Rodrigo_#R_HAM_B4_01': 1,
    'Rodrigo_#W_HAM_B4_01': 0,
    'Rodrigo_#G_HAM_B4_02': 1,
    'Rodrigo_#G_HAM_B4_05': 1,
    'Rodrigo_#G_HAM_B4_06': 1,
    'Rodrigo_#G_TCM_Default': 1,
    'Rodrigo_#G_TCM_00': 1,
    'Rodrigo_#G_TCM_07': 1,
    'Rodrigo_#G_TCM_27': 1,
    'Rodrigo_#G_TCM_40': 1,
    'Rodrigo_#G_TCM_55': 1,
    'Rodrigo_#G_TCM_59_01': 1,
    'Rodrigo_#G_TCM_59_02': 1,
    'Rodrigo_#G_TCM_59_03': 1,
    'Rodrigo_#G_TCM_74': 1,
    'Rodrigo_#G_TCM_81': 1,
    'Rodrigo_#G_TCM_82': 1,
    'Rodrigo_#G_TCM_85': 1,
    'Rodrigo_#G_TCM_86': 1,
    'Rodrigo_#G_TCM_88': 1,
    'Rodrigo_#G_TCM_89': 1,
    'Rodrigo_#G_TCM_91': 1,
    'Rodrigo_#G_TCM_92': 1,
    'Rodrigo_#G_TCM_94': 1,
    'Rodrigo_#G_TCM_97': 1,
    '#R_Human _Face_#W_Human _Face': 0,
    '#R_Human _Face_#R_CyborgTrader': 0,
    '#R_Human _Face_#W_CyborgTrader': 0,
    '#R_Human _Face_#R_Magna_Face': 0,
    '#R_Human _Face_#W_Magna_Face': 0,
    '#R_Human _Face_Wesley_Hair': 0,
    '#R_Human _Face_Rodrigo_Hair': 0,
    '#R_Human _Face_#R_Human_Body': 1,
    '#R_Human _Face_#W_Human_Body': 1,
    '#R_Human _Face_#R_Robotic Arm 03': 1,
    '#R_Human _Face_#W_Robotic Arm 03': 1,
    '#R_Human _Face_#R_Robotic Arm 05': 1,
    '#R_Human _Face_#W_Robotic Arm 05': 1,
    '#R_Human _Face_#R_Magna body': 1,
    '#R_Human _Face_#W_Magna body': 1,
    '#R_Human _Face_HA_None': 1,
    '#R_Human _Face_#G_HAM_B1_22': 1,
    '#R_Human _Face_#G_HAM_B1_31': 1,
    '#R_Human _Face_#G_HAM_B1_32': 1,
    '#R_Human _Face_#G_HAM_B2_14': 1,
    '#R_Human _Face_#G_HAM_B2_20': 1,
    '#R_Human _Face_#R_HAM_B2_25': 1,
    '#R_Human _Face_#W_HAM_B2_25': 1,
    '#R_Human _Face_#R_HAM_B3_05': 1,
    '#R_Human _Face_#W_HAM_B3_05': 1,
    '#R_Human _Face_#G_HAM_B3_06': 1,
    '#R_Human _Face_#R_HAM_B3_08': 1,
    '#R_Human _Face_#W_HAM_B3_08': 1,
    '#R_Human _Face_#G_HAM_B3_09': 1,
    '#R_Human _Face_#G_HAM_B3_11': 1,
    '#R_Human _Face_#G_HAM_B3_22': 1,
    '#R_Human _Face_#R_HAM_B4_01': 1,
    '#R_Human _Face_#W_HAM_B4_01': 1,
    '#R_Human _Face_#G_HAM_B4_02': 1,
    '#R_Human _Face_#G_HAM_B4_05': 1,
    '#R_Human _Face_#G_HAM_B4_06': 1,
    '#R_Human _Face_#G_TCM_Default': 1,
    '#R_Human _Face_#G_TCM_00': 1,
    '#R_Human _Face_#G_TCM_07': 1,
    '#R_Human _Face_#G_TCM_27': 1,
    '#R_Human _Face_#G_TCM_40': 1,
    '#R_Human _Face_#G_TCM_55': 1,
    '#R_Human _Face_#G_TCM_59_01': 1,
    '#R_Human _Face_#G_TCM_59_02': 1,
    '#R_Human _Face_#G_TCM_59_03': 1,
    '#R_Human _Face_#G_TCM_74': 1,
    '#R_Human _Face_#G_TCM_81': 1,
    '#R_Human _Face_#G_TCM_82': 1,
    '#R_Human _Face_#G_TCM_85': 1,
    '#R_Human _Face_#G_TCM_86': 1,
    '#R_Human _Face_#G_TCM_88': 1,
    '#R_Human _Face_#G_TCM_89': 1,
    '#R_Human _Face_#G_TCM_91': 1,
    '#R_Human _Face_#G_TCM_92': 1,
    '#R_Human _Face_#G_TCM_94': 1,
    '#R_Human _Face_#G_TCM_97': 1,
    '#W_Human _Face_#R_CyborgTrader': 0,
    '#W_Human _Face_#W_CyborgTrader': 0,
    '#W_Human _Face_#R_Magna_Face': 0,
    '#W_Human _Face_#W_Magna_Face': 0,
    '#W_Human _Face_Wesley_Hair': 0,
    '#W_Human _Face_Rodrigo_Hair': 0,
    '#W_Human _Face_#R_Human_Body': 1,
    '#W_Human _Face_#W_Human_Body': 1,
    '#W_Human _Face_#R_Robotic Arm 03': 1,
    '#W_Human _Face_#W_Robotic Arm 03': 1,
    '#W_Human _Face_#R_Robotic Arm 05': 1,
    '#W_Human _Face_#W_Robotic Arm 05': 1,
    '#W_Human _Face_#R_Magna body': 1,
    '#W_Human _Face_#W_Magna body': 1,
    '#W_Human _Face_HA_None': 1,
    '#W_Human _Face_#G_HAM_B1_22': 1,
    '#W_Human _Face_#G_HAM_B1_31': 1,
    '#W_Human _Face_#G_HAM_B1_32': 1,
    '#W_Human _Face_#G_HAM_B2_14': 1,
    '#W_Human _Face_#G_HAM_B2_20': 1,
    '#W_Human _Face_#R_HAM_B2_25': 1,
    '#W_Human _Face_#W_HAM_B2_25': 1,
    '#W_Human _Face_#R_HAM_B3_05': 1,
    '#W_Human _Face_#W_HAM_B3_05': 1,
    '#W_Human _Face_#G_HAM_B3_06': 1,
    '#W_Human _Face_#R_HAM_B3_08': 1,
    '#W_Human _Face_#W_HAM_B3_08': 1,
    '#W_Human _Face_#G_HAM_B3_09': 1,
    '#W_Human _Face_#G_HAM_B3_11': 1,
    '#W_Human _Face_#G_HAM_B3_22': 1,
    '#W_Human _Face_#R_HAM_B4_01': 1,
    '#W_Human _Face_#W_HAM_B4_01': 1,
    '#W_Human _Face_#G_HAM_B4_02': 1,
    '#W_Human _Face_#G_HAM_B4_05': 1,
    '#W_Human _Face_#G_HAM_B4_06': 1,
    '#W_Human _Face_#G_TCM_Default': 1,
    '#W_Human _Face_#G_TCM_00': 1,
    '#W_Human _Face_#G_TCM_07': 1,
    '#W_Human _Face_#G_TCM_27': 1,
    '#W_Human _Face_#G_TCM_40': 1,
    '#W_Human _Face_#G_TCM_55': 1,
    '#W_Human _Face_#G_TCM_59_01': 1,
    '#W_Human _Face_#G_TCM_59_02': 1,
    '#W_Human _Face_#G_TCM_59_03': 1,
    '#W_Human _Face_#G_TCM_74': 1,
    '#W_Human _Face_#G_TCM_81': 1,
    '#W_Human _Face_#G_TCM_82': 1,
    '#W_Human _Face_#G_TCM_85': 1,
    '#W_Human _Face_#G_TCM_86': 1,
    '#W_Human _Face_#G_TCM_88': 1,
    '#W_Human _Face_#G_TCM_89': 1,
    '#W_Human _Face_#G_TCM_91': 1,
    '#W_Human _Face_#G_TCM_92': 1,
    '#W_Human _Face_#G_TCM_94': 1,
    '#W_Human _Face_#G_TCM_97': 1,
    '#R_CyborgTrader_#W_CyborgTrader': 0,
    '#R_CyborgTrader_#R_Magna_Face': 0,
    '#R_CyborgTrader_#W_Magna_Face': 0,
    '#R_CyborgTrader_Wesley_Hair': 0,
    '#R_CyborgTrader_Rodrigo_Hair': 0,
    '#R_CyborgTrader_#R_Human_Body': 1,
    '#R_CyborgTrader_#W_Human_Body': 1,
    '#R_CyborgTrader_#R_Robotic Arm 03': 1,
    '#R_CyborgTrader_#W_Robotic Arm 03': 1,
    '#R_CyborgTrader_#R_Robotic Arm 05': 1,
    '#R_CyborgTrader_#W_Robotic Arm 05': 1,
    '#R_CyborgTrader_#R_Magna body': 1,
    '#R_CyborgTrader_#W_Magna body': 1,
    '#R_CyborgTrader_HA_None': 1,
    '#R_CyborgTrader_#G_HAM_B1_22': 1,
    '#R_CyborgTrader_#G_HAM_B1_31': 1,
    '#R_CyborgTrader_#G_HAM_B1_32': 1,
    '#R_CyborgTrader_#G_HAM_B2_14': 1,
    '#R_CyborgTrader_#G_HAM_B2_20': 1,
    '#R_CyborgTrader_#R_HAM_B2_25': 0,
    '#R_CyborgTrader_#W_HAM_B2_25': 0,
    '#R_CyborgTrader_#R_HAM_B3_05': 1,
    '#R_CyborgTrader_#W_HAM_B3_05': 1,
    '#R_CyborgTrader_#G_HAM_B3_06': 1,
    '#R_CyborgTrader_#R_HAM_B3_08': 1,
    '#R_CyborgTrader_#W_HAM_B3_08': 1,
    '#R_CyborgTrader_#G_HAM_B3_09': 1,
    '#R_CyborgTrader_#G_HAM_B3_11': 1,
    '#R_CyborgTrader_#G_HAM_B3_22': 1,
    '#R_CyborgTrader_#R_HAM_B4_01': 1,
    '#R_CyborgTrader_#W_HAM_B4_01': 1,
    '#R_CyborgTrader_#G_HAM_B4_02': 1,
    '#R_CyborgTrader_#G_HAM_B4_05': 1,
    '#R_CyborgTrader_#G_HAM_B4_06': 1,
    '#R_CyborgTrader_#G_TCM_Default': 1,
    '#R_CyborgTrader_#G_TCM_00': 1,
    '#R_CyborgTrader_#G_TCM_07': 1,
    '#R_CyborgTrader_#G_TCM_27': 1,
    '#R_CyborgTrader_#G_TCM_40': 1,
    '#R_CyborgTrader_#G_TCM_55': 1,
    '#R_CyborgTrader_#G_TCM_59_01': 1,
    '#R_CyborgTrader_#G_TCM_59_02': 1,
    '#R_CyborgTrader_#G_TCM_59_03': 1,
    '#R_CyborgTrader_#G_TCM_74': 1,
    '#R_CyborgTrader_#G_TCM_81': 1,
    '#R_CyborgTrader_#G_TCM_82': 1,
    '#R_CyborgTrader_#G_TCM_85': 1,
    '#R_CyborgTrader_#G_TCM_86': 1,
    '#R_CyborgTrader_#G_TCM_88': 1,
    '#R_CyborgTrader_#G_TCM_89': 1,
    '#R_CyborgTrader_#G_TCM_91': 1,
    '#R_CyborgTrader_#G_TCM_92': 1,
    '#R_CyborgTrader_#G_TCM_94': 1,
    '#R_CyborgTrader_#G_TCM_97': 1,
    '#W_CyborgTrader_#R_Magna_Face': 0,
    '#W_CyborgTrader_#W_Magna_Face': 0,
    '#W_CyborgTrader_Wesley_Hair': 0,
    '#W_CyborgTrader_Rodrigo_Hair': 0,
    '#W_CyborgTrader_#R_Human_Body': 1,
    '#W_CyborgTrader_#W_Human_Body': 1,
    '#W_CyborgTrader_#R_Robotic Arm 03': 1,
    '#W_CyborgTrader_#W_Robotic Arm 03': 1,
    '#W_CyborgTrader_#R_Robotic Arm 05': 1,
    '#W_CyborgTrader_#W_Robotic Arm 05': 1,
    '#W_CyborgTrader_#R_Magna body': 1,
    '#W_CyborgTrader_#W_Magna body': 1,
    '#W_CyborgTrader_HA_None': 1,
    '#W_CyborgTrader_#G_HAM_B1_22': 1,
    '#W_CyborgTrader_#G_HAM_B1_31': 1,
    '#W_CyborgTrader_#G_HAM_B1_32': 1,
    '#W_CyborgTrader_#G_HAM_B2_14': 1,
    '#W_CyborgTrader_#G_HAM_B2_20': 1,
    '#W_CyborgTrader_#R_HAM_B2_25': 0,
    '#W_CyborgTrader_#W_HAM_B2_25': 0,
    '#W_CyborgTrader_#R_HAM_B3_05': 1,
    '#W_CyborgTrader_#W_HAM_B3_05': 1,
    '#W_CyborgTrader_#G_HAM_B3_06': 1,
    '#W_CyborgTrader_#R_HAM_B3_08': 1,
    '#W_CyborgTrader_#W_HAM_B3_08': 1,
    '#W_CyborgTrader_#G_HAM_B3_09': 1,
    '#W_CyborgTrader_#G_HAM_B3_11': 1,
    '#W_CyborgTrader_#G_HAM_B3_22': 1,
    '#W_CyborgTrader_#R_HAM_B4_01': 1,
    '#W_CyborgTrader_#W_HAM_B4_01': 1,
    '#W_CyborgTrader_#G_HAM_B4_02': 1,
    '#W_CyborgTrader_#G_HAM_B4_05': 1,
    '#W_CyborgTrader_#G_HAM_B4_06': 1,
    '#W_CyborgTrader_#G_TCM_Default': 1,
    '#W_CyborgTrader_#G_TCM_00': 1,
    '#W_CyborgTrader_#G_TCM_07': 1,
    '#W_CyborgTrader_#G_TCM_27': 1,
    '#W_CyborgTrader_#G_TCM_40': 1,
    '#W_CyborgTrader_#G_TCM_55': 1,
    '#W_CyborgTrader_#G_TCM_59_01': 1,
    '#W_CyborgTrader_#G_TCM_59_02': 1,
    '#W_CyborgTrader_#G_TCM_59_03': 1,
    '#W_CyborgTrader_#G_TCM_74': 1,
    '#W_CyborgTrader_#G_TCM_81': 1,
    '#W_CyborgTrader_#G_TCM_82': 1,
    '#W_CyborgTrader_#G_TCM_85': 1,
    '#W_CyborgTrader_#G_TCM_86': 1,
    '#W_CyborgTrader_#G_TCM_88': 1,
    '#W_CyborgTrader_#G_TCM_89': 1,
    '#W_CyborgTrader_#G_TCM_91': 1,
    '#W_CyborgTrader_#G_TCM_92': 1,
    '#W_CyborgTrader_#G_TCM_94': 1,
    '#W_CyborgTrader_#G_TCM_97': 1,
    '#R_Magna_Face_#W_Magna_Face': 0,
    '#R_Magna_Face_Wesley_Hair': 0,
    '#R_Magna_Face_Rodrigo_Hair': 0,
    '#R_Magna_Face_#R_Human_Body': 1,
    '#R_Magna_Face_#W_Human_Body': 1,
    '#R_Magna_Face_#R_Robotic Arm 03': 1,
    '#R_Magna_Face_#W_Robotic Arm 03': 1,
    '#R_Magna_Face_#R_Robotic Arm 05': 1,
    '#R_Magna_Face_#W_Robotic Arm 05': 1,
    '#R_Magna_Face_#R_Magna body': 1,
    '#R_Magna_Face_#W_Magna body': 1,
    '#R_Magna_Face_HA_None': 1,
    '#R_Magna_Face_#G_HAM_B1_22': 1,
    '#R_Magna_Face_#G_HAM_B1_31': 1,
    '#R_Magna_Face_#G_HAM_B1_32': 1,
    '#R_Magna_Face_#G_HAM_B2_14': 1,
    '#R_Magna_Face_#G_HAM_B2_20': 1,
    '#R_Magna_Face_#R_HAM_B2_25': 0,
    '#R_Magna_Face_#W_HAM_B2_25': 0,
    '#R_Magna_Face_#R_HAM_B3_05': 1,
    '#R_Magna_Face_#W_HAM_B3_05': 1,
    '#R_Magna_Face_#G_HAM_B3_06': 1,
    '#R_Magna_Face_#R_HAM_B3_08': 1,
    '#R_Magna_Face_#W_HAM_B3_08': 1,
    '#R_Magna_Face_#G_HAM_B3_09': 1,
    '#R_Magna_Face_#G_HAM_B3_11': 1,
    '#R_Magna_Face_#G_HAM_B3_22': 1,
    '#R_Magna_Face_#R_HAM_B4_01': 1,
    '#R_Magna_Face_#W_HAM_B4_01': 1,
    '#R_Magna_Face_#G_HAM_B4_02': 1,
    '#R_Magna_Face_#G_HAM_B4_05': 1,
    '#R_Magna_Face_#G_HAM_B4_06': 1,
    '#R_Magna_Face_#G_TCM_Default': 1,
    '#R_Magna_Face_#G_TCM_00': 1,
    '#R_Magna_Face_#G_TCM_07': 1,
    '#R_Magna_Face_#G_TCM_27': 1,
    '#R_Magna_Face_#G_TCM_40': 1,
    '#R_Magna_Face_#G_TCM_55': 1,
    '#R_Magna_Face_#G_TCM_59_01': 1,
    '#R_Magna_Face_#G_TCM_59_02': 1,
    '#R_Magna_Face_#G_TCM_59_03': 1,
    '#R_Magna_Face_#G_TCM_74': 1,
    '#R_Magna_Face_#G_TCM_81': 1,
    '#R_Magna_Face_#G_TCM_82': 1,
    '#R_Magna_Face_#G_TCM_85': 1,
    '#R_Magna_Face_#G_TCM_86': 1,
    '#R_Magna_Face_#G_TCM_88': 1,
    '#R_Magna_Face_#G_TCM_89': 1,
    '#R_Magna_Face_#G_TCM_91': 1,
    '#R_Magna_Face_#G_TCM_92': 1,
    '#R_Magna_Face_#G_TCM_94': 1,
    '#R_Magna_Face_#G_TCM_97': 1,
    '#W_Magna_Face_Wesley_Hair': 0,
    '#W_Magna_Face_Rodrigo_Hair': 0,
    '#W_Magna_Face_#R_Human_Body': 1,
    '#W_Magna_Face_#W_Human_Body': 1,
    '#W_Magna_Face_#R_Robotic Arm 03': 1,
    '#W_Magna_Face_#W_Robotic Arm 03': 1,
    '#W_Magna_Face_#R_Robotic Arm 05': 1,
    '#W_Magna_Face_#W_Robotic Arm 05': 1,
    '#W_Magna_Face_#R_Magna body': 1,
    '#W_Magna_Face_#W_Magna body': 1,
    '#W_Magna_Face_HA_None': 1,
    '#W_Magna_Face_#G_HAM_B1_22': 1,
    '#W_Magna_Face_#G_HAM_B1_31': 1,
    '#W_Magna_Face_#G_HAM_B1_32': 1,
    '#W_Magna_Face_#G_HAM_B2_14': 1,
    '#W_Magna_Face_#G_HAM_B2_20': 1,
    '#W_Magna_Face_#R_HAM_B2_25': 0,
    '#W_Magna_Face_#W_HAM_B2_25': 0,
    '#W_Magna_Face_#R_HAM_B3_05': 1,
    '#W_Magna_Face_#W_HAM_B3_05': 1,
    '#W_Magna_Face_#G_HAM_B3_06': 1,
    '#W_Magna_Face_#R_HAM_B3_08': 1,
    '#W_Magna_Face_#W_HAM_B3_08': 1,
    '#W_Magna_Face_#G_HAM_B3_09': 1,
    '#W_Magna_Face_#G_HAM_B3_11': 1,
    '#W_Magna_Face_#G_HAM_B3_22': 1,
    '#W_Magna_Face_#R_HAM_B4_01': 1,
    '#W_Magna_Face_#W_HAM_B4_01': 1,
    '#W_Magna_Face_#G_HAM_B4_02': 1,
    '#W_Magna_Face_#G_HAM_B4_05': 1,
    '#W_Magna_Face_#G_HAM_B4_06': 1,
    '#W_Magna_Face_#G_TCM_Default': 1,
    '#W_Magna_Face_#G_TCM_00': 1,
    '#W_Magna_Face_#G_TCM_07': 1,
    '#W_Magna_Face_#G_TCM_27': 1,
    '#W_Magna_Face_#G_TCM_40': 1,
    '#W_Magna_Face_#G_TCM_55': 1,
    '#W_Magna_Face_#G_TCM_59_01': 1,
    '#W_Magna_Face_#G_TCM_59_02': 1,
    '#W_Magna_Face_#G_TCM_59_03': 1,
    '#W_Magna_Face_#G_TCM_74': 1,
    '#W_Magna_Face_#G_TCM_81': 1,
    '#W_Magna_Face_#G_TCM_82': 1,
    '#W_Magna_Face_#G_TCM_85': 1,
    '#W_Magna_Face_#G_TCM_86': 1,
    '#W_Magna_Face_#G_TCM_88': 1,
    '#W_Magna_Face_#G_TCM_89': 1,
    '#W_Magna_Face_#G_TCM_91': 1,
    '#W_Magna_Face_#G_TCM_92': 1,
    '#W_Magna_Face_#G_TCM_94': 1,
    '#W_Magna_Face_#G_TCM_97': 1,
    Wesley_Hair_Rodrigo_Hair: 0,
    'Wesley_Hair_#R_Human_Body': 1,
    'Wesley_Hair_#W_Human_Body': 1,
    'Wesley_Hair_#R_Robotic Arm 03': 1,
    'Wesley_Hair_#W_Robotic Arm 03': 1,
    'Wesley_Hair_#R_Robotic Arm 05': 1,
    'Wesley_Hair_#W_Robotic Arm 05': 1,
    'Wesley_Hair_#R_Magna body': 1,
    'Wesley_Hair_#W_Magna body': 1,
    Wesley_Hair_HA_None: 1,
    'Wesley_Hair_#G_HAM_B1_22': 0,
    'Wesley_Hair_#G_HAM_B1_31': 0,
    'Wesley_Hair_#G_HAM_B1_32': 0,
    'Wesley_Hair_#G_HAM_B2_14': 1,
    'Wesley_Hair_#G_HAM_B2_20': 0,
    'Wesley_Hair_#R_HAM_B2_25': 0,
    'Wesley_Hair_#W_HAM_B2_25': 0,
    'Wesley_Hair_#R_HAM_B3_05': 0,
    'Wesley_Hair_#W_HAM_B3_05': 0,
    'Wesley_Hair_#G_HAM_B3_06': 0,
    'Wesley_Hair_#R_HAM_B3_08': 0,
    'Wesley_Hair_#W_HAM_B3_08': 0,
    'Wesley_Hair_#G_HAM_B3_09': 1,
    'Wesley_Hair_#G_HAM_B3_11': 0,
    'Wesley_Hair_#G_HAM_B3_22': 1,
    'Wesley_Hair_#R_HAM_B4_01': 0,
    'Wesley_Hair_#W_HAM_B4_01': 0,
    'Wesley_Hair_#G_HAM_B4_02': 1,
    'Wesley_Hair_#G_HAM_B4_05': 1,
    'Wesley_Hair_#G_HAM_B4_06': 0,
    'Wesley_Hair_#G_TCM_Default': 1,
    'Wesley_Hair_#G_TCM_00': 1,
    'Wesley_Hair_#G_TCM_07': 1,
    'Wesley_Hair_#G_TCM_27': 1,
    'Wesley_Hair_#G_TCM_40': 1,
    'Wesley_Hair_#G_TCM_55': 1,
    'Wesley_Hair_#G_TCM_59_01': 1,
    'Wesley_Hair_#G_TCM_59_02': 1,
    'Wesley_Hair_#G_TCM_59_03': 1,
    'Wesley_Hair_#G_TCM_74': 1,
    'Wesley_Hair_#G_TCM_81': 1,
    'Wesley_Hair_#G_TCM_82': 1,
    'Wesley_Hair_#G_TCM_85': 1,
    'Wesley_Hair_#G_TCM_86': 1,
    'Wesley_Hair_#G_TCM_88': 1,
    'Wesley_Hair_#G_TCM_89': 1,
    'Wesley_Hair_#G_TCM_91': 1,
    'Wesley_Hair_#G_TCM_92': 1,
    'Wesley_Hair_#G_TCM_94': 1,
    'Wesley_Hair_#G_TCM_97': 1,
    'Rodrigo_Hair_#R_Human_Body': 1,
    'Rodrigo_Hair_#W_Human_Body': 1,
    'Rodrigo_Hair_#R_Robotic Arm 03': 1,
    'Rodrigo_Hair_#W_Robotic Arm 03': 1,
    'Rodrigo_Hair_#R_Robotic Arm 05': 1,
    'Rodrigo_Hair_#W_Robotic Arm 05': 1,
    'Rodrigo_Hair_#R_Magna body': 1,
    'Rodrigo_Hair_#W_Magna body': 1,
    Rodrigo_Hair_HA_None: 1,
    'Rodrigo_Hair_#G_HAM_B1_22': 0,
    'Rodrigo_Hair_#G_HAM_B1_31': 1,
    'Rodrigo_Hair_#G_HAM_B1_32': 0,
    'Rodrigo_Hair_#G_HAM_B2_14': 1,
    'Rodrigo_Hair_#G_HAM_B2_20': 1,
    'Rodrigo_Hair_#R_HAM_B2_25': 0,
    'Rodrigo_Hair_#W_HAM_B2_25': 0,
    'Rodrigo_Hair_#R_HAM_B3_05': 0,
    'Rodrigo_Hair_#W_HAM_B3_05': 0,
    'Rodrigo_Hair_#G_HAM_B3_06': 1,
    'Rodrigo_Hair_#R_HAM_B3_08': 0,
    'Rodrigo_Hair_#W_HAM_B3_08': 0,
    'Rodrigo_Hair_#G_HAM_B3_09': 0,
    'Rodrigo_Hair_#G_HAM_B3_11': 0,
    'Rodrigo_Hair_#G_HAM_B3_22': 1,
    'Rodrigo_Hair_#R_HAM_B4_01': 0,
    'Rodrigo_Hair_#W_HAM_B4_01': 0,
    'Rodrigo_Hair_#G_HAM_B4_02': 0,
    'Rodrigo_Hair_#G_HAM_B4_05': 1,
    'Rodrigo_Hair_#G_HAM_B4_06': 0,
    'Rodrigo_Hair_#G_TCM_Default': 1,
    'Rodrigo_Hair_#G_TCM_00': 1,
    'Rodrigo_Hair_#G_TCM_07': 1,
    'Rodrigo_Hair_#G_TCM_27': 1,
    'Rodrigo_Hair_#G_TCM_40': 1,
    'Rodrigo_Hair_#G_TCM_55': 1,
    'Rodrigo_Hair_#G_TCM_59_01': 1,
    'Rodrigo_Hair_#G_TCM_59_02': 1,
    'Rodrigo_Hair_#G_TCM_59_03': 1,
    'Rodrigo_Hair_#G_TCM_74': 1,
    'Rodrigo_Hair_#G_TCM_81': 1,
    'Rodrigo_Hair_#G_TCM_82': 1,
    'Rodrigo_Hair_#G_TCM_85': 1,
    'Rodrigo_Hair_#G_TCM_86': 1,
    'Rodrigo_Hair_#G_TCM_88': 1,
    'Rodrigo_Hair_#G_TCM_89': 1,
    'Rodrigo_Hair_#G_TCM_91': 1,
    'Rodrigo_Hair_#G_TCM_92': 1,
    'Rodrigo_Hair_#G_TCM_94': 1,
    'Rodrigo_Hair_#G_TCM_97': 1,
    '#R_Human_Body_#W_Human_Body': 0,
    '#R_Human_Body_#R_Robotic Arm 03': 0,
    '#R_Human_Body_#W_Robotic Arm 03': 0,
    '#R_Human_Body_#R_Robotic Arm 05': 0,
    '#R_Human_Body_#W_Robotic Arm 05': 0,
    '#R_Human_Body_#R_Magna body': 0,
    '#R_Human_Body_#W_Magna body': 0,
    '#R_Human_Body_HA_None': 1,
    '#R_Human_Body_#G_HAM_B1_22': 1,
    '#R_Human_Body_#G_HAM_B1_31': 1,
    '#R_Human_Body_#G_HAM_B1_32': 1,
    '#R_Human_Body_#G_HAM_B2_14': 1,
    '#R_Human_Body_#G_HAM_B2_20': 1,
    '#R_Human_Body_#R_HAM_B2_25': 1,
    '#R_Human_Body_#W_HAM_B2_25': 1,
    '#R_Human_Body_#R_HAM_B3_05': 1,
    '#R_Human_Body_#W_HAM_B3_05': 1,
    '#R_Human_Body_#G_HAM_B3_06': 1,
    '#R_Human_Body_#R_HAM_B3_08': 1,
    '#R_Human_Body_#W_HAM_B3_08': 1,
    '#R_Human_Body_#G_HAM_B3_09': 1,
    '#R_Human_Body_#G_HAM_B3_11': 1,
    '#R_Human_Body_#G_HAM_B3_22': 1,
    '#R_Human_Body_#R_HAM_B4_01': 1,
    '#R_Human_Body_#W_HAM_B4_01': 1,
    '#R_Human_Body_#G_HAM_B4_02': 1,
    '#R_Human_Body_#G_HAM_B4_05': 1,
    '#R_Human_Body_#G_HAM_B4_06': 1,
    '#R_Human_Body_#G_TCM_Default': 1,
    '#R_Human_Body_#G_TCM_00': 1,
    '#R_Human_Body_#G_TCM_07': 1,
    '#R_Human_Body_#G_TCM_27': 1,
    '#R_Human_Body_#G_TCM_40': 1,
    '#R_Human_Body_#G_TCM_55': 1,
    '#R_Human_Body_#G_TCM_59_01': 1,
    '#R_Human_Body_#G_TCM_59_02': 1,
    '#R_Human_Body_#G_TCM_59_03': 1,
    '#R_Human_Body_#G_TCM_74': 1,
    '#R_Human_Body_#G_TCM_81': 1,
    '#R_Human_Body_#G_TCM_82': 1,
    '#R_Human_Body_#G_TCM_85': 1,
    '#R_Human_Body_#G_TCM_86': 1,
    '#R_Human_Body_#G_TCM_88': 1,
    '#R_Human_Body_#G_TCM_89': 1,
    '#R_Human_Body_#G_TCM_91': 1,
    '#R_Human_Body_#G_TCM_92': 1,
    '#R_Human_Body_#G_TCM_94': 1,
    '#R_Human_Body_#G_TCM_97': 1,
    '#W_Human_Body_#R_Robotic Arm 03': 0,
    '#W_Human_Body_#W_Robotic Arm 03': 0,
    '#W_Human_Body_#R_Robotic Arm 05': 0,
    '#W_Human_Body_#W_Robotic Arm 05': 0,
    '#W_Human_Body_#R_Magna body': 0,
    '#W_Human_Body_#W_Magna body': 0,
    '#W_Human_Body_HA_None': 1,
    '#W_Human_Body_#G_HAM_B1_22': 1,
    '#W_Human_Body_#G_HAM_B1_31': 1,
    '#W_Human_Body_#G_HAM_B1_32': 1,
    '#W_Human_Body_#G_HAM_B2_14': 1,
    '#W_Human_Body_#G_HAM_B2_20': 1,
    '#W_Human_Body_#R_HAM_B2_25': 1,
    '#W_Human_Body_#W_HAM_B2_25': 1,
    '#W_Human_Body_#R_HAM_B3_05': 1,
    '#W_Human_Body_#W_HAM_B3_05': 1,
    '#W_Human_Body_#G_HAM_B3_06': 1,
    '#W_Human_Body_#R_HAM_B3_08': 1,
    '#W_Human_Body_#W_HAM_B3_08': 1,
    '#W_Human_Body_#G_HAM_B3_09': 1,
    '#W_Human_Body_#G_HAM_B3_11': 1,
    '#W_Human_Body_#G_HAM_B3_22': 1,
    '#W_Human_Body_#R_HAM_B4_01': 1,
    '#W_Human_Body_#W_HAM_B4_01': 1,
    '#W_Human_Body_#G_HAM_B4_02': 1,
    '#W_Human_Body_#G_HAM_B4_05': 1,
    '#W_Human_Body_#G_HAM_B4_06': 1,
    '#W_Human_Body_#G_TCM_Default': 1,
    '#W_Human_Body_#G_TCM_00': 1,
    '#W_Human_Body_#G_TCM_07': 1,
    '#W_Human_Body_#G_TCM_27': 1,
    '#W_Human_Body_#G_TCM_40': 1,
    '#W_Human_Body_#G_TCM_55': 1,
    '#W_Human_Body_#G_TCM_59_01': 1,
    '#W_Human_Body_#G_TCM_59_02': 1,
    '#W_Human_Body_#G_TCM_59_03': 1,
    '#W_Human_Body_#G_TCM_74': 1,
    '#W_Human_Body_#G_TCM_81': 1,
    '#W_Human_Body_#G_TCM_82': 1,
    '#W_Human_Body_#G_TCM_85': 1,
    '#W_Human_Body_#G_TCM_86': 1,
    '#W_Human_Body_#G_TCM_88': 1,
    '#W_Human_Body_#G_TCM_89': 1,
    '#W_Human_Body_#G_TCM_91': 1,
    '#W_Human_Body_#G_TCM_92': 1,
    '#W_Human_Body_#G_TCM_94': 1,
    '#W_Human_Body_#G_TCM_97': 1,
    '#R_Robotic Arm 03_#W_Robotic Arm 03': 0,
    '#R_Robotic Arm 03_#R_Robotic Arm 05': 0,
    '#R_Robotic Arm 03_#W_Robotic Arm 05': 0,
    '#R_Robotic Arm 03_#R_Magna body': 0,
    '#R_Robotic Arm 03_#W_Magna body': 0,
    '#R_Robotic Arm 03_HA_None': 1,
    '#R_Robotic Arm 03_#G_HAM_B1_22': 1,
    '#R_Robotic Arm 03_#G_HAM_B1_31': 1,
    '#R_Robotic Arm 03_#G_HAM_B1_32': 1,
    '#R_Robotic Arm 03_#G_HAM_B2_14': 1,
    '#R_Robotic Arm 03_#G_HAM_B2_20': 1,
    '#R_Robotic Arm 03_#R_HAM_B2_25': 1,
    '#R_Robotic Arm 03_#W_HAM_B2_25': 1,
    '#R_Robotic Arm 03_#R_HAM_B3_05': 1,
    '#R_Robotic Arm 03_#W_HAM_B3_05': 1,
    '#R_Robotic Arm 03_#G_HAM_B3_06': 1,
    '#R_Robotic Arm 03_#R_HAM_B3_08': 1,
    '#R_Robotic Arm 03_#W_HAM_B3_08': 1,
    '#R_Robotic Arm 03_#G_HAM_B3_09': 1,
    '#R_Robotic Arm 03_#G_HAM_B3_11': 1,
    '#R_Robotic Arm 03_#G_HAM_B3_22': 1,
    '#R_Robotic Arm 03_#R_HAM_B4_01': 1,
    '#R_Robotic Arm 03_#W_HAM_B4_01': 1,
    '#R_Robotic Arm 03_#G_HAM_B4_02': 1,
    '#R_Robotic Arm 03_#G_HAM_B4_05': 1,
    '#R_Robotic Arm 03_#G_HAM_B4_06': 1,
    '#R_Robotic Arm 03_#G_TCM_Default': 1,
    '#R_Robotic Arm 03_#G_TCM_00': 1,
    '#R_Robotic Arm 03_#G_TCM_07': 1,
    '#R_Robotic Arm 03_#G_TCM_27': 0,
    '#R_Robotic Arm 03_#G_TCM_40': 0,
    '#R_Robotic Arm 03_#G_TCM_55': 1,
    '#R_Robotic Arm 03_#G_TCM_59_01': 1,
    '#R_Robotic Arm 03_#G_TCM_59_02': 1,
    '#R_Robotic Arm 03_#G_TCM_59_03': 0,
    '#R_Robotic Arm 03_#G_TCM_74': 0,
    '#R_Robotic Arm 03_#G_TCM_81': 0,
    '#R_Robotic Arm 03_#G_TCM_82': 0,
    '#R_Robotic Arm 03_#G_TCM_85': 1,
    '#R_Robotic Arm 03_#G_TCM_86': 0,
    '#R_Robotic Arm 03_#G_TCM_88': 0,
    '#R_Robotic Arm 03_#G_TCM_89': 1,
    '#R_Robotic Arm 03_#G_TCM_91': 0,
    '#R_Robotic Arm 03_#G_TCM_92': 0,
    '#R_Robotic Arm 03_#G_TCM_94': 1,
    '#R_Robotic Arm 03_#G_TCM_97': 0,
    '#W_Robotic Arm 03_#R_Robotic Arm 05': 0,
    '#W_Robotic Arm 03_#W_Robotic Arm 05': 0,
    '#W_Robotic Arm 03_#R_Magna body': 0,
    '#W_Robotic Arm 03_#W_Magna body': 0,
    '#W_Robotic Arm 03_HA_None': 1,
    '#W_Robotic Arm 03_#G_HAM_B1_22': 1,
    '#W_Robotic Arm 03_#G_HAM_B1_31': 1,
    '#W_Robotic Arm 03_#G_HAM_B1_32': 1,
    '#W_Robotic Arm 03_#G_HAM_B2_14': 1,
    '#W_Robotic Arm 03_#G_HAM_B2_20': 1,
    '#W_Robotic Arm 03_#R_HAM_B2_25': 1,
    '#W_Robotic Arm 03_#W_HAM_B2_25': 1,
    '#W_Robotic Arm 03_#R_HAM_B3_05': 1,
    '#W_Robotic Arm 03_#W_HAM_B3_05': 1,
    '#W_Robotic Arm 03_#G_HAM_B3_06': 1,
    '#W_Robotic Arm 03_#R_HAM_B3_08': 1,
    '#W_Robotic Arm 03_#W_HAM_B3_08': 1,
    '#W_Robotic Arm 03_#G_HAM_B3_09': 1,
    '#W_Robotic Arm 03_#G_HAM_B3_11': 1,
    '#W_Robotic Arm 03_#G_HAM_B3_22': 1,
    '#W_Robotic Arm 03_#R_HAM_B4_01': 1,
    '#W_Robotic Arm 03_#W_HAM_B4_01': 1,
    '#W_Robotic Arm 03_#G_HAM_B4_02': 1,
    '#W_Robotic Arm 03_#G_HAM_B4_05': 1,
    '#W_Robotic Arm 03_#G_HAM_B4_06': 1,
    '#W_Robotic Arm 03_#G_TCM_Default': 1,
    '#W_Robotic Arm 03_#G_TCM_00': 1,
    '#W_Robotic Arm 03_#G_TCM_07': 1,
    '#W_Robotic Arm 03_#G_TCM_27': 0,
    '#W_Robotic Arm 03_#G_TCM_40': 0,
    '#W_Robotic Arm 03_#G_TCM_55': 1,
    '#W_Robotic Arm 03_#G_TCM_59_01': 1,
    '#W_Robotic Arm 03_#G_TCM_59_02': 1,
    '#W_Robotic Arm 03_#G_TCM_59_03': 0,
    '#W_Robotic Arm 03_#G_TCM_74': 0,
    '#W_Robotic Arm 03_#G_TCM_81': 0,
    '#W_Robotic Arm 03_#G_TCM_82': 0,
    '#W_Robotic Arm 03_#G_TCM_85': 1,
    '#W_Robotic Arm 03_#G_TCM_86': 0,
    '#W_Robotic Arm 03_#G_TCM_88': 0,
    '#W_Robotic Arm 03_#G_TCM_89': 1,
    '#W_Robotic Arm 03_#G_TCM_91': 0,
    '#W_Robotic Arm 03_#G_TCM_92': 0,
    '#W_Robotic Arm 03_#G_TCM_94': 1,
    '#W_Robotic Arm 03_#G_TCM_97': 0,
    '#R_Robotic Arm 05_#W_Robotic Arm 05': 0,
    '#R_Robotic Arm 05_#R_Magna body': 0,
    '#R_Robotic Arm 05_#W_Magna body': 0,
    '#R_Robotic Arm 05_HA_None': 1,
    '#R_Robotic Arm 05_#G_HAM_B1_22': 1,
    '#R_Robotic Arm 05_#G_HAM_B1_31': 1,
    '#R_Robotic Arm 05_#G_HAM_B1_32': 1,
    '#R_Robotic Arm 05_#G_HAM_B2_14': 1,
    '#R_Robotic Arm 05_#G_HAM_B2_20': 1,
    '#R_Robotic Arm 05_#R_HAM_B2_25': 1,
    '#R_Robotic Arm 05_#W_HAM_B2_25': 1,
    '#R_Robotic Arm 05_#R_HAM_B3_05': 1,
    '#R_Robotic Arm 05_#W_HAM_B3_05': 1,
    '#R_Robotic Arm 05_#G_HAM_B3_06': 1,
    '#R_Robotic Arm 05_#R_HAM_B3_08': 1,
    '#R_Robotic Arm 05_#W_HAM_B3_08': 1,
    '#R_Robotic Arm 05_#G_HAM_B3_09': 1,
    '#R_Robotic Arm 05_#G_HAM_B3_11': 1,
    '#R_Robotic Arm 05_#G_HAM_B3_22': 1,
    '#R_Robotic Arm 05_#R_HAM_B4_01': 1,
    '#R_Robotic Arm 05_#W_HAM_B4_01': 1,
    '#R_Robotic Arm 05_#G_HAM_B4_02': 1,
    '#R_Robotic Arm 05_#G_HAM_B4_05': 1,
    '#R_Robotic Arm 05_#G_HAM_B4_06': 1,
    '#R_Robotic Arm 05_#G_TCM_Default': 1,
    '#R_Robotic Arm 05_#G_TCM_00': 0,
    '#R_Robotic Arm 05_#G_TCM_07': 1,
    '#R_Robotic Arm 05_#G_TCM_27': 0,
    '#R_Robotic Arm 05_#G_TCM_40': 0,
    '#R_Robotic Arm 05_#G_TCM_55': 1,
    '#R_Robotic Arm 05_#G_TCM_59_01': 1,
    '#R_Robotic Arm 05_#G_TCM_59_02': 0,
    '#R_Robotic Arm 05_#G_TCM_59_03': 0,
    '#R_Robotic Arm 05_#G_TCM_74': 0,
    '#R_Robotic Arm 05_#G_TCM_81': 0,
    '#R_Robotic Arm 05_#G_TCM_82': 0,
    '#R_Robotic Arm 05_#G_TCM_85': 0,
    '#R_Robotic Arm 05_#G_TCM_86': 0,
    '#R_Robotic Arm 05_#G_TCM_88': 0,
    '#R_Robotic Arm 05_#G_TCM_89': 1,
    '#R_Robotic Arm 05_#G_TCM_91': 0,
    '#R_Robotic Arm 05_#G_TCM_92': 0,
    '#R_Robotic Arm 05_#G_TCM_94': 0,
    '#R_Robotic Arm 05_#G_TCM_97': 0,
    '#W_Robotic Arm 05_#R_Magna body': 0,
    '#W_Robotic Arm 05_#W_Magna body': 0,
    '#W_Robotic Arm 05_HA_None': 1,
    '#W_Robotic Arm 05_#G_HAM_B1_22': 1,
    '#W_Robotic Arm 05_#G_HAM_B1_31': 1,
    '#W_Robotic Arm 05_#G_HAM_B1_32': 1,
    '#W_Robotic Arm 05_#G_HAM_B2_14': 1,
    '#W_Robotic Arm 05_#G_HAM_B2_20': 1,
    '#W_Robotic Arm 05_#R_HAM_B2_25': 1,
    '#W_Robotic Arm 05_#W_HAM_B2_25': 1,
    '#W_Robotic Arm 05_#R_HAM_B3_05': 1,
    '#W_Robotic Arm 05_#W_HAM_B3_05': 1,
    '#W_Robotic Arm 05_#G_HAM_B3_06': 1,
    '#W_Robotic Arm 05_#R_HAM_B3_08': 1,
    '#W_Robotic Arm 05_#W_HAM_B3_08': 1,
    '#W_Robotic Arm 05_#G_HAM_B3_09': 1,
    '#W_Robotic Arm 05_#G_HAM_B3_11': 1,
    '#W_Robotic Arm 05_#G_HAM_B3_22': 1,
    '#W_Robotic Arm 05_#R_HAM_B4_01': 1,
    '#W_Robotic Arm 05_#W_HAM_B4_01': 1,
    '#W_Robotic Arm 05_#G_HAM_B4_02': 1,
    '#W_Robotic Arm 05_#G_HAM_B4_05': 1,
    '#W_Robotic Arm 05_#G_HAM_B4_06': 1,
    '#W_Robotic Arm 05_#G_TCM_Default': 1,
    '#W_Robotic Arm 05_#G_TCM_00': 0,
    '#W_Robotic Arm 05_#G_TCM_07': 1,
    '#W_Robotic Arm 05_#G_TCM_27': 0,
    '#W_Robotic Arm 05_#G_TCM_40': 0,
    '#W_Robotic Arm 05_#G_TCM_55': 1,
    '#W_Robotic Arm 05_#G_TCM_59_01': 1,
    '#W_Robotic Arm 05_#G_TCM_59_02': 0,
    '#W_Robotic Arm 05_#G_TCM_59_03': 0,
    '#W_Robotic Arm 05_#G_TCM_74': 0,
    '#W_Robotic Arm 05_#G_TCM_81': 0,
    '#W_Robotic Arm 05_#G_TCM_82': 0,
    '#W_Robotic Arm 05_#G_TCM_85': 0,
    '#W_Robotic Arm 05_#G_TCM_86': 0,
    '#W_Robotic Arm 05_#G_TCM_88': 0,
    '#W_Robotic Arm 05_#G_TCM_89': 1,
    '#W_Robotic Arm 05_#G_TCM_91': 0,
    '#W_Robotic Arm 05_#G_TCM_92': 0,
    '#W_Robotic Arm 05_#G_TCM_94': 0,
    '#W_Robotic Arm 05_#G_TCM_97': 0,
    '#R_Magna body_#W_Magna body': 0,
    '#R_Magna body_HA_None': 1,
    '#R_Magna body_#G_HAM_B1_22': 1,
    '#R_Magna body_#G_HAM_B1_31': 1,
    '#R_Magna body_#G_HAM_B1_32': 1,
    '#R_Magna body_#G_HAM_B2_14': 1,
    '#R_Magna body_#G_HAM_B2_20': 1,
    '#R_Magna body_#R_HAM_B2_25': 1,
    '#R_Magna body_#W_HAM_B2_25': 1,
    '#R_Magna body_#R_HAM_B3_05': 1,
    '#R_Magna body_#W_HAM_B3_05': 1,
    '#R_Magna body_#G_HAM_B3_06': 1,
    '#R_Magna body_#R_HAM_B3_08': 1,
    '#R_Magna body_#W_HAM_B3_08': 1,
    '#R_Magna body_#G_HAM_B3_09': 1,
    '#R_Magna body_#G_HAM_B3_11': 1,
    '#R_Magna body_#G_HAM_B3_22': 1,
    '#R_Magna body_#R_HAM_B4_01': 1,
    '#R_Magna body_#W_HAM_B4_01': 1,
    '#R_Magna body_#G_HAM_B4_02': 1,
    '#R_Magna body_#G_HAM_B4_05': 1,
    '#R_Magna body_#G_HAM_B4_06': 1,
    '#R_Magna body_#G_TCM_Default': 1,
    '#R_Magna body_#G_TCM_00': 1,
    '#R_Magna body_#G_TCM_07': 1,
    '#R_Magna body_#G_TCM_27': 1,
    '#R_Magna body_#G_TCM_40': 1,
    '#R_Magna body_#G_TCM_55': 1,
    '#R_Magna body_#G_TCM_59_01': 1,
    '#R_Magna body_#G_TCM_59_02': 1,
    '#R_Magna body_#G_TCM_59_03': 1,
    '#R_Magna body_#G_TCM_74': 1,
    '#R_Magna body_#G_TCM_81': 1,
    '#R_Magna body_#G_TCM_82': 1,
    '#R_Magna body_#G_TCM_85': 1,
    '#R_Magna body_#G_TCM_86': 1,
    '#R_Magna body_#G_TCM_88': 1,
    '#R_Magna body_#G_TCM_89': 1,
    '#R_Magna body_#G_TCM_91': 1,
    '#R_Magna body_#G_TCM_92': 1,
    '#R_Magna body_#G_TCM_94': 1,
    '#R_Magna body_#G_TCM_97': 1,
    '#W_Magna body_HA_None': 1,
    '#W_Magna body_#G_HAM_B1_22': 1,
    '#W_Magna body_#G_HAM_B1_31': 1,
    '#W_Magna body_#G_HAM_B1_32': 1,
    '#W_Magna body_#G_HAM_B2_14': 1,
    '#W_Magna body_#G_HAM_B2_20': 1,
    '#W_Magna body_#R_HAM_B2_25': 1,
    '#W_Magna body_#W_HAM_B2_25': 1,
    '#W_Magna body_#R_HAM_B3_05': 1,
    '#W_Magna body_#W_HAM_B3_05': 1,
    '#W_Magna body_#G_HAM_B3_06': 1,
    '#W_Magna body_#R_HAM_B3_08': 1,
    '#W_Magna body_#W_HAM_B3_08': 1,
    '#W_Magna body_#G_HAM_B3_09': 1,
    '#W_Magna body_#G_HAM_B3_11': 1,
    '#W_Magna body_#G_HAM_B3_22': 1,
    '#W_Magna body_#R_HAM_B4_01': 1,
    '#W_Magna body_#W_HAM_B4_01': 1,
    '#W_Magna body_#G_HAM_B4_02': 1,
    '#W_Magna body_#G_HAM_B4_05': 1,
    '#W_Magna body_#G_HAM_B4_06': 1,
    '#W_Magna body_#G_TCM_Default': 1,
    '#W_Magna body_#G_TCM_00': 1,
    '#W_Magna body_#G_TCM_07': 1,
    '#W_Magna body_#G_TCM_27': 1,
    '#W_Magna body_#G_TCM_40': 1,
    '#W_Magna body_#G_TCM_55': 1,
    '#W_Magna body_#G_TCM_59_01': 1,
    '#W_Magna body_#G_TCM_59_02': 1,
    '#W_Magna body_#G_TCM_59_03': 1,
    '#W_Magna body_#G_TCM_74': 1,
    '#W_Magna body_#G_TCM_81': 1,
    '#W_Magna body_#G_TCM_82': 1,
    '#W_Magna body_#G_TCM_85': 1,
    '#W_Magna body_#G_TCM_86': 1,
    '#W_Magna body_#G_TCM_88': 1,
    '#W_Magna body_#G_TCM_89': 1,
    '#W_Magna body_#G_TCM_91': 1,
    '#W_Magna body_#G_TCM_92': 1,
    '#W_Magna body_#G_TCM_94': 1,
    '#W_Magna body_#G_TCM_97': 1,
    'HA_None_#G_HAM_B1_22': 0,
    'HA_None_#G_HAM_B1_31': 0,
    'HA_None_#G_HAM_B1_32': 0,
    'HA_None_#G_HAM_B2_14': 0,
    'HA_None_#G_HAM_B2_20': 0,
    'HA_None_#R_HAM_B2_25': 0,
    'HA_None_#W_HAM_B2_25': 0,
    'HA_None_#R_HAM_B3_05': 0,
    'HA_None_#W_HAM_B3_05': 0,
    'HA_None_#G_HAM_B3_06': 0,
    'HA_None_#R_HAM_B3_08': 0,
    'HA_None_#W_HAM_B3_08': 0,
    'HA_None_#G_HAM_B3_09': 0,
    'HA_None_#G_HAM_B3_11': 0,
    'HA_None_#G_HAM_B3_22': 0,
    'HA_None_#R_HAM_B4_01': 0,
    'HA_None_#W_HAM_B4_01': 0,
    'HA_None_#G_HAM_B4_02': 0,
    'HA_None_#G_HAM_B4_05': 0,
    'HA_None_#G_HAM_B4_06': 0,
    'HA_None_#G_TCM_Default': 1,
    'HA_None_#G_TCM_00': 1,
    'HA_None_#G_TCM_07': 1,
    'HA_None_#G_TCM_27': 1,
    'HA_None_#G_TCM_40': 1,
    'HA_None_#G_TCM_55': 1,
    'HA_None_#G_TCM_59_01': 1,
    'HA_None_#G_TCM_59_02': 1,
    'HA_None_#G_TCM_59_03': 1,
    'HA_None_#G_TCM_74': 1,
    'HA_None_#G_TCM_81': 1,
    'HA_None_#G_TCM_82': 1,
    'HA_None_#G_TCM_85': 1,
    'HA_None_#G_TCM_86': 1,
    'HA_None_#G_TCM_88': 1,
    'HA_None_#G_TCM_89': 1,
    'HA_None_#G_TCM_91': 1,
    'HA_None_#G_TCM_92': 1,
    'HA_None_#G_TCM_94': 1,
    'HA_None_#G_TCM_97': 1,
    '#G_HAM_B1_22_#G_HAM_B1_31': 0,
    '#G_HAM_B1_22_#G_HAM_B1_32': 0,
    '#G_HAM_B1_22_#G_HAM_B2_14': 0,
    '#G_HAM_B1_22_#G_HAM_B2_20': 0,
    '#G_HAM_B1_22_#R_HAM_B2_25': 0,
    '#G_HAM_B1_22_#W_HAM_B2_25': 0,
    '#G_HAM_B1_22_#R_HAM_B3_05': 0,
    '#G_HAM_B1_22_#W_HAM_B3_05': 0,
    '#G_HAM_B1_22_#G_HAM_B3_06': 0,
    '#G_HAM_B1_22_#R_HAM_B3_08': 0,
    '#G_HAM_B1_22_#W_HAM_B3_08': 0,
    '#G_HAM_B1_22_#G_HAM_B3_09': 0,
    '#G_HAM_B1_22_#G_HAM_B3_11': 0,
    '#G_HAM_B1_22_#G_HAM_B3_22': 0,
    '#G_HAM_B1_22_#R_HAM_B4_01': 0,
    '#G_HAM_B1_22_#W_HAM_B4_01': 0,
    '#G_HAM_B1_22_#G_HAM_B4_02': 0,
    '#G_HAM_B1_22_#G_HAM_B4_05': 0,
    '#G_HAM_B1_22_#G_HAM_B4_06': 0,
    '#G_HAM_B1_22_#G_TCM_Default': 1,
    '#G_HAM_B1_22_#G_TCM_00': 1,
    '#G_HAM_B1_22_#G_TCM_07': 1,
    '#G_HAM_B1_22_#G_TCM_27': 1,
    '#G_HAM_B1_22_#G_TCM_40': 1,
    '#G_HAM_B1_22_#G_TCM_55': 1,
    '#G_HAM_B1_22_#G_TCM_59_01': 1,
    '#G_HAM_B1_22_#G_TCM_59_02': 1,
    '#G_HAM_B1_22_#G_TCM_59_03': 1,
    '#G_HAM_B1_22_#G_TCM_74': 1,
    '#G_HAM_B1_22_#G_TCM_81': 0,
    '#G_HAM_B1_22_#G_TCM_82': 1,
    '#G_HAM_B1_22_#G_TCM_85': 1,
    '#G_HAM_B1_22_#G_TCM_86': 1,
    '#G_HAM_B1_22_#G_TCM_88': 1,
    '#G_HAM_B1_22_#G_TCM_89': 1,
    '#G_HAM_B1_22_#G_TCM_91': 1,
    '#G_HAM_B1_22_#G_TCM_92': 1,
    '#G_HAM_B1_22_#G_TCM_94': 1,
    '#G_HAM_B1_22_#G_TCM_97': 1,
    '#G_HAM_B1_31_#G_HAM_B1_32': 0,
    '#G_HAM_B1_31_#G_HAM_B2_14': 0,
    '#G_HAM_B1_31_#G_HAM_B2_20': 0,
    '#G_HAM_B1_31_#R_HAM_B2_25': 0,
    '#G_HAM_B1_31_#W_HAM_B2_25': 0,
    '#G_HAM_B1_31_#R_HAM_B3_05': 0,
    '#G_HAM_B1_31_#W_HAM_B3_05': 0,
    '#G_HAM_B1_31_#G_HAM_B3_06': 0,
    '#G_HAM_B1_31_#R_HAM_B3_08': 0,
    '#G_HAM_B1_31_#W_HAM_B3_08': 0,
    '#G_HAM_B1_31_#G_HAM_B3_09': 0,
    '#G_HAM_B1_31_#G_HAM_B3_11': 0,
    '#G_HAM_B1_31_#G_HAM_B3_22': 0,
    '#G_HAM_B1_31_#R_HAM_B4_01': 0,
    '#G_HAM_B1_31_#W_HAM_B4_01': 0,
    '#G_HAM_B1_31_#G_HAM_B4_02': 0,
    '#G_HAM_B1_31_#G_HAM_B4_05': 0,
    '#G_HAM_B1_31_#G_HAM_B4_06': 0,
    '#G_HAM_B1_31_#G_TCM_Default': 1,
    '#G_HAM_B1_31_#G_TCM_00': 1,
    '#G_HAM_B1_31_#G_TCM_07': 1,
    '#G_HAM_B1_31_#G_TCM_27': 1,
    '#G_HAM_B1_31_#G_TCM_40': 1,
    '#G_HAM_B1_31_#G_TCM_55': 1,
    '#G_HAM_B1_31_#G_TCM_59_01': 1,
    '#G_HAM_B1_31_#G_TCM_59_02': 1,
    '#G_HAM_B1_31_#G_TCM_59_03': 1,
    '#G_HAM_B1_31_#G_TCM_74': 1,
    '#G_HAM_B1_31_#G_TCM_81': 1,
    '#G_HAM_B1_31_#G_TCM_82': 1,
    '#G_HAM_B1_31_#G_TCM_85': 1,
    '#G_HAM_B1_31_#G_TCM_86': 1,
    '#G_HAM_B1_31_#G_TCM_88': 1,
    '#G_HAM_B1_31_#G_TCM_89': 1,
    '#G_HAM_B1_31_#G_TCM_91': 1,
    '#G_HAM_B1_31_#G_TCM_92': 1,
    '#G_HAM_B1_31_#G_TCM_94': 1,
    '#G_HAM_B1_31_#G_TCM_97': 1,
    '#G_HAM_B1_32_#G_HAM_B2_14': 0,
    '#G_HAM_B1_32_#G_HAM_B2_20': 0,
    '#G_HAM_B1_32_#R_HAM_B2_25': 0,
    '#G_HAM_B1_32_#W_HAM_B2_25': 0,
    '#G_HAM_B1_32_#R_HAM_B3_05': 0,
    '#G_HAM_B1_32_#W_HAM_B3_05': 0,
    '#G_HAM_B1_32_#G_HAM_B3_06': 0,
    '#G_HAM_B1_32_#R_HAM_B3_08': 0,
    '#G_HAM_B1_32_#W_HAM_B3_08': 0,
    '#G_HAM_B1_32_#G_HAM_B3_09': 0,
    '#G_HAM_B1_32_#G_HAM_B3_11': 0,
    '#G_HAM_B1_32_#G_HAM_B3_22': 0,
    '#G_HAM_B1_32_#R_HAM_B4_01': 0,
    '#G_HAM_B1_32_#W_HAM_B4_01': 0,
    '#G_HAM_B1_32_#G_HAM_B4_02': 0,
    '#G_HAM_B1_32_#G_HAM_B4_05': 0,
    '#G_HAM_B1_32_#G_HAM_B4_06': 0,
    '#G_HAM_B1_32_#G_TCM_Default': 1,
    '#G_HAM_B1_32_#G_TCM_00': 1,
    '#G_HAM_B1_32_#G_TCM_07': 1,
    '#G_HAM_B1_32_#G_TCM_27': 1,
    '#G_HAM_B1_32_#G_TCM_40': 0,
    '#G_HAM_B1_32_#G_TCM_55': 1,
    '#G_HAM_B1_32_#G_TCM_59_01': 1,
    '#G_HAM_B1_32_#G_TCM_59_02': 1,
    '#G_HAM_B1_32_#G_TCM_59_03': 1,
    '#G_HAM_B1_32_#G_TCM_74': 1,
    '#G_HAM_B1_32_#G_TCM_81': 0,
    '#G_HAM_B1_32_#G_TCM_82': 1,
    '#G_HAM_B1_32_#G_TCM_85': 0,
    '#G_HAM_B1_32_#G_TCM_86': 0,
    '#G_HAM_B1_32_#G_TCM_88': 1,
    '#G_HAM_B1_32_#G_TCM_89': 1,
    '#G_HAM_B1_32_#G_TCM_91': 1,
    '#G_HAM_B1_32_#G_TCM_92': 1,
    '#G_HAM_B1_32_#G_TCM_94': 1,
    '#G_HAM_B1_32_#G_TCM_97': 1,
    '#G_HAM_B2_14_#G_HAM_B2_20': 0,
    '#G_HAM_B2_14_#R_HAM_B2_25': 0,
    '#G_HAM_B2_14_#W_HAM_B2_25': 0,
    '#G_HAM_B2_14_#R_HAM_B3_05': 0,
    '#G_HAM_B2_14_#W_HAM_B3_05': 0,
    '#G_HAM_B2_14_#G_HAM_B3_06': 0,
    '#G_HAM_B2_14_#R_HAM_B3_08': 0,
    '#G_HAM_B2_14_#W_HAM_B3_08': 0,
    '#G_HAM_B2_14_#G_HAM_B3_09': 0,
    '#G_HAM_B2_14_#G_HAM_B3_11': 0,
    '#G_HAM_B2_14_#G_HAM_B3_22': 0,
    '#G_HAM_B2_14_#R_HAM_B4_01': 0,
    '#G_HAM_B2_14_#W_HAM_B4_01': 0,
    '#G_HAM_B2_14_#G_HAM_B4_02': 0,
    '#G_HAM_B2_14_#G_HAM_B4_05': 0,
    '#G_HAM_B2_14_#G_HAM_B4_06': 0,
    '#G_HAM_B2_14_#G_TCM_Default': 1,
    '#G_HAM_B2_14_#G_TCM_00': 1,
    '#G_HAM_B2_14_#G_TCM_07': 1,
    '#G_HAM_B2_14_#G_TCM_27': 1,
    '#G_HAM_B2_14_#G_TCM_40': 1,
    '#G_HAM_B2_14_#G_TCM_55': 1,
    '#G_HAM_B2_14_#G_TCM_59_01': 1,
    '#G_HAM_B2_14_#G_TCM_59_02': 1,
    '#G_HAM_B2_14_#G_TCM_59_03': 1,
    '#G_HAM_B2_14_#G_TCM_74': 1,
    '#G_HAM_B2_14_#G_TCM_81': 1,
    '#G_HAM_B2_14_#G_TCM_82': 1,
    '#G_HAM_B2_14_#G_TCM_85': 1,
    '#G_HAM_B2_14_#G_TCM_86': 1,
    '#G_HAM_B2_14_#G_TCM_88': 1,
    '#G_HAM_B2_14_#G_TCM_89': 1,
    '#G_HAM_B2_14_#G_TCM_91': 1,
    '#G_HAM_B2_14_#G_TCM_92': 1,
    '#G_HAM_B2_14_#G_TCM_94': 1,
    '#G_HAM_B2_14_#G_TCM_97': 1,
    '#G_HAM_B2_20_Wesley': 1,
    '#G_HAM_B2_20_Rodrigo': 1,
    '#G_HAM_B2_20_#R_Human _Face': 1,
    '#G_HAM_B2_20_#W_Human _Face': 1,
    '#G_HAM_B2_20_#R_CyborgTrader': 1,
    '#G_HAM_B2_20_#W_CyborgTrader': 1,
    '#G_HAM_B2_20_#R_Magna_Face': 1,
    '#G_HAM_B2_20_#W_Magna_Face': 1,
    '#G_HAM_B2_20_Wesley_Hair': 0,
    '#G_HAM_B2_20_Rodrigo_Hair': 1,
    '#G_HAM_B2_20_#R_Human_Body': 1,
    '#G_HAM_B2_20_#W_Human_Body': 1,
    '#G_HAM_B2_20_#R_Robotic Arm 03': 1,
    '#G_HAM_B2_20_#W_Robotic Arm 03': 1,
    '#G_HAM_B2_20_#R_Robotic Arm 05': 1,
    '#G_HAM_B2_20_#W_Robotic Arm 05': 1,
    '#G_HAM_B2_20_#R_Magna body': 1,
    '#G_HAM_B2_20_#W_Magna body': 1,
    '#G_HAM_B2_20_HA_None': 0,
    '#G_HAM_B2_20_#G_HAM_B1_22': 0,
    '#G_HAM_B2_20_#G_HAM_B1_31': 0,
    '#G_HAM_B2_20_#G_HAM_B1_32': 0,
    '#G_HAM_B2_20_#G_HAM_B2_14': 0,
    '#G_HAM_B2_20_#R_HAM_B2_25': 0,
    '#G_HAM_B2_20_#W_HAM_B2_25': 0,
    '#G_HAM_B2_20_#R_HAM_B3_05': 0,
    '#G_HAM_B2_20_#W_HAM_B3_05': 0,
    '#G_HAM_B2_20_#G_HAM_B3_06': 0,
    '#G_HAM_B2_20_#R_HAM_B3_08': 0,
    '#G_HAM_B2_20_#W_HAM_B3_08': 0,
    '#G_HAM_B2_20_#G_HAM_B3_09': 0,
    '#G_HAM_B2_20_#G_HAM_B3_11': 0,
    '#G_HAM_B2_20_#G_HAM_B3_22': 0,
    '#G_HAM_B2_20_#R_HAM_B4_01': 0,
    '#G_HAM_B2_20_#W_HAM_B4_01': 0,
    '#G_HAM_B2_20_#G_HAM_B4_02': 0,
    '#G_HAM_B2_20_#G_HAM_B4_05': 0,
    '#G_HAM_B2_20_#G_HAM_B4_06': 0,
    '#G_HAM_B2_20_#G_TCM_Default': 1,
    '#G_HAM_B2_20_#G_TCM_00': 1,
    '#G_HAM_B2_20_#G_TCM_07': 1,
    '#G_HAM_B2_20_#G_TCM_27': 1,
    '#G_HAM_B2_20_#G_TCM_40': 1,
    '#G_HAM_B2_20_#G_TCM_55': 1,
    '#G_HAM_B2_20_#G_TCM_59_01': 1,
    '#G_HAM_B2_20_#G_TCM_59_02': 1,
    '#G_HAM_B2_20_#G_TCM_59_03': 1,
    '#G_HAM_B2_20_#G_TCM_74': 1,
    '#G_HAM_B2_20_#G_TCM_81': 0,
    '#G_HAM_B2_20_#G_TCM_82': 1,
    '#G_HAM_B2_20_#G_TCM_85': 1,
    '#G_HAM_B2_20_#G_TCM_86': 1,
    '#G_HAM_B2_20_#G_TCM_88': 1,
    '#G_HAM_B2_20_#G_TCM_89': 1,
    '#G_HAM_B2_20_#G_TCM_91': 1,
    '#G_HAM_B2_20_#G_TCM_92': 1,
    '#G_HAM_B2_20_#G_TCM_94': 1,
    '#G_HAM_B2_20_#G_TCM_97': 1,
    '#R_HAM_B2_25_#G_HAM_B2_20': 0,
    '#R_HAM_B2_25_#W_HAM_B2_25': 0,
    '#R_HAM_B2_25_#R_HAM_B3_05': 0,
    '#R_HAM_B2_25_#W_HAM_B3_05': 0,
    '#R_HAM_B2_25_#G_HAM_B3_06': 0,
    '#R_HAM_B2_25_#R_HAM_B3_08': 0,
    '#R_HAM_B2_25_#W_HAM_B3_08': 0,
    '#R_HAM_B2_25_#G_HAM_B3_09': 0,
    '#R_HAM_B2_25_#G_HAM_B3_11': 0,
    '#R_HAM_B2_25_#G_HAM_B3_22': 0,
    '#R_HAM_B2_25_#R_HAM_B4_01': 0,
    '#R_HAM_B2_25_#W_HAM_B4_01': 0,
    '#R_HAM_B2_25_#G_HAM_B4_02': 0,
    '#R_HAM_B2_25_#G_HAM_B4_05': 0,
    '#R_HAM_B2_25_#G_HAM_B4_06': 0,
    '#R_HAM_B2_25_#G_TCM_Default': 1,
    '#R_HAM_B2_25_#G_TCM_00': 1,
    '#R_HAM_B2_25_#G_TCM_07': 1,
    '#R_HAM_B2_25_#G_TCM_27': 1,
    '#R_HAM_B2_25_#G_TCM_40': 1,
    '#R_HAM_B2_25_#G_TCM_55': 1,
    '#R_HAM_B2_25_#G_TCM_59_01': 1,
    '#R_HAM_B2_25_#G_TCM_59_02': 1,
    '#R_HAM_B2_25_#G_TCM_59_03': 1,
    '#R_HAM_B2_25_#G_TCM_74': 1,
    '#R_HAM_B2_25_#G_TCM_81': 1,
    '#R_HAM_B2_25_#G_TCM_82': 1,
    '#R_HAM_B2_25_#G_TCM_85': 1,
    '#R_HAM_B2_25_#G_TCM_86': 1,
    '#R_HAM_B2_25_#G_TCM_88': 1,
    '#R_HAM_B2_25_#G_TCM_89': 1,
    '#R_HAM_B2_25_#G_TCM_91': 1,
    '#R_HAM_B2_25_#G_TCM_92': 1,
    '#R_HAM_B2_25_#G_TCM_94': 1,
    '#R_HAM_B2_25_#G_TCM_97': 1,
    '#W_HAM_B2_25_#G_HAM_B2_20': 0,
    '#W_HAM_B2_25_#R_HAM_B3_05': 0,
    '#W_HAM_B2_25_#W_HAM_B3_05': 0,
    '#W_HAM_B2_25_#G_HAM_B3_06': 0,
    '#W_HAM_B2_25_#R_HAM_B3_08': 0,
    '#W_HAM_B2_25_#W_HAM_B3_08': 0,
    '#W_HAM_B2_25_#G_HAM_B3_09': 0,
    '#W_HAM_B2_25_#G_HAM_B3_11': 0,
    '#W_HAM_B2_25_#G_HAM_B3_22': 0,
    '#W_HAM_B2_25_#R_HAM_B4_01': 0,
    '#W_HAM_B2_25_#W_HAM_B4_01': 0,
    '#W_HAM_B2_25_#G_HAM_B4_02': 0,
    '#W_HAM_B2_25_#G_HAM_B4_05': 0,
    '#W_HAM_B2_25_#G_HAM_B4_06': 0,
    '#W_HAM_B2_25_#G_TCM_Default': 1,
    '#W_HAM_B2_25_#G_TCM_00': 1,
    '#W_HAM_B2_25_#G_TCM_07': 1,
    '#W_HAM_B2_25_#G_TCM_27': 1,
    '#W_HAM_B2_25_#G_TCM_40': 1,
    '#W_HAM_B2_25_#G_TCM_55': 1,
    '#W_HAM_B2_25_#G_TCM_59_01': 1,
    '#W_HAM_B2_25_#G_TCM_59_02': 1,
    '#W_HAM_B2_25_#G_TCM_59_03': 1,
    '#W_HAM_B2_25_#G_TCM_74': 1,
    '#W_HAM_B2_25_#G_TCM_81': 1,
    '#W_HAM_B2_25_#G_TCM_82': 1,
    '#W_HAM_B2_25_#G_TCM_85': 1,
    '#W_HAM_B2_25_#G_TCM_86': 1,
    '#W_HAM_B2_25_#G_TCM_88': 1,
    '#W_HAM_B2_25_#G_TCM_89': 1,
    '#W_HAM_B2_25_#G_TCM_91': 1,
    '#W_HAM_B2_25_#G_TCM_92': 1,
    '#W_HAM_B2_25_#G_TCM_94': 1,
    '#W_HAM_B2_25_#G_TCM_97': 1,
    '#R_HAM_B3_05_#G_HAM_B2_20': 0,
    '#R_HAM_B3_05_#W_HAM_B3_05': 0,
    '#R_HAM_B3_05_#G_HAM_B3_06': 0,
    '#R_HAM_B3_05_#R_HAM_B3_08': 0,
    '#R_HAM_B3_05_#W_HAM_B3_08': 0,
    '#R_HAM_B3_05_#G_HAM_B3_09': 0,
    '#R_HAM_B3_05_#G_HAM_B3_11': 0,
    '#R_HAM_B3_05_#G_HAM_B3_22': 0,
    '#R_HAM_B3_05_#R_HAM_B4_01': 0,
    '#R_HAM_B3_05_#W_HAM_B4_01': 0,
    '#R_HAM_B3_05_#G_HAM_B4_02': 0,
    '#R_HAM_B3_05_#G_HAM_B4_05': 0,
    '#R_HAM_B3_05_#G_HAM_B4_06': 0,
    '#R_HAM_B3_05_#G_TCM_Default': 1,
    '#R_HAM_B3_05_#G_TCM_00': 1,
    '#R_HAM_B3_05_#G_TCM_07': 1,
    '#R_HAM_B3_05_#G_TCM_27': 1,
    '#R_HAM_B3_05_#G_TCM_40': 0,
    '#R_HAM_B3_05_#G_TCM_55': 1,
    '#R_HAM_B3_05_#G_TCM_59_01': 1,
    '#R_HAM_B3_05_#G_TCM_59_02': 1,
    '#R_HAM_B3_05_#G_TCM_59_03': 1,
    '#R_HAM_B3_05_#G_TCM_74': 1,
    '#R_HAM_B3_05_#G_TCM_81': 1,
    '#R_HAM_B3_05_#G_TCM_82': 1,
    '#R_HAM_B3_05_#G_TCM_85': 0,
    '#R_HAM_B3_05_#G_TCM_86': 0,
    '#R_HAM_B3_05_#G_TCM_88': 0,
    '#R_HAM_B3_05_#G_TCM_89': 1,
    '#R_HAM_B3_05_#G_TCM_91': 1,
    '#R_HAM_B3_05_#G_TCM_92': 1,
    '#R_HAM_B3_05_#G_TCM_94': 1,
    '#R_HAM_B3_05_#G_TCM_97': 1,
    '#W_HAM_B3_05_#G_HAM_B2_20': 0,
    '#W_HAM_B3_05_#G_HAM_B3_06': 0,
    '#W_HAM_B3_05_#R_HAM_B3_08': 0,
    '#W_HAM_B3_05_#W_HAM_B3_08': 0,
    '#W_HAM_B3_05_#G_HAM_B3_09': 0,
    '#W_HAM_B3_05_#G_HAM_B3_11': 0,
    '#W_HAM_B3_05_#G_HAM_B3_22': 0,
    '#W_HAM_B3_05_#R_HAM_B4_01': 0,
    '#W_HAM_B3_05_#W_HAM_B4_01': 0,
    '#W_HAM_B3_05_#G_HAM_B4_02': 0,
    '#W_HAM_B3_05_#G_HAM_B4_05': 0,
    '#W_HAM_B3_05_#G_HAM_B4_06': 0,
    '#W_HAM_B3_05_#G_TCM_Default': 1,
    '#W_HAM_B3_05_#G_TCM_00': 1,
    '#W_HAM_B3_05_#G_TCM_07': 1,
    '#W_HAM_B3_05_#G_TCM_27': 1,
    '#W_HAM_B3_05_#G_TCM_40': 0,
    '#W_HAM_B3_05_#G_TCM_55': 1,
    '#W_HAM_B3_05_#G_TCM_59_01': 1,
    '#W_HAM_B3_05_#G_TCM_59_02': 1,
    '#W_HAM_B3_05_#G_TCM_59_03': 1,
    '#W_HAM_B3_05_#G_TCM_74': 1,
    '#W_HAM_B3_05_#G_TCM_81': 1,
    '#W_HAM_B3_05_#G_TCM_82': 1,
    '#W_HAM_B3_05_#G_TCM_85': 0,
    '#W_HAM_B3_05_#G_TCM_86': 0,
    '#W_HAM_B3_05_#G_TCM_88': 0,
    '#W_HAM_B3_05_#G_TCM_89': 1,
    '#W_HAM_B3_05_#G_TCM_91': 1,
    '#W_HAM_B3_05_#G_TCM_92': 1,
    '#W_HAM_B3_05_#G_TCM_94': 1,
    '#W_HAM_B3_05_#G_TCM_97': 1,
    '#G_HAM_B3_06_#G_HAM_B2_20': 0,
    '#G_HAM_B3_06_#R_HAM_B3_08': 0,
    '#G_HAM_B3_06_#W_HAM_B3_08': 0,
    '#G_HAM_B3_06_#G_HAM_B3_09': 0,
    '#G_HAM_B3_06_#G_HAM_B3_11': 0,
    '#G_HAM_B3_06_#G_HAM_B3_22': 0,
    '#G_HAM_B3_06_#R_HAM_B4_01': 0,
    '#G_HAM_B3_06_#W_HAM_B4_01': 0,
    '#G_HAM_B3_06_#G_HAM_B4_02': 0,
    '#G_HAM_B3_06_#G_HAM_B4_05': 0,
    '#G_HAM_B3_06_#G_HAM_B4_06': 0,
    '#G_HAM_B3_06_#G_TCM_Default': 1,
    '#G_HAM_B3_06_#G_TCM_00': 1,
    '#G_HAM_B3_06_#G_TCM_07': 1,
    '#G_HAM_B3_06_#G_TCM_27': 1,
    '#G_HAM_B3_06_#G_TCM_40': 1,
    '#G_HAM_B3_06_#G_TCM_55': 1,
    '#G_HAM_B3_06_#G_TCM_59_01': 1,
    '#G_HAM_B3_06_#G_TCM_59_02': 1,
    '#G_HAM_B3_06_#G_TCM_59_03': 1,
    '#G_HAM_B3_06_#G_TCM_74': 1,
    '#G_HAM_B3_06_#G_TCM_81': 1,
    '#G_HAM_B3_06_#G_TCM_82': 1,
    '#G_HAM_B3_06_#G_TCM_85': 1,
    '#G_HAM_B3_06_#G_TCM_86': 1,
    '#G_HAM_B3_06_#G_TCM_88': 1,
    '#G_HAM_B3_06_#G_TCM_89': 1,
    '#G_HAM_B3_06_#G_TCM_91': 1,
    '#G_HAM_B3_06_#G_TCM_92': 1,
    '#G_HAM_B3_06_#G_TCM_94': 1,
    '#G_HAM_B3_06_#G_TCM_97': 1,
    '#R_HAM_B3_08_#G_HAM_B2_20': 0,
    '#R_HAM_B3_08_#W_HAM_B3_08': 0,
    '#R_HAM_B3_08_#G_HAM_B3_09': 0,
    '#R_HAM_B3_08_#G_HAM_B3_11': 0,
    '#R_HAM_B3_08_#G_HAM_B3_22': 0,
    '#R_HAM_B3_08_#R_HAM_B4_01': 0,
    '#R_HAM_B3_08_#W_HAM_B4_01': 0,
    '#R_HAM_B3_08_#G_HAM_B4_02': 0,
    '#R_HAM_B3_08_#G_HAM_B4_05': 0,
    '#R_HAM_B3_08_#G_HAM_B4_06': 0,
    '#R_HAM_B3_08_#G_TCM_Default': 1,
    '#R_HAM_B3_08_#G_TCM_00': 1,
    '#R_HAM_B3_08_#G_TCM_07': 1,
    '#R_HAM_B3_08_#G_TCM_27': 1,
    '#R_HAM_B3_08_#G_TCM_40': 1,
    '#R_HAM_B3_08_#G_TCM_55': 1,
    '#R_HAM_B3_08_#G_TCM_59_01': 1,
    '#R_HAM_B3_08_#G_TCM_59_02': 1,
    '#R_HAM_B3_08_#G_TCM_59_03': 1,
    '#R_HAM_B3_08_#G_TCM_74': 1,
    '#R_HAM_B3_08_#G_TCM_81': 0,
    '#R_HAM_B3_08_#G_TCM_82': 1,
    '#R_HAM_B3_08_#G_TCM_85': 1,
    '#R_HAM_B3_08_#G_TCM_86': 1,
    '#R_HAM_B3_08_#G_TCM_88': 1,
    '#R_HAM_B3_08_#G_TCM_89': 1,
    '#R_HAM_B3_08_#G_TCM_91': 1,
    '#R_HAM_B3_08_#G_TCM_92': 1,
    '#R_HAM_B3_08_#G_TCM_94': 1,
    '#R_HAM_B3_08_#G_TCM_97': 1,
    '#W_HAM_B3_08_#G_HAM_B2_20': 0,
    '#W_HAM_B3_08_#G_HAM_B3_09': 0,
    '#W_HAM_B3_08_#G_HAM_B3_11': 0,
    '#W_HAM_B3_08_#G_HAM_B3_22': 0,
    '#W_HAM_B3_08_#R_HAM_B4_01': 0,
    '#W_HAM_B3_08_#W_HAM_B4_01': 0,
    '#W_HAM_B3_08_#G_HAM_B4_02': 0,
    '#W_HAM_B3_08_#G_HAM_B4_05': 0,
    '#W_HAM_B3_08_#G_HAM_B4_06': 0,
    '#W_HAM_B3_08_#G_TCM_Default': 1,
    '#W_HAM_B3_08_#G_TCM_00': 1,
    '#W_HAM_B3_08_#G_TCM_07': 1,
    '#W_HAM_B3_08_#G_TCM_27': 1,
    '#W_HAM_B3_08_#G_TCM_40': 1,
    '#W_HAM_B3_08_#G_TCM_55': 1,
    '#W_HAM_B3_08_#G_TCM_59_01': 1,
    '#W_HAM_B3_08_#G_TCM_59_02': 1,
    '#W_HAM_B3_08_#G_TCM_59_03': 1,
    '#W_HAM_B3_08_#G_TCM_74': 1,
    '#W_HAM_B3_08_#G_TCM_81': 0,
    '#W_HAM_B3_08_#G_TCM_82': 1,
    '#W_HAM_B3_08_#G_TCM_85': 1,
    '#W_HAM_B3_08_#G_TCM_86': 1,
    '#W_HAM_B3_08_#G_TCM_88': 1,
    '#W_HAM_B3_08_#G_TCM_89': 1,
    '#W_HAM_B3_08_#G_TCM_91': 1,
    '#W_HAM_B3_08_#G_TCM_92': 1,
    '#W_HAM_B3_08_#G_TCM_94': 1,
    '#W_HAM_B3_08_#G_TCM_97': 1,
    '#G_HAM_B3_09_#G_HAM_B2_20': 0,
    '#G_HAM_B3_09_#G_HAM_B3_11': 0,
    '#G_HAM_B3_09_#G_HAM_B3_22': 0,
    '#G_HAM_B3_09_#R_HAM_B4_01': 0,
    '#G_HAM_B3_09_#W_HAM_B4_01': 0,
    '#G_HAM_B3_09_#G_HAM_B4_02': 0,
    '#G_HAM_B3_09_#G_HAM_B4_05': 0,
    '#G_HAM_B3_09_#G_HAM_B4_06': 0,
    '#G_HAM_B3_09_#G_TCM_Default': 1,
    '#G_HAM_B3_09_#G_TCM_00': 1,
    '#G_HAM_B3_09_#G_TCM_07': 1,
    '#G_HAM_B3_09_#G_TCM_27': 1,
    '#G_HAM_B3_09_#G_TCM_40': 0,
    '#G_HAM_B3_09_#G_TCM_55': 1,
    '#G_HAM_B3_09_#G_TCM_59_01': 0,
    '#G_HAM_B3_09_#G_TCM_59_02': 0,
    '#G_HAM_B3_09_#G_TCM_59_03': 0,
    '#G_HAM_B3_09_#G_TCM_74': 0,
    '#G_HAM_B3_09_#G_TCM_81': 0,
    '#G_HAM_B3_09_#G_TCM_82': 0,
    '#G_HAM_B3_09_#G_TCM_85': 0,
    '#G_HAM_B3_09_#G_TCM_86': 0,
    '#G_HAM_B3_09_#G_TCM_88': 1,
    '#G_HAM_B3_09_#G_TCM_89': 0,
    '#G_HAM_B3_09_#G_TCM_91': 1,
    '#G_HAM_B3_09_#G_TCM_92': 1,
    '#G_HAM_B3_09_#G_TCM_94': 1,
    '#G_HAM_B3_09_#G_TCM_97': 0,
    '#G_HAM_B3_11_#G_HAM_B2_20': 0,
    '#G_HAM_B3_11_#G_HAM_B3_22': 0,
    '#G_HAM_B3_11_#R_HAM_B4_01': 0,
    '#G_HAM_B3_11_#W_HAM_B4_01': 0,
    '#G_HAM_B3_11_#G_HAM_B4_02': 0,
    '#G_HAM_B3_11_#G_HAM_B4_05': 0,
    '#G_HAM_B3_11_#G_HAM_B4_06': 0,
    '#G_HAM_B3_11_#G_TCM_Default': 1,
    '#G_HAM_B3_11_#G_TCM_00': 1,
    '#G_HAM_B3_11_#G_TCM_07': 1,
    '#G_HAM_B3_11_#G_TCM_27': 1,
    '#G_HAM_B3_11_#G_TCM_40': 1,
    '#G_HAM_B3_11_#G_TCM_55': 1,
    '#G_HAM_B3_11_#G_TCM_59_01': 1,
    '#G_HAM_B3_11_#G_TCM_59_02': 1,
    '#G_HAM_B3_11_#G_TCM_59_03': 1,
    '#G_HAM_B3_11_#G_TCM_74': 1,
    '#G_HAM_B3_11_#G_TCM_81': 1,
    '#G_HAM_B3_11_#G_TCM_82': 1,
    '#G_HAM_B3_11_#G_TCM_85': 1,
    '#G_HAM_B3_11_#G_TCM_86': 1,
    '#G_HAM_B3_11_#G_TCM_88': 1,
    '#G_HAM_B3_11_#G_TCM_89': 1,
    '#G_HAM_B3_11_#G_TCM_91': 1,
    '#G_HAM_B3_11_#G_TCM_92': 1,
    '#G_HAM_B3_11_#G_TCM_94': 1,
    '#G_HAM_B3_11_#G_TCM_97': 1,
    '#G_HAM_B3_22_#G_HAM_B2_20': 0,
    '#G_HAM_B3_22_#R_HAM_B4_01': 0,
    '#G_HAM_B3_22_#W_HAM_B4_01': 0,
    '#G_HAM_B3_22_#G_HAM_B4_02': 0,
    '#G_HAM_B3_22_#G_HAM_B4_05': 0,
    '#G_HAM_B3_22_#G_HAM_B4_06': 0,
    '#G_HAM_B3_22_#G_TCM_Default': 1,
    '#G_HAM_B3_22_#G_TCM_00': 1,
    '#G_HAM_B3_22_#G_TCM_07': 1,
    '#G_HAM_B3_22_#G_TCM_27': 1,
    '#G_HAM_B3_22_#G_TCM_40': 1,
    '#G_HAM_B3_22_#G_TCM_55': 1,
    '#G_HAM_B3_22_#G_TCM_59_01': 1,
    '#G_HAM_B3_22_#G_TCM_59_02': 1,
    '#G_HAM_B3_22_#G_TCM_59_03': 1,
    '#G_HAM_B3_22_#G_TCM_74': 1,
    '#G_HAM_B3_22_#G_TCM_81': 1,
    '#G_HAM_B3_22_#G_TCM_82': 1,
    '#G_HAM_B3_22_#G_TCM_85': 1,
    '#G_HAM_B3_22_#G_TCM_86': 1,
    '#G_HAM_B3_22_#G_TCM_88': 1,
    '#G_HAM_B3_22_#G_TCM_89': 1,
    '#G_HAM_B3_22_#G_TCM_91': 1,
    '#G_HAM_B3_22_#G_TCM_92': 1,
    '#G_HAM_B3_22_#G_TCM_94': 1,
    '#G_HAM_B3_22_#G_TCM_97': 1,
    '#R_HAM_B4_01_#G_HAM_B2_20': 0,
    '#R_HAM_B4_01_#W_HAM_B4_01': 0,
    '#R_HAM_B4_01_#G_HAM_B4_02': 0,
    '#R_HAM_B4_01_#G_HAM_B4_05': 0,
    '#R_HAM_B4_01_#G_HAM_B4_06': 0,
    '#R_HAM_B4_01_#G_TCM_Default': 1,
    '#R_HAM_B4_01_#G_TCM_00': 1,
    '#R_HAM_B4_01_#G_TCM_07': 1,
    '#R_HAM_B4_01_#G_TCM_27': 1,
    '#R_HAM_B4_01_#G_TCM_40': 1,
    '#R_HAM_B4_01_#G_TCM_55': 1,
    '#R_HAM_B4_01_#G_TCM_59_01': 1,
    '#R_HAM_B4_01_#G_TCM_59_02': 1,
    '#R_HAM_B4_01_#G_TCM_59_03': 1,
    '#R_HAM_B4_01_#G_TCM_74': 1,
    '#R_HAM_B4_01_#G_TCM_81': 0,
    '#R_HAM_B4_01_#G_TCM_82': 1,
    '#R_HAM_B4_01_#G_TCM_85': 1,
    '#R_HAM_B4_01_#G_TCM_86': 1,
    '#R_HAM_B4_01_#G_TCM_88': 1,
    '#R_HAM_B4_01_#G_TCM_89': 1,
    '#R_HAM_B4_01_#G_TCM_91': 1,
    '#R_HAM_B4_01_#G_TCM_92': 1,
    '#R_HAM_B4_01_#G_TCM_94': 1,
    '#R_HAM_B4_01_#G_TCM_97': 1,
    '#W_HAM_B4_01_#G_HAM_B2_20': 0,
    '#W_HAM_B4_01_#G_HAM_B4_02': 0,
    '#W_HAM_B4_01_#G_HAM_B4_05': 0,
    '#W_HAM_B4_01_#G_HAM_B4_06': 0,
    '#W_HAM_B4_01_#G_TCM_Default': 1,
    '#W_HAM_B4_01_#G_TCM_00': 1,
    '#W_HAM_B4_01_#G_TCM_07': 1,
    '#W_HAM_B4_01_#G_TCM_27': 1,
    '#W_HAM_B4_01_#G_TCM_40': 1,
    '#W_HAM_B4_01_#G_TCM_55': 1,
    '#W_HAM_B4_01_#G_TCM_59_01': 1,
    '#W_HAM_B4_01_#G_TCM_59_02': 1,
    '#W_HAM_B4_01_#G_TCM_59_03': 1,
    '#W_HAM_B4_01_#G_TCM_74': 1,
    '#W_HAM_B4_01_#G_TCM_81': 0,
    '#W_HAM_B4_01_#G_TCM_82': 1,
    '#W_HAM_B4_01_#G_TCM_85': 1,
    '#W_HAM_B4_01_#G_TCM_86': 1,
    '#W_HAM_B4_01_#G_TCM_88': 1,
    '#W_HAM_B4_01_#G_TCM_89': 1,
    '#W_HAM_B4_01_#G_TCM_91': 1,
    '#W_HAM_B4_01_#G_TCM_92': 1,
    '#W_HAM_B4_01_#G_TCM_94': 1,
    '#W_HAM_B4_01_#G_TCM_97': 1,
    '#G_HAM_B4_02_#G_HAM_B2_20': 0,
    '#G_HAM_B4_02_#G_HAM_B4_05': 0,
    '#G_HAM_B4_02_#G_HAM_B4_06': 0,
    '#G_HAM_B4_02_#G_TCM_Default': 1,
    '#G_HAM_B4_02_#G_TCM_00': 1,
    '#G_HAM_B4_02_#G_TCM_07': 1,
    '#G_HAM_B4_02_#G_TCM_27': 1,
    '#G_HAM_B4_02_#G_TCM_40': 1,
    '#G_HAM_B4_02_#G_TCM_55': 1,
    '#G_HAM_B4_02_#G_TCM_59_01': 1,
    '#G_HAM_B4_02_#G_TCM_59_02': 1,
    '#G_HAM_B4_02_#G_TCM_59_03': 1,
    '#G_HAM_B4_02_#G_TCM_74': 1,
    '#G_HAM_B4_02_#G_TCM_81': 1,
    '#G_HAM_B4_02_#G_TCM_82': 1,
    '#G_HAM_B4_02_#G_TCM_85': 1,
    '#G_HAM_B4_02_#G_TCM_86': 1,
    '#G_HAM_B4_02_#G_TCM_88': 1,
    '#G_HAM_B4_02_#G_TCM_89': 1,
    '#G_HAM_B4_02_#G_TCM_91': 1,
    '#G_HAM_B4_02_#G_TCM_92': 1,
    '#G_HAM_B4_02_#G_TCM_94': 1,
    '#G_HAM_B4_02_#G_TCM_97': 1,
    '#G_HAM_B4_05_#G_HAM_B2_20': 0,
    '#G_HAM_B4_05_#G_HAM_B4_06': 0,
    '#G_HAM_B4_05_#G_TCM_Default': 1,
    '#G_HAM_B4_05_#G_TCM_00': 1,
    '#G_HAM_B4_05_#G_TCM_07': 1,
    '#G_HAM_B4_05_#G_TCM_27': 1,
    '#G_HAM_B4_05_#G_TCM_40': 1,
    '#G_HAM_B4_05_#G_TCM_55': 1,
    '#G_HAM_B4_05_#G_TCM_59_01': 1,
    '#G_HAM_B4_05_#G_TCM_59_02': 1,
    '#G_HAM_B4_05_#G_TCM_59_03': 1,
    '#G_HAM_B4_05_#G_TCM_74': 1,
    '#G_HAM_B4_05_#G_TCM_81': 1,
    '#G_HAM_B4_05_#G_TCM_82': 1,
    '#G_HAM_B4_05_#G_TCM_85': 1,
    '#G_HAM_B4_05_#G_TCM_86': 1,
    '#G_HAM_B4_05_#G_TCM_88': 1,
    '#G_HAM_B4_05_#G_TCM_89': 1,
    '#G_HAM_B4_05_#G_TCM_91': 1,
    '#G_HAM_B4_05_#G_TCM_92': 1,
    '#G_HAM_B4_05_#G_TCM_94': 1,
    '#G_HAM_B4_05_#G_TCM_97': 1,
    '#G_HAM_B4_06_#G_HAM_B2_20': 0,
    '#G_HAM_B4_06_#G_TCM_Default': 1,
    '#G_HAM_B4_06_#G_TCM_00': 1,
    '#G_HAM_B4_06_#G_TCM_07': 1,
    '#G_HAM_B4_06_#G_TCM_27': 1,
    '#G_HAM_B4_06_#G_TCM_40': 1,
    '#G_HAM_B4_06_#G_TCM_55': 1,
    '#G_HAM_B4_06_#G_TCM_59_01': 1,
    '#G_HAM_B4_06_#G_TCM_59_02': 1,
    '#G_HAM_B4_06_#G_TCM_59_03': 1,
    '#G_HAM_B4_06_#G_TCM_74': 1,
    '#G_HAM_B4_06_#G_TCM_81': 1,
    '#G_HAM_B4_06_#G_TCM_82': 1,
    '#G_HAM_B4_06_#G_TCM_85': 1,
    '#G_HAM_B4_06_#G_TCM_86': 1,
    '#G_HAM_B4_06_#G_TCM_88': 1,
    '#G_HAM_B4_06_#G_TCM_89': 1,
    '#G_HAM_B4_06_#G_TCM_91': 1,
    '#G_HAM_B4_06_#G_TCM_92': 1,
    '#G_HAM_B4_06_#G_TCM_94': 1,
    '#G_HAM_B4_06_#G_TCM_97': 1,
    '#G_TCM_Default_#G_HAM_B2_20': 1,
    '#G_TCM_Default_#G_TCM_00': 0,
    '#G_TCM_Default_#G_TCM_07': 0,
    '#G_TCM_Default_#G_TCM_27': 0,
    '#G_TCM_Default_#G_TCM_40': 0,
    '#G_TCM_Default_#G_TCM_55': 0,
    '#G_TCM_Default_#G_TCM_59_01': 0,
    '#G_TCM_Default_#G_TCM_59_02': 0,
    '#G_TCM_Default_#G_TCM_59_03': 0,
    '#G_TCM_Default_#G_TCM_74': 0,
    '#G_TCM_Default_#G_TCM_81': 0,
    '#G_TCM_Default_#G_TCM_82': 0,
    '#G_TCM_Default_#G_TCM_85': 0,
    '#G_TCM_Default_#G_TCM_86': 0,
    '#G_TCM_Default_#G_TCM_88': 0,
    '#G_TCM_Default_#G_TCM_89': 0,
    '#G_TCM_Default_#G_TCM_91': 0,
    '#G_TCM_Default_#G_TCM_92': 0,
    '#G_TCM_Default_#G_TCM_94': 0,
    '#G_TCM_Default_#G_TCM_97': 0,
    '#G_TCM_00_#G_HAM_B2_20': 1,
    '#G_TCM_00_#G_TCM_07': 0,
    '#G_TCM_00_#G_TCM_27': 0,
    '#G_TCM_00_#G_TCM_40': 0,
    '#G_TCM_00_#G_TCM_55': 0,
    '#G_TCM_00_#G_TCM_59_01': 0,
    '#G_TCM_00_#G_TCM_59_02': 0,
    '#G_TCM_00_#G_TCM_59_03': 0,
    '#G_TCM_00_#G_TCM_74': 0,
    '#G_TCM_00_#G_TCM_81': 0,
    '#G_TCM_00_#G_TCM_82': 0,
    '#G_TCM_00_#G_TCM_85': 0,
    '#G_TCM_00_#G_TCM_86': 0,
    '#G_TCM_00_#G_TCM_88': 0,
    '#G_TCM_00_#G_TCM_89': 0,
    '#G_TCM_00_#G_TCM_91': 0,
    '#G_TCM_00_#G_TCM_92': 0,
    '#G_TCM_00_#G_TCM_94': 0,
    '#G_TCM_00_#G_TCM_97': 0,
    '#G_TCM_07_#G_HAM_B2_20': 1,
    '#G_TCM_07_#G_TCM_27': 0,
    '#G_TCM_07_#G_TCM_40': 0,
    '#G_TCM_07_#G_TCM_55': 0,
    '#G_TCM_07_#G_TCM_59_01': 0,
    '#G_TCM_07_#G_TCM_59_02': 0,
    '#G_TCM_07_#G_TCM_59_03': 0,
    '#G_TCM_07_#G_TCM_74': 0,
    '#G_TCM_07_#G_TCM_81': 0,
    '#G_TCM_07_#G_TCM_82': 0,
    '#G_TCM_07_#G_TCM_85': 0,
    '#G_TCM_07_#G_TCM_86': 0,
    '#G_TCM_07_#G_TCM_88': 0,
    '#G_TCM_07_#G_TCM_89': 0,
    '#G_TCM_07_#G_TCM_91': 0,
    '#G_TCM_07_#G_TCM_92': 0,
    '#G_TCM_07_#G_TCM_94': 0,
    '#G_TCM_07_#G_TCM_97': 0,
    '#G_TCM_27_#G_HAM_B2_20': 1,
    '#G_TCM_27_#G_TCM_40': 0,
    '#G_TCM_27_#G_TCM_55': 0,
    '#G_TCM_27_#G_TCM_59_01': 0,
    '#G_TCM_27_#G_TCM_59_02': 0,
    '#G_TCM_27_#G_TCM_59_03': 0,
    '#G_TCM_27_#G_TCM_74': 0,
    '#G_TCM_27_#G_TCM_81': 0,
    '#G_TCM_27_#G_TCM_82': 0,
    '#G_TCM_27_#G_TCM_85': 0,
    '#G_TCM_27_#G_TCM_86': 0,
    '#G_TCM_27_#G_TCM_88': 0,
    '#G_TCM_27_#G_TCM_89': 0,
    '#G_TCM_27_#G_TCM_91': 0,
    '#G_TCM_27_#G_TCM_92': 0,
    '#G_TCM_27_#G_TCM_94': 0,
    '#G_TCM_27_#G_TCM_97': 0,
    '#G_TCM_40_#G_HAM_B2_20': 1,
    '#G_TCM_40_#G_TCM_55': 0,
    '#G_TCM_40_#G_TCM_59_01': 0,
    '#G_TCM_40_#G_TCM_59_02': 0,
    '#G_TCM_40_#G_TCM_59_03': 0,
    '#G_TCM_40_#G_TCM_74': 0,
    '#G_TCM_40_#G_TCM_81': 0,
    '#G_TCM_40_#G_TCM_82': 0,
    '#G_TCM_40_#G_TCM_85': 0,
    '#G_TCM_40_#G_TCM_86': 0,
    '#G_TCM_40_#G_TCM_88': 0,
    '#G_TCM_40_#G_TCM_89': 0,
    '#G_TCM_40_#G_TCM_91': 0,
    '#G_TCM_40_#G_TCM_92': 0,
    '#G_TCM_40_#G_TCM_94': 0,
    '#G_TCM_40_#G_TCM_97': 0,
    '#G_TCM_55_#G_HAM_B2_20': 1,
    '#G_TCM_55_#G_TCM_59_01': 0,
    '#G_TCM_55_#G_TCM_59_02': 0,
    '#G_TCM_55_#G_TCM_59_03': 0,
    '#G_TCM_55_#G_TCM_74': 0,
    '#G_TCM_55_#G_TCM_81': 0,
    '#G_TCM_55_#G_TCM_82': 0,
    '#G_TCM_55_#G_TCM_85': 0,
    '#G_TCM_55_#G_TCM_86': 0,
    '#G_TCM_55_#G_TCM_88': 0,
    '#G_TCM_55_#G_TCM_89': 0,
    '#G_TCM_55_#G_TCM_91': 0,
    '#G_TCM_55_#G_TCM_92': 0,
    '#G_TCM_55_#G_TCM_94': 0,
    '#G_TCM_55_#G_TCM_97': 0,
    '#G_TCM_59_01_#G_HAM_B2_20': 1,
    '#G_TCM_59_01_#G_TCM_59_02': 0,
    '#G_TCM_59_01_#G_TCM_59_03': 0,
    '#G_TCM_59_01_#G_TCM_74': 0,
    '#G_TCM_59_01_#G_TCM_81': 0,
    '#G_TCM_59_01_#G_TCM_82': 0,
    '#G_TCM_59_01_#G_TCM_85': 0,
    '#G_TCM_59_01_#G_TCM_86': 0,
    '#G_TCM_59_01_#G_TCM_88': 0,
    '#G_TCM_59_01_#G_TCM_89': 0,
    '#G_TCM_59_01_#G_TCM_91': 0,
    '#G_TCM_59_01_#G_TCM_92': 0,
    '#G_TCM_59_01_#G_TCM_94': 0,
    '#G_TCM_59_01_#G_TCM_97': 0,
    '#G_TCM_59_02_#G_HAM_B2_20': 1,
    '#G_TCM_59_02_#G_TCM_59_03': 0,
    '#G_TCM_59_02_#G_TCM_74': 0,
    '#G_TCM_59_02_#G_TCM_81': 0,
    '#G_TCM_59_02_#G_TCM_82': 0,
    '#G_TCM_59_02_#G_TCM_85': 0,
    '#G_TCM_59_02_#G_TCM_86': 0,
    '#G_TCM_59_02_#G_TCM_88': 0,
    '#G_TCM_59_02_#G_TCM_89': 0,
    '#G_TCM_59_02_#G_TCM_91': 0,
    '#G_TCM_59_02_#G_TCM_92': 0,
    '#G_TCM_59_02_#G_TCM_94': 0,
    '#G_TCM_59_02_#G_TCM_97': 0,
    '#G_TCM_59_03_#G_HAM_B2_20': 1,
    '#G_TCM_59_03_#G_TCM_74': 0,
    '#G_TCM_59_03_#G_TCM_81': 0,
    '#G_TCM_59_03_#G_TCM_82': 0,
    '#G_TCM_59_03_#G_TCM_85': 0,
    '#G_TCM_59_03_#G_TCM_86': 0,
    '#G_TCM_59_03_#G_TCM_88': 0,
    '#G_TCM_59_03_#G_TCM_89': 0,
    '#G_TCM_59_03_#G_TCM_91': 0,
    '#G_TCM_59_03_#G_TCM_92': 0,
    '#G_TCM_59_03_#G_TCM_94': 0,
    '#G_TCM_59_03_#G_TCM_97': 0,
    '#G_TCM_74_#G_HAM_B2_20': 1,
    '#G_TCM_74_#G_TCM_81': 0,
    '#G_TCM_74_#G_TCM_82': 0,
    '#G_TCM_74_#G_TCM_85': 0,
    '#G_TCM_74_#G_TCM_86': 0,
    '#G_TCM_74_#G_TCM_88': 0,
    '#G_TCM_74_#G_TCM_89': 0,
    '#G_TCM_74_#G_TCM_91': 0,
    '#G_TCM_74_#G_TCM_92': 0,
    '#G_TCM_74_#G_TCM_94': 0,
    '#G_TCM_74_#G_TCM_97': 0,
    '#G_TCM_81_#G_HAM_B2_20': 0,
    '#G_TCM_81_#G_TCM_82': 0,
    '#G_TCM_81_#G_TCM_85': 0,
    '#G_TCM_81_#G_TCM_86': 0,
    '#G_TCM_81_#G_TCM_88': 0,
    '#G_TCM_81_#G_TCM_89': 0,
    '#G_TCM_81_#G_TCM_91': 0,
    '#G_TCM_81_#G_TCM_92': 0,
    '#G_TCM_81_#G_TCM_94': 0,
    '#G_TCM_81_#G_TCM_97': 0,
    '#G_TCM_82_#G_HAM_B2_20': 1,
    '#G_TCM_82_#G_TCM_85': 0,
    '#G_TCM_82_#G_TCM_86': 0,
    '#G_TCM_82_#G_TCM_88': 0,
    '#G_TCM_82_#G_TCM_89': 0,
    '#G_TCM_82_#G_TCM_91': 0,
    '#G_TCM_82_#G_TCM_92': 0,
    '#G_TCM_82_#G_TCM_94': 0,
    '#G_TCM_82_#G_TCM_97': 0,
    '#G_TCM_85_#G_HAM_B2_20': 1,
    '#G_TCM_85_#G_TCM_86': 0,
    '#G_TCM_85_#G_TCM_88': 0,
    '#G_TCM_85_#G_TCM_89': 0,
    '#G_TCM_85_#G_TCM_91': 0,
    '#G_TCM_85_#G_TCM_92': 0,
    '#G_TCM_85_#G_TCM_94': 0,
    '#G_TCM_85_#G_TCM_97': 0,
    '#G_TCM_86_#G_HAM_B2_20': 1,
    '#G_TCM_86_#G_TCM_88': 0,
    '#G_TCM_86_#G_TCM_89': 0,
    '#G_TCM_86_#G_TCM_91': 0,
    '#G_TCM_86_#G_TCM_92': 0,
    '#G_TCM_86_#G_TCM_94': 0,
    '#G_TCM_86_#G_TCM_97': 0,
    '#G_TCM_88_#G_HAM_B2_20': 1,
    '#G_TCM_88_#G_TCM_89': 0,
    '#G_TCM_88_#G_TCM_91': 0,
    '#G_TCM_88_#G_TCM_92': 0,
    '#G_TCM_88_#G_TCM_94': 0,
    '#G_TCM_88_#G_TCM_97': 0,
    '#G_TCM_89_#G_HAM_B2_20': 1,
    '#G_TCM_89_#G_TCM_91': 0,
    '#G_TCM_89_#G_TCM_92': 0,
    '#G_TCM_89_#G_TCM_94': 0,
    '#G_TCM_89_#G_TCM_97': 0,
    '#G_TCM_91_#G_HAM_B2_20': 1,
    '#G_TCM_91_#G_TCM_92': 0,
    '#G_TCM_91_#G_TCM_94': 0,
    '#G_TCM_91_#G_TCM_97': 0,
    '#G_TCM_92_#G_HAM_B2_20': 1,
    '#G_TCM_92_#G_TCM_94': 0,
    '#G_TCM_92_#G_TCM_97': 0,
    '#G_TCM_94_#G_HAM_B2_20': 1,
    '#G_TCM_94_#G_TCM_97': 0,
    '#G_TCM_97_#G_HAM_B2_20': 1
  }
  // compatibility: {
  //   "Wesley_Rodrigo": 0,
  //   "Wesley_Human _Face": 1,
  //   "Wesley_Cyber_Visor": 1,
  //   "Wesley_Magna_Face": 1,
  //   "Wesley_Wesley_Hair": 1,
  //   "Wesley_Rodrigo_Hair": 1,
  //   "Wesley_Human_Body": 1,
  //   "Wesley_Robotic Arm 03": 1,
  //   "Wesley_Robotic Arm 05": 1,
  //   "Wesley_Magna body": 1,
  //   "Wesley_HA_None": 1,
  //   "Wesley_HAM_B1_22": 1,
  //   "Wesley_HAM_B1_31": 1,
  //   "Wesley_HAM_B1_32": 1,
  //   "Wesley_HAM_B2_14": 1,
  //   "Wesley_HAM_B2_20": 1,
  //   "Wesley_HAM_B3_05": 1,
  //   "Wesley_HAM_B3_06": 1,
  //   "Wesley_HAM_B3_08": 1,
  //   "Wesley_HAM_B3_09": 1,
  //   "Wesley_HAM_B3_11": 1,
  //   "Wesley_HAM_B3_22": 1,
  //   "Wesley_HAM_B4_01": 1,
  //   "Wesley_HAM_B4_02": 1,
  //   "Wesley_HAM_B4_05": 1,
  //   "Wesley_HAM_B4_06": 1,
  //   "Wesley_TCM_Default": 1,
  //   "Wesley_TCM_00": 1,
  //   "Wesley_TCM_07": 1,
  //   "Wesley_TCM_27": 1,
  //   "Wesley_TCM_40": 1,
  //   "Wesley_TCM_55": 1,
  //   "Wesley_TCM_59_01": 1,
  //   "Wesley_TCM_59_02": 1,
  //   "Wesley_TCM_59_03": 1,
  //   "Wesley_TCM_74": 1,
  //   "Wesley_TCM_81": 1,
  //   "Wesley_TCM_82": 1,
  //   "Wesley_TCM_85": 1,
  //   "Wesley_TCM_88": 1,
  //   "Wesley_TCM_89": 1,
  //   "Wesley_TCM_91": 1,
  //   "Wesley_TCM_92": 1,
  //   "Wesley_TCM_94": 1,
  //   "Wesley_TCM_97": 1,
  //   "Rodrigo_Human _Face": 1,
  //   "Rodrigo_Cyber_Visor": 1,
  //   "Rodrigo_Magna_Face": 1,
  //   "Rodrigo_Wesley_Hair": 1,
  //   "Rodrigo_Rodrigo_Hair": 1,
  //   "Rodrigo_Human_Body": 1,
  //   "Rodrigo_Robotic Arm 03": 1,
  //   "Rodrigo_Robotic Arm 05": 1,
  //   "Rodrigo_Magna body": 1,
  //   "Rodrigo_HA_None": 1,
  //   "Rodrigo_HAM_B1_22": 1,
  //   "Rodrigo_HAM_B1_31": 1,
  //   "Rodrigo_HAM_B1_32": 1,
  //   "Rodrigo_HAM_B2_14": 1,
  //   "Rodrigo_HAM_B2_20": 1,
  //   "Rodrigo_HAM_B3_05": 1,
  //   "Rodrigo_HAM_B3_06": 1,
  //   "Rodrigo_HAM_B3_08": 1,
  //   "Rodrigo_HAM_B3_09": 1,
  //   "Rodrigo_HAM_B3_11": 1,
  //   "Rodrigo_HAM_B3_22": 1,
  //   "Rodrigo_HAM_B4_01": 1,
  //   "Rodrigo_HAM_B4_02": 1,
  //   "Rodrigo_HAM_B4_05": 1,
  //   "Rodrigo_HAM_B4_06": 1,
  //   "Rodrigo_TCM_Default": 1,
  //   "Rodrigo_TCM_00": 1,
  //   "Rodrigo_TCM_07": 1,
  //   "Rodrigo_TCM_27": 1,
  //   "Rodrigo_TCM_40": 1,
  //   "Rodrigo_TCM_55": 1,
  //   "Rodrigo_TCM_59_01": 1,
  //   "Rodrigo_TCM_59_02": 1,
  //   "Rodrigo_TCM_59_03": 1,
  //   "Rodrigo_TCM_74": 1,
  //   "Rodrigo_TCM_81": 1,
  //   "Rodrigo_TCM_82": 1,
  //   "Rodrigo_TCM_85": 1,
  //   "Rodrigo_TCM_88": 1,
  //   "Rodrigo_TCM_89": 1,
  //   "Rodrigo_TCM_91": 1,
  //   "Rodrigo_TCM_92": 1,
  //   "Rodrigo_TCM_94": 1,
  //   "Rodrigo_TCM_97": 1,
  //   "Human _Face_Cyber_Visor": 0,
  //   "Human _Face_Magna_Face": 0,
  //   "Human _Face_Wesley_Hair": 0,
  //   "Human _Face_Rodrigo_Hair": 0,
  //   "Human _Face_Human_Body": 1,
  //   "Human _Face_Robotic Arm 03": 1,
  //   "Human _Face_Robotic Arm 05": 1,
  //   "Human _Face_Magna body": 1,
  //   "Human _Face_HA_None": 1,
  //   "Human _Face_HAM_B1_22": 1,
  //   "Human _Face_HAM_B1_31": 1,
  //   "Human _Face_HAM_B1_32": 1,
  //   "Human _Face_HAM_B2_14": 1,
  //   "Human _Face_HAM_B2_20": 1,
  //   "Human _Face_HAM_B3_05": 1,
  //   "Human _Face_HAM_B3_06": 1,
  //   "Human _Face_HAM_B3_08": 1,
  //   "Human _Face_HAM_B3_09": 1,
  //   "Human _Face_HAM_B3_11": 1,
  //   "Human _Face_HAM_B3_22": 1,
  //   "Human _Face_HAM_B4_01": 1,
  //   "Human _Face_HAM_B4_02": 1,
  //   "Human _Face_HAM_B4_05": 1,
  //   "Human _Face_HAM_B4_06": 1,
  //   "Human _Face_TCM_Default": 1,
  //   "Human _Face_TCM_00": 1,
  //   "Human _Face_TCM_07": 1,
  //   "Human _Face_TCM_27": 1,
  //   "Human _Face_TCM_40": 1,
  //   "Human _Face_TCM_55": 1,
  //   "Human _Face_TCM_59_01": 1,
  //   "Human _Face_TCM_59_02": 1,
  //   "Human _Face_TCM_59_03": 1,
  //   "Human _Face_TCM_74": 1,
  //   "Human _Face_TCM_81": 1,
  //   "Human _Face_TCM_82": 1,
  //   "Human _Face_TCM_85": 1,
  //   "Human _Face_TCM_88": 1,
  //   "Human _Face_TCM_89": 1,
  //   "Human _Face_TCM_91": 1,
  //   "Human _Face_TCM_92": 1,
  //   "Human _Face_TCM_94": 1,
  //   "Human _Face_TCM_97": 1,
  //   "Cyber_Visor_Magna_Face": 0,
  //   "Cyber_Visor_Wesley_Hair": 0,
  //   "Cyber_Visor_Rodrigo_Hair": 0,
  //   "Cyber_Visor_Human_Body": 1,
  //   "Cyber_Visor_Robotic Arm 03": 1,
  //   "Cyber_Visor_Robotic Arm 05": 1,
  //   "Cyber_Visor_Magna body": 1,
  //   "Cyber_Visor_HA_None": 1,
  //   "Cyber_Visor_HAM_B1_22": 0,
  //   "Cyber_Visor_HAM_B1_31": 1,
  //   "Cyber_Visor_HAM_B1_32": 0,
  //   "Cyber_Visor_HAM_B2_14": 1,
  //   "Cyber_Visor_HAM_B2_20": 1,
  //   "Cyber_Visor_HAM_B3_05": 0,
  //   "Cyber_Visor_HAM_B3_06": 1,
  //   "Cyber_Visor_HAM_B3_08": 0,
  //   "Cyber_Visor_HAM_B3_09": 0,
  //   "Cyber_Visor_HAM_B3_11": 0,
  //   "Cyber_Visor_HAM_B3_22": 0,
  //   "Cyber_Visor_HAM_B4_01": 0,
  //   "Cyber_Visor_HAM_B4_02": 0,
  //   "Cyber_Visor_HAM_B4_05": 1,
  //   "Cyber_Visor_HAM_B4_06": 0,
  //   "Cyber_Visor_TCM_Default": 1,
  //   "Cyber_Visor_TCM_00": 1,
  //   "Cyber_Visor_TCM_07": 1,
  //   "Cyber_Visor_TCM_27": 1,
  //   "Cyber_Visor_TCM_40": 1,
  //   "Cyber_Visor_TCM_55": 1,
  //   "Cyber_Visor_TCM_59_01": 1,
  //   "Cyber_Visor_TCM_59_02": 1,
  //   "Cyber_Visor_TCM_59_03": 1,
  //   "Cyber_Visor_TCM_74": 1,
  //   "Cyber_Visor_TCM_81": 1,
  //   "Cyber_Visor_TCM_82": 1,
  //   "Cyber_Visor_TCM_85": 1,
  //   "Cyber_Visor_TCM_88": 1,
  //   "Cyber_Visor_TCM_89": 1,
  //   "Cyber_Visor_TCM_91": 1,
  //   "Cyber_Visor_TCM_92": 1,
  //   "Cyber_Visor_TCM_94": 1,
  //   "Cyber_Visor_TCM_97": 1,
  //   "Magna_Face_Wesley_Hair": 0,
  //   "Magna_Face_Rodrigo_Hair": 0,
  //   "Magna_Face_Human_Body": 1,
  //   "Magna_Face_Robotic Arm 03": 1,
  //   "Magna_Face_Robotic Arm 05": 1,
  //   "Magna_Face_Magna body": 1,
  //   "Magna_Face_HA_None": 1,
  //   "Magna_Face_HAM_B1_22": 1,
  //   "Magna_Face_HAM_B1_31": 1,
  //   "Magna_Face_HAM_B1_32": 1,
  //   "Magna_Face_HAM_B2_14": 1,
  //   "Magna_Face_HAM_B2_20": 1,
  //   "Magna_Face_HAM_B3_05": 1,
  //   "Magna_Face_HAM_B3_06": 1,
  //   "Magna_Face_HAM_B3_08": 1,
  //   "Magna_Face_HAM_B3_09": 1,
  //   "Magna_Face_HAM_B3_11": 1,
  //   "Magna_Face_HAM_B3_22": 1,
  //   "Magna_Face_HAM_B4_01": 1,
  //   "Magna_Face_HAM_B4_02": 1,
  //   "Magna_Face_HAM_B4_05": 1,
  //   "Magna_Face_HAM_B4_06": 1,
  //   "Magna_Face_TCM_Default": 1,
  //   "Magna_Face_TCM_00": 1,
  //   "Magna_Face_TCM_07": 1,
  //   "Magna_Face_TCM_27": 1,
  //   "Magna_Face_TCM_40": 1,
  //   "Magna_Face_TCM_55": 1,
  //   "Magna_Face_TCM_59_01": 1,
  //   "Magna_Face_TCM_59_02": 1,
  //   "Magna_Face_TCM_59_03": 1,
  //   "Magna_Face_TCM_74": 1,
  //   "Magna_Face_TCM_81": 1,
  //   "Magna_Face_TCM_82": 1,
  //   "Magna_Face_TCM_85": 1,
  //   "Magna_Face_TCM_88": 1,
  //   "Magna_Face_TCM_89": 1,
  //   "Magna_Face_TCM_91": 1,
  //   "Magna_Face_TCM_92": 1,
  //   "Magna_Face_TCM_94": 1,
  //   "Magna_Face_TCM_97": 1,
  //   "Wesley_Hair_Rodrigo_Hair": 0,
  //   "Wesley_Hair_Human_Body": 1,
  //   "Wesley_Hair_Robotic Arm 03": 1,
  //   "Wesley_Hair_Robotic Arm 05": 1,
  //   "Wesley_Hair_Magna body": 1,
  //   "Wesley_Hair_HA_None": 1,
  //   "Wesley_Hair_HAM_B1_22": 0,
  //   "Wesley_Hair_HAM_B1_31": 0,
  //   "Wesley_Hair_HAM_B1_32": 0,
  //   "Wesley_Hair_HAM_B2_14": 1,
  //   "Wesley_Hair_HAM_B2_20": 0,
  //   "Wesley_Hair_HAM_B3_05": 0,
  //   "Wesley_Hair_HAM_B3_06": 0,
  //   "Wesley_Hair_HAM_B3_08": 0,
  //   "Wesley_Hair_HAM_B3_09": 1,
  //   "Wesley_Hair_HAM_B3_11": 0,
  //   "Wesley_Hair_HAM_B3_22": 1,
  //   "Wesley_Hair_HAM_B4_01": 0,
  //   "Wesley_Hair_HAM_B4_02": 1,
  //   "Wesley_Hair_HAM_B4_05": 1,
  //   "Wesley_Hair_HAM_B4_06": 0,
  //   "Wesley_Hair_TCM_Default": 1,
  //   "Wesley_Hair_TCM_00": 1,
  //   "Wesley_Hair_TCM_07": 1,
  //   "Wesley_Hair_TCM_27": 1,
  //   "Wesley_Hair_TCM_40": 1,
  //   "Wesley_Hair_TCM_55": 1,
  //   "Wesley_Hair_TCM_59_01": 1,
  //   "Wesley_Hair_TCM_59_02": 1,
  //   "Wesley_Hair_TCM_59_03": 1,
  //   "Wesley_Hair_TCM_74": 1,
  //   "Wesley_Hair_TCM_81": 1,
  //   "Wesley_Hair_TCM_82": 1,
  //   "Wesley_Hair_TCM_85": 1,
  //   "Wesley_Hair_TCM_88": 1,
  //   "Wesley_Hair_TCM_89": 1,
  //   "Wesley_Hair_TCM_91": 1,
  //   "Wesley_Hair_TCM_92": 1,
  //   "Wesley_Hair_TCM_94": 1,
  //   "Wesley_Hair_TCM_97": 1,
  //   "Rodrigo_Hair_Human_Body": 1,
  //   "Rodrigo_Hair_Robotic Arm 03": 1,
  //   "Rodrigo_Hair_Robotic Arm 05": 1,
  //   "Rodrigo_Hair_Magna body": 1,
  //   "Rodrigo_Hair_HA_None": 1,
  //   "Rodrigo_Hair_HAM_B1_22": 0,
  //   "Rodrigo_Hair_HAM_B1_31": 1,
  //   "Rodrigo_Hair_HAM_B1_32": 0,
  //   "Rodrigo_Hair_HAM_B2_14": 1,
  //   "Rodrigo_Hair_HAM_B2_20": 1,
  //   "Rodrigo_Hair_HAM_B3_05": 0,
  //   "Rodrigo_Hair_HAM_B3_06": 1,
  //   "Rodrigo_Hair_HAM_B3_08": 0,
  //   "Rodrigo_Hair_HAM_B3_09": 0,
  //   "Rodrigo_Hair_HAM_B3_11": 0,
  //   "Rodrigo_Hair_HAM_B3_22": 1,
  //   "Rodrigo_Hair_HAM_B4_01": 0,
  //   "Rodrigo_Hair_HAM_B4_02": 0,
  //   "Rodrigo_Hair_HAM_B4_05": 1,
  //   "Rodrigo_Hair_HAM_B4_06": 0,
  //   "Rodrigo_Hair_TCM_Default": 1,
  //   "Rodrigo_Hair_TCM_00": 1,
  //   "Rodrigo_Hair_TCM_07": 1,
  //   "Rodrigo_Hair_TCM_27": 1,
  //   "Rodrigo_Hair_TCM_40": 1,
  //   "Rodrigo_Hair_TCM_55": 1,
  //   "Rodrigo_Hair_TCM_59_01": 1,
  //   "Rodrigo_Hair_TCM_59_02": 1,
  //   "Rodrigo_Hair_TCM_59_03": 1,
  //   "Rodrigo_Hair_TCM_74": 1,
  //   "Rodrigo_Hair_TCM_81": 1,
  //   "Rodrigo_Hair_TCM_82": 1,
  //   "Rodrigo_Hair_TCM_85": 1,
  //   "Rodrigo_Hair_TCM_88": 1,
  //   "Rodrigo_Hair_TCM_89": 1,
  //   "Rodrigo_Hair_TCM_91": 1,
  //   "Rodrigo_Hair_TCM_92": 1,
  //   "Rodrigo_Hair_TCM_94": 1,
  //   "Rodrigo_Hair_TCM_97": 1,
  //   "Human_Body_Robotic Arm 03": 0,
  //   "Human_Body_Robotic Arm 05": 0,
  //   "Human_Body_Magna body": 0,
  //   "Human_Body_HA_None": 1,
  //   "Human_Body_HAM_B1_22": 1,
  //   "Human_Body_HAM_B1_31": 1,
  //   "Human_Body_HAM_B1_32": 1,
  //   "Human_Body_HAM_B2_14": 1,
  //   "Human_Body_HAM_B2_20": 1,
  //   "Human_Body_HAM_B3_05": 1,
  //   "Human_Body_HAM_B3_06": 1,
  //   "Human_Body_HAM_B3_08": 1,
  //   "Human_Body_HAM_B3_09": 1,
  //   "Human_Body_HAM_B3_11": 1,
  //   "Human_Body_HAM_B3_22": 1,
  //   "Human_Body_HAM_B4_01": 1,
  //   "Human_Body_HAM_B4_02": 1,
  //   "Human_Body_HAM_B4_05": 1,
  //   "Human_Body_HAM_B4_06": 1,
  //   "Human_Body_TCM_Default": 1,
  //   "Human_Body_TCM_00": 1,
  //   "Human_Body_TCM_07": 1,
  //   "Human_Body_TCM_27": 1,
  //   "Human_Body_TCM_40": 1,
  //   "Human_Body_TCM_55": 1,
  //   "Human_Body_TCM_59_01": 1,
  //   "Human_Body_TCM_59_02": 1,
  //   "Human_Body_TCM_59_03": 1,
  //   "Human_Body_TCM_74": 1,
  //   "Human_Body_TCM_81": 1,
  //   "Human_Body_TCM_82": 1,
  //   "Human_Body_TCM_85": 1,
  //   "Human_Body_TCM_88": 1,
  //   "Human_Body_TCM_89": 1,
  //   "Human_Body_TCM_91": 1,
  //   "Human_Body_TCM_92": 1,
  //   "Human_Body_TCM_94": 1,
  //   "Human_Body_TCM_97": 1,
  //   "Robotic Arm 03_Robotic Arm 05": 0,
  //   "Robotic Arm 03_Magna body": 0,
  //   "Robotic Arm 03_HA_None": 1,
  //   "Robotic Arm 03_HAM_B1_22": 1,
  //   "Robotic Arm 03_HAM_B1_31": 1,
  //   "Robotic Arm 03_HAM_B1_32": 1,
  //   "Robotic Arm 03_HAM_B2_14": 1,
  //   "Robotic Arm 03_HAM_B2_20": 1,
  //   "Robotic Arm 03_HAM_B3_05": 1,
  //   "Robotic Arm 03_HAM_B3_06": 1,
  //   "Robotic Arm 03_HAM_B3_08": 1,
  //   "Robotic Arm 03_HAM_B3_09": 1,
  //   "Robotic Arm 03_HAM_B3_11": 1,
  //   "Robotic Arm 03_HAM_B3_22": 1,
  //   "Robotic Arm 03_HAM_B4_01": 1,
  //   "Robotic Arm 03_HAM_B4_02": 1,
  //   "Robotic Arm 03_HAM_B4_05": 1,
  //   "Robotic Arm 03_HAM_B4_06": 1,
  //   "Robotic Arm 03_TCM_Default": 1,
  //   "Robotic Arm 03_TCM_00": 1,
  //   "Robotic Arm 03_TCM_07": 1,
  //   "Robotic Arm 03_TCM_27": 0,
  //   "Robotic Arm 03_TCM_40": 0,
  //   "Robotic Arm 03_TCM_55": 1,
  //   "Robotic Arm 03_TCM_59_01": 1,
  //   "Robotic Arm 03_TCM_59_02": 1,
  //   "Robotic Arm 03_TCM_59_03": 0,
  //   "Robotic Arm 03_TCM_74": 0,
  //   "Robotic Arm 03_TCM_81": 0,
  //   "Robotic Arm 03_TCM_82": 0,
  //   "Robotic Arm 03_TCM_85": 1,
  //   "Robotic Arm 03_TCM_88": 0,
  //   "Robotic Arm 03_TCM_89": 1,
  //   "Robotic Arm 03_TCM_91": 0,
  //   "Robotic Arm 03_TCM_92": 0,
  //   "Robotic Arm 03_TCM_94": 1,
  //   "Robotic Arm 03_TCM_97": 0,
  //   "Robotic Arm 05_Magna body": 0,
  //   "Robotic Arm 05_HA_None": 1,
  //   "Robotic Arm 05_HAM_B1_22": 1,
  //   "Robotic Arm 05_HAM_B1_31": 1,
  //   "Robotic Arm 05_HAM_B1_32": 1,
  //   "Robotic Arm 05_HAM_B2_14": 1,
  //   "Robotic Arm 05_HAM_B2_20": 1,
  //   "Robotic Arm 05_HAM_B3_05": 1,
  //   "Robotic Arm 05_HAM_B3_06": 1,
  //   "Robotic Arm 05_HAM_B3_08": 1,
  //   "Robotic Arm 05_HAM_B3_09": 1,
  //   "Robotic Arm 05_HAM_B3_11": 1,
  //   "Robotic Arm 05_HAM_B3_22": 1,
  //   "Robotic Arm 05_HAM_B4_01": 1,
  //   "Robotic Arm 05_HAM_B4_02": 1,
  //   "Robotic Arm 05_HAM_B4_05": 1,
  //   "Robotic Arm 05_HAM_B4_06": 1,
  //   "Robotic Arm 05_TCM_Default": 1,
  //   "Robotic Arm 05_TCM_00": 0,
  //   "Robotic Arm 05_TCM_07": 1,
  //   "Robotic Arm 05_TCM_27": 0,
  //   "Robotic Arm 05_TCM_40": 0,
  //   "Robotic Arm 05_TCM_55": 1,
  //   "Robotic Arm 05_TCM_59_01": 1,
  //   "Robotic Arm 05_TCM_59_02": 0,
  //   "Robotic Arm 05_TCM_59_03": 0,
  //   "Robotic Arm 05_TCM_74": 0,
  //   "Robotic Arm 05_TCM_81": 0,
  //   "Robotic Arm 05_TCM_82": 0,
  //   "Robotic Arm 05_TCM_85": 0,
  //   "Robotic Arm 05_TCM_88": 0,
  //   "Robotic Arm 05_TCM_89": 1,
  //   "Robotic Arm 05_TCM_91": 0,
  //   "Robotic Arm 05_TCM_92": 0,
  //   "Robotic Arm 05_TCM_94": 0,
  //   "Robotic Arm 05_TCM_97": 0,
  //   "Magna body_HA_None": 1,
  //   "Magna body_HAM_B1_22": 1,
  //   "Magna body_HAM_B1_31": 1,
  //   "Magna body_HAM_B1_32": 1,
  //   "Magna body_HAM_B2_14": 1,
  //   "Magna body_HAM_B2_20": 1,
  //   "Magna body_HAM_B3_05": 1,
  //   "Magna body_HAM_B3_06": 1,
  //   "Magna body_HAM_B3_08": 1,
  //   "Magna body_HAM_B3_09": 1,
  //   "Magna body_HAM_B3_11": 1,
  //   "Magna body_HAM_B3_22": 1,
  //   "Magna body_HAM_B4_01": 1,
  //   "Magna body_HAM_B4_02": 1,
  //   "Magna body_HAM_B4_05": 1,
  //   "Magna body_HAM_B4_06": 1,
  //   "Magna body_TCM_Default": 1,
  //   "Magna body_TCM_00": 1,
  //   "Magna body_TCM_07": 1,
  //   "Magna body_TCM_27": 1,
  //   "Magna body_TCM_40": 1,
  //   "Magna body_TCM_55": 1,
  //   "Magna body_TCM_59_01": 1,
  //   "Magna body_TCM_59_02": 1,
  //   "Magna body_TCM_59_03": 1,
  //   "Magna body_TCM_74": 1,
  //   "Magna body_TCM_81": 1,
  //   "Magna body_TCM_82": 1,
  //   "Magna body_TCM_85": 1,
  //   "Magna body_TCM_88": 1,
  //   "Magna body_TCM_89": 1,
  //   "Magna body_TCM_91": 1,
  //   "Magna body_TCM_92": 1,
  //   "Magna body_TCM_94": 1,
  //   "Magna body_TCM_97": 1,
  //   "HA_None_HAM_B1_22": 0,
  //   "HA_None_HAM_B1_31": 0,
  //   "HA_None_HAM_B1_32": 0,
  //   "HA_None_HAM_B2_14": 0,
  //   "HA_None_HAM_B2_20": 0,
  //   "HA_None_HAM_B3_05": 0,
  //   "HA_None_HAM_B3_06": 0,
  //   "HA_None_HAM_B3_08": 0,
  //   "HA_None_HAM_B3_09": 0,
  //   "HA_None_HAM_B3_11": 0,
  //   "HA_None_HAM_B3_22": 0,
  //   "HA_None_HAM_B4_01": 0,
  //   "HA_None_HAM_B4_02": 0,
  //   "HA_None_HAM_B4_05": 0,
  //   "HA_None_HAM_B4_06": 0,
  //   "HA_None_TCM_Default": 1,
  //   "HA_None_TCM_00": 1,
  //   "HA_None_TCM_07": 1,
  //   "HA_None_TCM_27": 1,
  //   "HA_None_TCM_40": 1,
  //   "HA_None_TCM_55": 1,
  //   "HA_None_TCM_59_01": 1,
  //   "HA_None_TCM_59_02": 1,
  //   "HA_None_TCM_59_03": 1,
  //   "HA_None_TCM_74": 1,
  //   "HA_None_TCM_81": 1,
  //   "HA_None_TCM_82": 1,
  //   "HA_None_TCM_85": 1,
  //   "HA_None_TCM_88": 1,
  //   "HA_None_TCM_89": 1,
  //   "HA_None_TCM_91": 1,
  //   "HA_None_TCM_92": 1,
  //   "HA_None_TCM_94": 1,
  //   "HA_None_TCM_97": 1,
  //   "HAM_B1_22_HAM_B1_31": 0,
  //   "HAM_B1_22_HAM_B1_32": 0,
  //   "HAM_B1_22_HAM_B2_14": 0,
  //   "HAM_B1_22_HAM_B2_20": 0,
  //   "HAM_B1_22_HAM_B3_05": 0,
  //   "HAM_B1_22_HAM_B3_06": 0,
  //   "HAM_B1_22_HAM_B3_08": 0,
  //   "HAM_B1_22_HAM_B3_09": 0,
  //   "HAM_B1_22_HAM_B3_11": 0,
  //   "HAM_B1_22_HAM_B3_22": 0,
  //   "HAM_B1_22_HAM_B4_01": 0,
  //   "HAM_B1_22_HAM_B4_02": 0,
  //   "HAM_B1_22_HAM_B4_05": 0,
  //   "HAM_B1_22_HAM_B4_06": 0,
  //   "HAM_B1_22_TCM_Default": 1,
  //   "HAM_B1_22_TCM_00": 1,
  //   "HAM_B1_22_TCM_07": 1,
  //   "HAM_B1_22_TCM_27": 1,
  //   "HAM_B1_22_TCM_40": 1,
  //   "HAM_B1_22_TCM_55": 1,
  //   "HAM_B1_22_TCM_59_01": 1,
  //   "HAM_B1_22_TCM_59_02": 1,
  //   "HAM_B1_22_TCM_59_03": 1,
  //   "HAM_B1_22_TCM_74": 1,
  //   "HAM_B1_22_TCM_81": 0,
  //   "HAM_B1_22_TCM_82": 1,
  //   "HAM_B1_22_TCM_85": 1,
  //   "HAM_B1_22_TCM_88": 1,
  //   "HAM_B1_22_TCM_89": 1,
  //   "HAM_B1_22_TCM_91": 1,
  //   "HAM_B1_22_TCM_92": 1,
  //   "HAM_B1_22_TCM_94": 1,
  //   "HAM_B1_22_TCM_97": 1,
  //   "HAM_B1_31_HAM_B1_32": 0,
  //   "HAM_B1_31_HAM_B2_14": 0,
  //   "HAM_B1_31_HAM_B2_20": 0,
  //   "HAM_B1_31_HAM_B3_05": 0,
  //   "HAM_B1_31_HAM_B3_06": 0,
  //   "HAM_B1_31_HAM_B3_08": 0,
  //   "HAM_B1_31_HAM_B3_09": 0,
  //   "HAM_B1_31_HAM_B3_11": 0,
  //   "HAM_B1_31_HAM_B3_22": 0,
  //   "HAM_B1_31_HAM_B4_01": 0,
  //   "HAM_B1_31_HAM_B4_02": 0,
  //   "HAM_B1_31_HAM_B4_05": 0,
  //   "HAM_B1_31_HAM_B4_06": 0,
  //   "HAM_B1_31_TCM_Default": 1,
  //   "HAM_B1_31_TCM_00": 1,
  //   "HAM_B1_31_TCM_07": 1,
  //   "HAM_B1_31_TCM_27": 1,
  //   "HAM_B1_31_TCM_40": 1,
  //   "HAM_B1_31_TCM_55": 1,
  //   "HAM_B1_31_TCM_59_01": 1,
  //   "HAM_B1_31_TCM_59_02": 1,
  //   "HAM_B1_31_TCM_59_03": 1,
  //   "HAM_B1_31_TCM_74": 1,
  //   "HAM_B1_31_TCM_81": 1,
  //   "HAM_B1_31_TCM_82": 1,
  //   "HAM_B1_31_TCM_85": 1,
  //   "HAM_B1_31_TCM_88": 1,
  //   "HAM_B1_31_TCM_89": 1,
  //   "HAM_B1_31_TCM_91": 1,
  //   "HAM_B1_31_TCM_92": 1,
  //   "HAM_B1_31_TCM_94": 1,
  //   "HAM_B1_31_TCM_97": 1,
  //   "HAM_B1_32_HAM_B2_14": 0,
  //   "HAM_B1_32_HAM_B2_20": 0,
  //   "HAM_B1_32_HAM_B3_05": 0,
  //   "HAM_B1_32_HAM_B3_06": 0,
  //   "HAM_B1_32_HAM_B3_08": 0,
  //   "HAM_B1_32_HAM_B3_09": 0,
  //   "HAM_B1_32_HAM_B3_11": 0,
  //   "HAM_B1_32_HAM_B3_22": 0,
  //   "HAM_B1_32_HAM_B4_01": 0,
  //   "HAM_B1_32_HAM_B4_02": 0,
  //   "HAM_B1_32_HAM_B4_05": 0,
  //   "HAM_B1_32_HAM_B4_06": 0,
  //   "HAM_B1_32_TCM_Default": 1,
  //   "HAM_B1_32_TCM_00": 1,
  //   "HAM_B1_32_TCM_07": 1,
  //   "HAM_B1_32_TCM_27": 1,
  //   "HAM_B1_32_TCM_40": 0,
  //   "HAM_B1_32_TCM_55": 1,
  //   "HAM_B1_32_TCM_59_01": 1,
  //   "HAM_B1_32_TCM_59_02": 1,
  //   "HAM_B1_32_TCM_59_03": 1,
  //   "HAM_B1_32_TCM_74": 1,
  //   "HAM_B1_32_TCM_81": 0,
  //   "HAM_B1_32_TCM_82": 1,
  //   "HAM_B1_32_TCM_85": 0,
  //   "HAM_B1_32_TCM_88": 1,
  //   "HAM_B1_32_TCM_89": 1,
  //   "HAM_B1_32_TCM_91": 1,
  //   "HAM_B1_32_TCM_92": 1,
  //   "HAM_B1_32_TCM_94": 1,
  //   "HAM_B1_32_TCM_97": 1,
  //   "HAM_B2_14_HAM_B2_20": 0,
  //   "HAM_B2_14_HAM_B3_05": 0,
  //   "HAM_B2_14_HAM_B3_06": 0,
  //   "HAM_B2_14_HAM_B3_08": 0,
  //   "HAM_B2_14_HAM_B3_09": 0,
  //   "HAM_B2_14_HAM_B3_11": 0,
  //   "HAM_B2_14_HAM_B3_22": 0,
  //   "HAM_B2_14_HAM_B4_01": 0,
  //   "HAM_B2_14_HAM_B4_02": 0,
  //   "HAM_B2_14_HAM_B4_05": 0,
  //   "HAM_B2_14_HAM_B4_06": 0,
  //   "HAM_B2_14_TCM_Default": 1,
  //   "HAM_B2_14_TCM_00": 1,
  //   "HAM_B2_14_TCM_07": 1,
  //   "HAM_B2_14_TCM_27": 1,
  //   "HAM_B2_14_TCM_40": 1,
  //   "HAM_B2_14_TCM_55": 1,
  //   "HAM_B2_14_TCM_59_01": 1,
  //   "HAM_B2_14_TCM_59_02": 1,
  //   "HAM_B2_14_TCM_59_03": 1,
  //   "HAM_B2_14_TCM_74": 1,
  //   "HAM_B2_14_TCM_81": 1,
  //   "HAM_B2_14_TCM_82": 1,
  //   "HAM_B2_14_TCM_85": 1,
  //   "HAM_B2_14_TCM_88": 1,
  //   "HAM_B2_14_TCM_89": 1,
  //   "HAM_B2_14_TCM_91": 1,
  //   "HAM_B2_14_TCM_92": 1,
  //   "HAM_B2_14_TCM_94": 1,
  //   "HAM_B2_14_TCM_97": 1,
  //   "HAM_B2_20_HAM_B2_20": 0,
  //   "HAM_B2_20_HAM_B3_05": 0,
  //   "HAM_B2_20_HAM_B3_06": 0,
  //   "HAM_B2_20_HAM_B3_08": 0,
  //   "HAM_B2_20_HAM_B3_09": 0,
  //   "HAM_B2_20_HAM_B3_11": 0,
  //   "HAM_B2_20_HAM_B3_22": 0,
  //   "HAM_B2_20_HAM_B4_01": 0,
  //   "HAM_B2_20_HAM_B4_02": 0,
  //   "HAM_B2_20_HAM_B4_05": 0,
  //   "HAM_B2_20_HAM_B4_06": 0,
  //   "HAM_B2_20_TCM_Default": 1,
  //   "HAM_B2_20_TCM_00": 1,
  //   "HAM_B2_20_TCM_07": 1,
  //   "HAM_B2_20_TCM_27": 1,
  //   "HAM_B2_20_TCM_40": 1,
  //   "HAM_B2_20_TCM_55": 1,
  //   "HAM_B2_20_TCM_59_01": 1,
  //   "HAM_B2_20_TCM_59_02": 1,
  //   "HAM_B2_20_TCM_59_03": 1,
  //   "HAM_B2_20_TCM_74": 1,
  //   "HAM_B2_20_TCM_81": 0,
  //   "HAM_B2_20_TCM_82": 1,
  //   "HAM_B2_20_TCM_85": 1,
  //   "HAM_B2_20_TCM_88": 1,
  //   "HAM_B2_20_TCM_89": 1,
  //   "HAM_B2_20_TCM_91": 1,
  //   "HAM_B2_20_TCM_92": 1,
  //   "HAM_B2_20_TCM_94": 1,
  //   "HAM_B2_20_TCM_97": 1,
  //   "HAM_B3_05_HAM_B2_20": 0,
  //   "HAM_B3_05_HAM_B3_06": 0,
  //   "HAM_B3_05_HAM_B3_08": 0,
  //   "HAM_B3_05_HAM_B3_09": 0,
  //   "HAM_B3_05_HAM_B3_11": 0,
  //   "HAM_B3_05_HAM_B3_22": 0,
  //   "HAM_B3_05_HAM_B4_01": 0,
  //   "HAM_B3_05_HAM_B4_02": 0,
  //   "HAM_B3_05_HAM_B4_05": 0,
  //   "HAM_B3_05_HAM_B4_06": 0,
  //   "HAM_B3_05_TCM_Default": 1,
  //   "HAM_B3_05_TCM_00": 1,
  //   "HAM_B3_05_TCM_07": 1,
  //   "HAM_B3_05_TCM_27": 1,
  //   "HAM_B3_05_TCM_40": 0,
  //   "HAM_B3_05_TCM_55": 1,
  //   "HAM_B3_05_TCM_59_01": 1,
  //   "HAM_B3_05_TCM_59_02": 1,
  //   "HAM_B3_05_TCM_59_03": 1,
  //   "HAM_B3_05_TCM_74": 1,
  //   "HAM_B3_05_TCM_81": 1,
  //   "HAM_B3_05_TCM_82": 1,
  //   "HAM_B3_05_TCM_85": 0,
  //   "HAM_B3_05_TCM_88": 0,
  //   "HAM_B3_05_TCM_89": 1,
  //   "HAM_B3_05_TCM_91": 1,
  //   "HAM_B3_05_TCM_92": 1,
  //   "HAM_B3_05_TCM_94": 1,
  //   "HAM_B3_05_TCM_97": 1,
  //   "HAM_B3_06_HAM_B2_20": 0,
  //   "HAM_B3_06_HAM_B3_08": 0,
  //   "HAM_B3_06_HAM_B3_09": 0,
  //   "HAM_B3_06_HAM_B3_11": 0,
  //   "HAM_B3_06_HAM_B3_22": 0,
  //   "HAM_B3_06_HAM_B4_01": 0,
  //   "HAM_B3_06_HAM_B4_02": 0,
  //   "HAM_B3_06_HAM_B4_05": 0,
  //   "HAM_B3_06_HAM_B4_06": 0,
  //   "HAM_B3_06_TCM_Default": 1,
  //   "HAM_B3_06_TCM_00": 1,
  //   "HAM_B3_06_TCM_07": 1,
  //   "HAM_B3_06_TCM_27": 1,
  //   "HAM_B3_06_TCM_40": 1,
  //   "HAM_B3_06_TCM_55": 1,
  //   "HAM_B3_06_TCM_59_01": 1,
  //   "HAM_B3_06_TCM_59_02": 1,
  //   "HAM_B3_06_TCM_59_03": 1,
  //   "HAM_B3_06_TCM_74": 1,
  //   "HAM_B3_06_TCM_81": 1,
  //   "HAM_B3_06_TCM_82": 1,
  //   "HAM_B3_06_TCM_85": 1,
  //   "HAM_B3_06_TCM_88": 1,
  //   "HAM_B3_06_TCM_89": 1,
  //   "HAM_B3_06_TCM_91": 1,
  //   "HAM_B3_06_TCM_92": 1,
  //   "HAM_B3_06_TCM_94": 1,
  //   "HAM_B3_06_TCM_97": 1,
  //   "HAM_B3_08_HAM_B2_20": 0,
  //   "HAM_B3_08_HAM_B3_09": 0,
  //   "HAM_B3_08_HAM_B3_11": 0,
  //   "HAM_B3_08_HAM_B3_22": 0,
  //   "HAM_B3_08_HAM_B4_01": 0,
  //   "HAM_B3_08_HAM_B4_02": 0,
  //   "HAM_B3_08_HAM_B4_05": 0,
  //   "HAM_B3_08_HAM_B4_06": 0,
  //   "HAM_B3_08_TCM_Default": 1,
  //   "HAM_B3_08_TCM_00": 1,
  //   "HAM_B3_08_TCM_07": 1,
  //   "HAM_B3_08_TCM_27": 1,
  //   "HAM_B3_08_TCM_40": 1,
  //   "HAM_B3_08_TCM_55": 1,
  //   "HAM_B3_08_TCM_59_01": 1,
  //   "HAM_B3_08_TCM_59_02": 1,
  //   "HAM_B3_08_TCM_59_03": 1,
  //   "HAM_B3_08_TCM_74": 1,
  //   "HAM_B3_08_TCM_81": 0,
  //   "HAM_B3_08_TCM_82": 1,
  //   "HAM_B3_08_TCM_85": 1,
  //   "HAM_B3_08_TCM_88": 1,
  //   "HAM_B3_08_TCM_89": 1,
  //   "HAM_B3_08_TCM_91": 1,
  //   "HAM_B3_08_TCM_92": 1,
  //   "HAM_B3_08_TCM_94": 1,
  //   "HAM_B3_08_TCM_97": 1,
  //   "HAM_B3_09_HAM_B2_20": 0,
  //   "HAM_B3_09_HAM_B3_11": 0,
  //   "HAM_B3_09_HAM_B3_22": 0,
  //   "HAM_B3_09_HAM_B4_01": 0,
  //   "HAM_B3_09_HAM_B4_02": 0,
  //   "HAM_B3_09_HAM_B4_05": 0,
  //   "HAM_B3_09_HAM_B4_06": 0,
  //   "HAM_B3_09_TCM_Default": 1,
  //   "HAM_B3_09_TCM_00": 1,
  //   "HAM_B3_09_TCM_07": 1,
  //   "HAM_B3_09_TCM_27": 1,
  //   "HAM_B3_09_TCM_40": 0,
  //   "HAM_B3_09_TCM_55": 1,
  //   "HAM_B3_09_TCM_59_01": 0,
  //   "HAM_B3_09_TCM_59_02": 0,
  //   "HAM_B3_09_TCM_59_03": 0,
  //   "HAM_B3_09_TCM_74": 0,
  //   "HAM_B3_09_TCM_81": 0,
  //   "HAM_B3_09_TCM_82": 0,
  //   "HAM_B3_09_TCM_85": 0,
  //   "HAM_B3_09_TCM_88": 1,
  //   "HAM_B3_09_TCM_89": 0,
  //   "HAM_B3_09_TCM_91": 1,
  //   "HAM_B3_09_TCM_92": 1,
  //   "HAM_B3_09_TCM_94": 1,
  //   "HAM_B3_09_TCM_97": 0,
  //   "HAM_B3_11_HAM_B2_20": 0,
  //   "HAM_B3_11_HAM_B3_22": 0,
  //   "HAM_B3_11_HAM_B4_01": 0,
  //   "HAM_B3_11_HAM_B4_02": 0,
  //   "HAM_B3_11_HAM_B4_05": 0,
  //   "HAM_B3_11_HAM_B4_06": 0,
  //   "HAM_B3_11_TCM_Default": 1,
  //   "HAM_B3_11_TCM_00": 1,
  //   "HAM_B3_11_TCM_07": 1,
  //   "HAM_B3_11_TCM_27": 1,
  //   "HAM_B3_11_TCM_40": 1,
  //   "HAM_B3_11_TCM_55": 1,
  //   "HAM_B3_11_TCM_59_01": 1,
  //   "HAM_B3_11_TCM_59_02": 1,
  //   "HAM_B3_11_TCM_59_03": 1,
  //   "HAM_B3_11_TCM_74": 1,
  //   "HAM_B3_11_TCM_81": 1,
  //   "HAM_B3_11_TCM_82": 1,
  //   "HAM_B3_11_TCM_85": 1,
  //   "HAM_B3_11_TCM_88": 1,
  //   "HAM_B3_11_TCM_89": 1,
  //   "HAM_B3_11_TCM_91": 1,
  //   "HAM_B3_11_TCM_92": 1,
  //   "HAM_B3_11_TCM_94": 1,
  //   "HAM_B3_11_TCM_97": 1,
  //   "HAM_B3_22_HAM_B2_20": 0,
  //   "HAM_B3_22_HAM_B4_01": 0,
  //   "HAM_B3_22_HAM_B4_02": 0,
  //   "HAM_B3_22_HAM_B4_05": 0,
  //   "HAM_B3_22_HAM_B4_06": 0,
  //   "HAM_B3_22_TCM_Default": 1,
  //   "HAM_B3_22_TCM_00": 1,
  //   "HAM_B3_22_TCM_07": 1,
  //   "HAM_B3_22_TCM_27": 1,
  //   "HAM_B3_22_TCM_40": 1,
  //   "HAM_B3_22_TCM_55": 1,
  //   "HAM_B3_22_TCM_59_01": 1,
  //   "HAM_B3_22_TCM_59_02": 1,
  //   "HAM_B3_22_TCM_59_03": 1,
  //   "HAM_B3_22_TCM_74": 1,
  //   "HAM_B3_22_TCM_81": 1,
  //   "HAM_B3_22_TCM_82": 1,
  //   "HAM_B3_22_TCM_85": 1,
  //   "HAM_B3_22_TCM_88": 1,
  //   "HAM_B3_22_TCM_89": 1,
  //   "HAM_B3_22_TCM_91": 1,
  //   "HAM_B3_22_TCM_92": 1,
  //   "HAM_B3_22_TCM_94": 1,
  //   "HAM_B3_22_TCM_97": 1,
  //   "HAM_B4_01_HAM_B2_20": 0,
  //   "HAM_B4_01_HAM_B4_02": 0,
  //   "HAM_B4_01_HAM_B4_05": 0,
  //   "HAM_B4_01_HAM_B4_06": 0,
  //   "HAM_B4_01_TCM_Default": 1,
  //   "HAM_B4_01_TCM_00": 1,
  //   "HAM_B4_01_TCM_07": 1,
  //   "HAM_B4_01_TCM_27": 1,
  //   "HAM_B4_01_TCM_40": 1,
  //   "HAM_B4_01_TCM_55": 1,
  //   "HAM_B4_01_TCM_59_01": 1,
  //   "HAM_B4_01_TCM_59_02": 1,
  //   "HAM_B4_01_TCM_59_03": 1,
  //   "HAM_B4_01_TCM_74": 1,
  //   "HAM_B4_01_TCM_81": 0,
  //   "HAM_B4_01_TCM_82": 1,
  //   "HAM_B4_01_TCM_85": 1,
  //   "HAM_B4_01_TCM_88": 1,
  //   "HAM_B4_01_TCM_89": 1,
  //   "HAM_B4_01_TCM_91": 1,
  //   "HAM_B4_01_TCM_92": 1,
  //   "HAM_B4_01_TCM_94": 1,
  //   "HAM_B4_01_TCM_97": 1,
  //   "HAM_B4_02_HAM_B2_20": 0,
  //   "HAM_B4_02_HAM_B4_05": 0,
  //   "HAM_B4_02_HAM_B4_06": 0,
  //   "HAM_B4_02_TCM_Default": 1,
  //   "HAM_B4_02_TCM_00": 1,
  //   "HAM_B4_02_TCM_07": 1,
  //   "HAM_B4_02_TCM_27": 1,
  //   "HAM_B4_02_TCM_40": 1,
  //   "HAM_B4_02_TCM_55": 1,
  //   "HAM_B4_02_TCM_59_01": 1,
  //   "HAM_B4_02_TCM_59_02": 1,
  //   "HAM_B4_02_TCM_59_03": 1,
  //   "HAM_B4_02_TCM_74": 1,
  //   "HAM_B4_02_TCM_81": 1,
  //   "HAM_B4_02_TCM_82": 1,
  //   "HAM_B4_02_TCM_85": 1,
  //   "HAM_B4_02_TCM_88": 1,
  //   "HAM_B4_02_TCM_89": 1,
  //   "HAM_B4_02_TCM_91": 1,
  //   "HAM_B4_02_TCM_92": 1,
  //   "HAM_B4_02_TCM_94": 1,
  //   "HAM_B4_02_TCM_97": 1,
  //   "HAM_B4_05_HAM_B2_20": 0,
  //   "HAM_B4_05_HAM_B4_06": 0,
  //   "HAM_B4_05_TCM_Default": 1,
  //   "HAM_B4_05_TCM_00": 1,
  //   "HAM_B4_05_TCM_07": 1,
  //   "HAM_B4_05_TCM_27": 1,
  //   "HAM_B4_05_TCM_40": 1,
  //   "HAM_B4_05_TCM_55": 1,
  //   "HAM_B4_05_TCM_59_01": 1,
  //   "HAM_B4_05_TCM_59_02": 1,
  //   "HAM_B4_05_TCM_59_03": 1,
  //   "HAM_B4_05_TCM_74": 1,
  //   "HAM_B4_05_TCM_81": 1,
  //   "HAM_B4_05_TCM_82": 1,
  //   "HAM_B4_05_TCM_85": 1,
  //   "HAM_B4_05_TCM_88": 1,
  //   "HAM_B4_05_TCM_89": 1,
  //   "HAM_B4_05_TCM_91": 1,
  //   "HAM_B4_05_TCM_92": 1,
  //   "HAM_B4_05_TCM_94": 1,
  //   "HAM_B4_05_TCM_97": 1,
  //   "HAM_B4_06_HAM_B2_20": 0,
  //   "HAM_B4_06_TCM_Default": 1,
  //   "HAM_B4_06_TCM_00": 1,
  //   "HAM_B4_06_TCM_07": 1,
  //   "HAM_B4_06_TCM_27": 1,
  //   "HAM_B4_06_TCM_40": 1,
  //   "HAM_B4_06_TCM_55": 1,
  //   "HAM_B4_06_TCM_59_01": 1,
  //   "HAM_B4_06_TCM_59_02": 1,
  //   "HAM_B4_06_TCM_59_03": 1,
  //   "HAM_B4_06_TCM_74": 1,
  //   "HAM_B4_06_TCM_81": 1,
  //   "HAM_B4_06_TCM_82": 1,
  //   "HAM_B4_06_TCM_85": 1,
  //   "HAM_B4_06_TCM_88": 1,
  //   "HAM_B4_06_TCM_89": 1,
  //   "HAM_B4_06_TCM_91": 1,
  //   "HAM_B4_06_TCM_92": 1,
  //   "HAM_B4_06_TCM_94": 1,
  //   "HAM_B4_06_TCM_97": 1,
  //   "TCM_Default_HAM_B2_20": 1,
  //   "TCM_Default_TCM_00": 0,
  //   "TCM_Default_TCM_07": 0,
  //   "TCM_Default_TCM_27": 0,
  //   "TCM_Default_TCM_40": 0,
  //   "TCM_Default_TCM_55": 0,
  //   "TCM_Default_TCM_59_01": 0,
  //   "TCM_Default_TCM_59_02": 0,
  //   "TCM_Default_TCM_59_03": 0,
  //   "TCM_Default_TCM_74": 0,
  //   "TCM_Default_TCM_81": 0,
  //   "TCM_Default_TCM_82": 0,
  //   "TCM_Default_TCM_85": 0,
  //   "TCM_Default_TCM_88": 0,
  //   "TCM_Default_TCM_89": 0,
  //   "TCM_Default_TCM_91": 0,
  //   "TCM_Default_TCM_92": 0,
  //   "TCM_Default_TCM_94": 0,
  //   "TCM_Default_TCM_97": 0,
  //   "TCM_00_HAM_B2_20": 1,
  //   "TCM_00_TCM_07": 0,
  //   "TCM_00_TCM_27": 0,
  //   "TCM_00_TCM_40": 0,
  //   "TCM_00_TCM_55": 0,
  //   "TCM_00_TCM_59_01": 0,
  //   "TCM_00_TCM_59_02": 0,
  //   "TCM_00_TCM_59_03": 0,
  //   "TCM_00_TCM_74": 0,
  //   "TCM_00_TCM_81": 0,
  //   "TCM_00_TCM_82": 0,
  //   "TCM_00_TCM_85": 0,
  //   "TCM_00_TCM_88": 0,
  //   "TCM_00_TCM_89": 0,
  //   "TCM_00_TCM_91": 0,
  //   "TCM_00_TCM_92": 0,
  //   "TCM_00_TCM_94": 0,
  //   "TCM_00_TCM_97": 0,
  //   "TCM_07_HAM_B2_20": 1,
  //   "TCM_07_TCM_27": 0,
  //   "TCM_07_TCM_40": 0,
  //   "TCM_07_TCM_55": 0,
  //   "TCM_07_TCM_59_01": 0,
  //   "TCM_07_TCM_59_02": 0,
  //   "TCM_07_TCM_59_03": 0,
  //   "TCM_07_TCM_74": 0,
  //   "TCM_07_TCM_81": 0,
  //   "TCM_07_TCM_82": 0,
  //   "TCM_07_TCM_85": 0,
  //   "TCM_07_TCM_88": 0,
  //   "TCM_07_TCM_89": 0,
  //   "TCM_07_TCM_91": 0,
  //   "TCM_07_TCM_92": 0,
  //   "TCM_07_TCM_94": 0,
  //   "TCM_07_TCM_97": 0,
  //   "TCM_27_HAM_B2_20": 1,
  //   "TCM_27_TCM_40": 0,
  //   "TCM_27_TCM_55": 0,
  //   "TCM_27_TCM_59_01": 0,
  //   "TCM_27_TCM_59_02": 0,
  //   "TCM_27_TCM_59_03": 0,
  //   "TCM_27_TCM_74": 0,
  //   "TCM_27_TCM_81": 0,
  //   "TCM_27_TCM_82": 0,
  //   "TCM_27_TCM_85": 0,
  //   "TCM_27_TCM_88": 0,
  //   "TCM_27_TCM_89": 0,
  //   "TCM_27_TCM_91": 0,
  //   "TCM_27_TCM_92": 0,
  //   "TCM_27_TCM_94": 0,
  //   "TCM_27_TCM_97": 0,
  //   "TCM_40_HAM_B2_20": 1,
  //   "TCM_40_TCM_55": 0,
  //   "TCM_40_TCM_59_01": 0,
  //   "TCM_40_TCM_59_02": 0,
  //   "TCM_40_TCM_59_03": 0,
  //   "TCM_40_TCM_74": 0,
  //   "TCM_40_TCM_81": 0,
  //   "TCM_40_TCM_82": 0,
  //   "TCM_40_TCM_85": 0,
  //   "TCM_40_TCM_88": 0,
  //   "TCM_40_TCM_89": 0,
  //   "TCM_40_TCM_91": 0,
  //   "TCM_40_TCM_92": 0,
  //   "TCM_40_TCM_94": 0,
  //   "TCM_40_TCM_97": 0,
  //   "TCM_55_HAM_B2_20": 1,
  //   "TCM_55_TCM_59_01": 0,
  //   "TCM_55_TCM_59_02": 0,
  //   "TCM_55_TCM_59_03": 0,
  //   "TCM_55_TCM_74": 0,
  //   "TCM_55_TCM_81": 0,
  //   "TCM_55_TCM_82": 0,
  //   "TCM_55_TCM_85": 0,
  //   "TCM_55_TCM_88": 0,
  //   "TCM_55_TCM_89": 0,
  //   "TCM_55_TCM_91": 0,
  //   "TCM_55_TCM_92": 0,
  //   "TCM_55_TCM_94": 0,
  //   "TCM_55_TCM_97": 0,
  //   "TCM_59_01_HAM_B2_20": 1,
  //   "TCM_59_01_TCM_59_02": 0,
  //   "TCM_59_01_TCM_59_03": 0,
  //   "TCM_59_01_TCM_74": 0,
  //   "TCM_59_01_TCM_81": 0,
  //   "TCM_59_01_TCM_82": 0,
  //   "TCM_59_01_TCM_85": 0,
  //   "TCM_59_01_TCM_88": 0,
  //   "TCM_59_01_TCM_89": 0,
  //   "TCM_59_01_TCM_91": 0,
  //   "TCM_59_01_TCM_92": 0,
  //   "TCM_59_01_TCM_94": 0,
  //   "TCM_59_01_TCM_97": 0,
  //   "TCM_59_02_HAM_B2_20": 1,
  //   "TCM_59_02_TCM_59_03": 0,
  //   "TCM_59_02_TCM_74": 0,
  //   "TCM_59_02_TCM_81": 0,
  //   "TCM_59_02_TCM_82": 0,
  //   "TCM_59_02_TCM_85": 0,
  //   "TCM_59_02_TCM_88": 0,
  //   "TCM_59_02_TCM_89": 0,
  //   "TCM_59_02_TCM_91": 0,
  //   "TCM_59_02_TCM_92": 0,
  //   "TCM_59_02_TCM_94": 0,
  //   "TCM_59_02_TCM_97": 0,
  //   "TCM_59_03_HAM_B2_20": 1,
  //   "TCM_59_03_TCM_74": 0,
  //   "TCM_59_03_TCM_81": 0,
  //   "TCM_59_03_TCM_82": 0,
  //   "TCM_59_03_TCM_85": 0,
  //   "TCM_59_03_TCM_88": 0,
  //   "TCM_59_03_TCM_89": 0,
  //   "TCM_59_03_TCM_91": 0,
  //   "TCM_59_03_TCM_92": 0,
  //   "TCM_59_03_TCM_94": 0,
  //   "TCM_59_03_TCM_97": 0,
  //   "TCM_74_HAM_B2_20": 1,
  //   "TCM_74_TCM_81": 0,
  //   "TCM_74_TCM_82": 0,
  //   "TCM_74_TCM_85": 0,
  //   "TCM_74_TCM_88": 0,
  //   "TCM_74_TCM_89": 0,
  //   "TCM_74_TCM_91": 0,
  //   "TCM_74_TCM_92": 0,
  //   "TCM_74_TCM_94": 0,
  //   "TCM_74_TCM_97": 0,
  //   "TCM_81_HAM_B2_20": 0,
  //   "TCM_81_TCM_82": 0,
  //   "TCM_81_TCM_85": 0,
  //   "TCM_81_TCM_88": 0,
  //   "TCM_81_TCM_89": 0,
  //   "TCM_81_TCM_91": 0,
  //   "TCM_81_TCM_92": 0,
  //   "TCM_81_TCM_94": 0,
  //   "TCM_81_TCM_97": 0,
  //   "TCM_82_HAM_B2_20": 1,
  //   "TCM_82_TCM_85": 0,
  //   "TCM_82_TCM_88": 0,
  //   "TCM_82_TCM_89": 0,
  //   "TCM_82_TCM_91": 0,
  //   "TCM_82_TCM_92": 0,
  //   "TCM_82_TCM_94": 0,
  //   "TCM_82_TCM_97": 0,
  //   "TCM_85_HAM_B2_20": 1,
  //   "TCM_85_TCM_88": 0,
  //   "TCM_85_TCM_89": 0,
  //   "TCM_85_TCM_91": 0,
  //   "TCM_85_TCM_92": 0,
  //   "TCM_85_TCM_94": 0,
  //   "TCM_85_TCM_97": 0,
  //   "TCM_88_HAM_B2_20": 1,
  //   "TCM_88_TCM_89": 0,
  //   "TCM_88_TCM_91": 0,
  //   "TCM_88_TCM_92": 0,
  //   "TCM_88_TCM_94": 0,
  //   "TCM_88_TCM_97": 0,
  //   "TCM_89_HAM_B2_20": 1,
  //   "TCM_89_TCM_91": 0,
  //   "TCM_89_TCM_92": 0,
  //   "TCM_89_TCM_94": 0,
  //   "TCM_89_TCM_97": 0,
  //   "TCM_91_HAM_B2_20": 1,
  //   "TCM_91_TCM_92": 0,
  //   "TCM_91_TCM_94": 0,
  //   "TCM_91_TCM_97": 0,
  //   "TCM_92_HAM_B2_20": 1,
  //   "TCM_92_TCM_94": 0,
  //   "TCM_92_TCM_97": 0,
  //   "TCM_94_HAM_B2_20": 1,
  //   "TCM_94_TCM_97": 0,
  //   "TCM_97_HAM_B2_20": 1
  //   }
};
