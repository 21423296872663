import React, { useState } from 'react';

export const Reset = ({ showResetBtn, setShowCharacterSelection, resetState, controlsRef }) => {

  const [showResetModal, setShowResetModal] = useState(false);

  const handleResetModal = (action) => {
    if (action) {
      resetState();
    }
    setShowResetModal(false);
    setShowCharacterSelection(true);
  }
  const handleResetButtonClick = () => {
    setShowResetModal(true);
    setShowCharacterSelection(false);
  }
  return (
    <>
      {showResetBtn && (
        <img
          className='resetButton'
          onClick={() => handleResetButtonClick()}
          src='\Assets\unity-assets\reset-button.png'
          alt='icon'
        />
      )}
      {showResetModal && (
        <div className='inner-overlay'>
          Are you sure you want to reset you character?
          <img
            onClick={() =>{ 
              handleResetModal(true)
              controlsRef.current?.reset()
            }}
            src='\Assets\unity-assets\proceed.png'
            alt='icon'
          />
          <img
            onClick={() => handleResetModal(false)}
            src='\Assets\unity-assets\cancel.png'
            alt='icon'
          />
        </div>
      )}
    </>
  );
};
