import React, { useState } from "react";

export const Background = () => {
  return (
    <>
      {/* <div className='unity-heading'>Choose Your Character</div> */}
      <div className="backgroundGrad">
        {/* <img
          className='unityBG'
          // src='\Assets\unity-assets\unity-background.webp'
        /> */}
      </div>
    </>
  );
};
