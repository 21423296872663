import React from 'react';
import Builder from '../components/Builder/Index';
// import dynamic from 'next/dynamic';

// const Builder = dynamic(() => import('@/components/Builder/Index'), {
//   ssr: false
// });
const ReadyTrade = ({ isMobileView }: any) => {
  return (
    <>
      {isMobileView ? (
        <section className='mob-ready-trader-section'>
          <div className='liner-shade'>
            <div className='container' id='ReadyNFT'>
              <h3>Ready Trader, Go!</h3>
              <p>
                Customize your avatar completely with our rich and detailed
                avatar builder. <br />
                You have to trade in style, right?
              </p>

              <div className='img-box'>
                <Builder />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className='ready-trader-section'>
          <div className='liner-shade'>
            <div className='container' id='ReadyNFT'>
              <h3>Ready Trader, Go!</h3>
              <p style={{ marginBottom: 48 }}>
                Customize your avatar completely with our rich and detailed
                avatar builder. You have to trade in style, right?
              </p>

              <div
                className='img-box'
                style={{
                  zoom: 0.8
                }}
              >
                <div className='glow-1'></div>
                <div className='glow-2'></div>
                <Builder />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ReadyTrade;
