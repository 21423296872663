import React from 'react';
import FooterSocialLinksData from '@/components/Footer/FooterSocialLinks';

const Footer = ({ isMobileView }: any) => {
  return (
    <>
      {isMobileView ? (
        <>
          <section className='mob-footer-section'>
            <div className='liner-shade'>
              <div className='container'>
                <a href='index.html'>
                  <img
                    src={'/images/prime-trader-footerlogo.svg'}
                    alt='prime-trader-logo'
                  />
                </a>
                <p className='footer-passage'>
                  A revolution in the world of crypto and blockchain.
                  PrimeTrader offers people across the cryptosphere the thrill
                  of gamified social crypto trading, powered by cutting-edge AI.
                </p>
                <div className='footer-links'>
                  <div className='footer-links-left'>
                    <ul>
                      <li>
                        <a href='#'>Home</a>
                      </li>
                      <li>
                        <a href='#game'>Games</a>
                      </li>
                      <li>
                        <a href='#Ready'>Traders</a>
                      </li>
                    </ul>
                  </div>
                  <div className='footer-links-right'>
                    <ul>
                      <li>
                        <a href='#roadmap'>Roadmap</a>
                      </li>
                      <li>
                        <a href='#protocol'>Docs</a>
                      </li>
                      <li>
                        <a href='#ReadyNFT'>NFT Builder</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='footer-social-box'>
                  <div className='footer-icons1'>
                    <a href={FooterSocialLinksData.facebook}>
                      <img
                        src='/images_p/footer/facbook.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.instagram}>
                      <img
                        src='/images_p/footer/instagram.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.linkedin}>
                      <img
                        src='/images_p/footer/linkedin.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.email}>
                      <img
                        src='/images_p/footer/email.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>
                  </div>
                  <div className='footer-icons2'>
                    <a href={FooterSocialLinksData.twitter}>
                      <img
                        src='/images_p/footer/twitter.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.discord}>
                      <img
                        src='/images_p/footer/discord.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '}
                    {/* <a href={FooterSocialLinksData.telegram}>
                      <img
                        src='/images_p/footer/telegram.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '} */}
                    <a href={FooterSocialLinksData.medium}>
                      <img
                        src='/images_p/footer/medium.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='medium'
                      />
                    </a>
                  </div>
                </div>
                <p className='copyright'>
                  © 2024 Prime Trader. All Rights Reserved. Terms of
                  Service.Privacy Policy.CCPA.
                </p>
              </div>
            </div>
          </section>
        </>
      ) : (
        <section className='footer-section'>
          <div className='liner-shade'>
            <div className='container'>
              <div className='flex-box'>
                <div className='footer-logo-box'>
                  <a href='index.html'>
                    <img
                      src={'/images/prime-trader-footerlogo.svg'}
                      alt='prime-trader-logo'
                    />
                  </a>
                  <p className='footer-passage'>
                    A revolution in the world of crypto and blockchain.
                    PrimeTrader offers people across the cryptosphere the thrill
                    of gamified social crypto trading, powered by cutting-edge
                    AI.
                  </p>
                  <p className='copyright'>
                    © 2024 Prime Trader. All Rights Reserved. Terms of
                    Service.Privacy Policy.CCPA.
                  </p>
                </div>
                <div className='footer-links'>
                  <ul>
                    <li>
                      <a href='#'>Home</a>
                    </li>
                    <li>
                      <a href='#game'>Games</a>
                    </li>
                    <li>
                      <a href='#Ready'>Traders</a>
                    </li>
                    <li>
                      <a href='#ReadyNFT'>NFT Builder</a>
                    </li>
                  </ul>
                </div>
                <div className='footer-links'>
                  <ul>
                    <li>
                      <a href='#roadmap'>Roadmap</a>
                    </li>
                    <li>
                      <a href='#protocol'>Docs</a>
                    </li>
                  </ul>
                </div>
                <div className='footer-social-box'>
                  <div className='footer-icons1'>
                    <a href={FooterSocialLinksData.facebook}>
                      <img
                        src='/images_p/footer/facbook.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.instagram}>
                      <img
                        src='/images_p/footer/instagram.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.linkedin}>
                      <img
                        src='/images_p/footer/linkedin.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.email}>
                      <img
                        src='/images_p/footer/email.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>
                  </div>
                  <div className='footer-icons2'>
                    <a href={FooterSocialLinksData.twitter}>
                      <img
                        src='/images_p/footer/twitter.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.discord}>
                      <img
                        src='/images_p/footer/discord.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '}
                    {/* <a href={FooterSocialLinksData.telegram}>
                      <img
                        src='/images_p/footer/telegram.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '} */}
                    <a href={FooterSocialLinksData.medium}>
                      <img
                        src='/images_p/footer/medium.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='medium'
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Footer;
