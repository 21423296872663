
// type defination
export interface FooterSocialLinks {
  twitter: string;
  instagram: string;
  linkedin: string;
  facebook: string;
  email: string;
  discord: string;
  // telegram: string;
  medium: string;
}

// list of members
const FooterSocialLinksData: FooterSocialLinks = {
  facebook: "https://www.facebook.com/PrimeTrader.PT",
  email: 'mailto:contact@primetrader.com',
  twitter: "https://twitter.com/PrimeTraderX",
  instagram: "https://www.instagram.com/primetraderx/",
  linkedin: "https://www.linkedin.com/company/primetraderx",
  discord: 'https://discord.gg/WKfzBp6ETC',
  // telegram: 'https://telegram.org/',
  medium: 'https://medium.com/@PrimeTraderX',
}

export default FooterSocialLinksData;
