import { useState, useEffect } from "react";
import {
  preloadArm,
  preloadCharacters,
  preloadClothing,
  preloadHeadGear,
  preloadFaces,
} from "../assetsPreLoading";
import { metadata } from "../data";

export const usePreloadAssets = ({
  category,
  categoryItem,
  setLoadingAssets,
  updateFace,
  updateBody,
  updateCloth,
  updateHead,
  setLoadedCharacters,
  setLoadedFaces,
  setLoadedArm,
  setLoadedClothing,
  setLoadedHeadGear,
}) => {

  useEffect(() => {
    const loadAssets = async () => {
      // Load assets based on the selected category
      switch (category) {
        case "Characters":
          await preloadCharacters(metadata.Characters, setLoadedCharacters);
          break;
        case "Face":
          const face = await preloadFaces(
            categoryItem,
            setLoadedFaces,
            setLoadingAssets
          );
          updateFace({ scene: face, path: categoryItem?.key });
          setLoadingAssets(false);
          break;

        case "Head Gear":
          const head = await preloadHeadGear(
            categoryItem,
            setLoadedHeadGear,
            setLoadingAssets
          );
          updateHead({ scene: head, path: categoryItem?.key });
          setLoadingAssets(false);
          break;

        case "Arm":
          const arm = await preloadArm(
            categoryItem,
            setLoadedArm,
            setLoadingAssets
          );
          updateBody({ scene: arm, path: categoryItem.key });
          setLoadingAssets(false);
          break;

        case "Clothing":
          const cloth = await preloadClothing(
            categoryItem,
            setLoadedClothing,
            setLoadingAssets
          );
          updateCloth({ scene: cloth, path: categoryItem.key });
          setLoadingAssets(false);
          break;

        default:
          console.error("Unknown category");
      }
    };

    // Trigger asset loading when the category changes
    loadAssets();
  }, [categoryItem]);

};
