'use client';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import Navbar from '@/components/Navbar/Navbar';
import Hero from '@/components/HeroSection/Hero';
import HowToTrade from '@/components/HowToPrimeTrade/HowToTrade';
import MeetTrading from '@/components/MeetTrading';
import EcoGettingStarted from '@/components/GettingStartedWithBridging/EcoGettingStarted';
import EcoSystem from '@/components/EcoSystem';
import RoadMap from '@/components/RoadMap/RoadMap';
import ReadyTrade from '@/components/ReadyTrade';
import Footer from '@/components/Footer/Footer';
const CarouselTeam = lazy(
  () => import('@/components/CoreTeamPinnedWheel/CoreTeamPinnedWheel')
);
import Avatar3D from '@/components/Avatar3d';
import PtNumbers from '@/components/PtNumbers/PtNumbers';
import ContactUs from '@/components/ContactUs/Contactus';
import Loader from '@/components/Loader';

export default function Home() {
  if (typeof window !== 'undefined') {
    console.log('do nothing');
  } else {
    return;
  }
  const [isMobileView, setIsMobileView] = useState(
    window.matchMedia('(max-width: 767px)').matches ? true : false
  );
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
      document.body.style.visibility = 'visible';
    }
    // handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <main>
      <section className='top-section'>
        <div className='liner-shade'>
          {/* <HalvingCountdown
            targetDate={new Date('2024-05-08T18:00:00Z')}
            isMobileView={isMobileView}
          /> */}
          <div className='container'>
            <Navbar isMobileView={isMobileView} />
          </div>
        </div>
      </section>
      <Hero isMobileView={isMobileView} />
      <PtNumbers />
      <HowToTrade isMobileView={isMobileView} />
      <MeetTrading isMobileView={isMobileView} />
      <EcoSystem isMobileView={isMobileView} />
      <EcoGettingStarted isMobileView={isMobileView} />
      <RoadMap isMobileView={isMobileView} />
      <Avatar3D isMobileView={isMobileView} />
      <ReadyTrade isMobileView={isMobileView} />
      <Suspense fallback={<div>Loading...</div>}>
        <CarouselTeam isMobileView={isMobileView} />
      </Suspense>
      <ContactUs isMobileView={isMobileView} setLoading={setLoading} />
      <Footer isMobileView={isMobileView} />
      <Loader isLoading={isLoading} />
    </main>
  );
}
