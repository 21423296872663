import React from 'react';
import styles from '../../styles/builder/nftbuildermob.module.scss';

export const RightSidebarMob = ({
  category,
  updateCategory,
  metadata,
  characterCode,
  rightSidebar,
  characterSelection,
  updateCurrentCategoryItem,
  assetsLoading,
  undo,
  redo,
  undoDisable,
  redoDisable,
  characterSelectionScreen,
  characters,
  handleButtonClickChar,
  selectedButton,
  setSelectedButton
}) => {
  const handleButtonClick = (index) => {
    setSelectedButton(index);
    updateCurrentCategoryItem(metadata[category][index]);
  };

  const handleNextButtonClick = () => {
    redo();
  };

  const handlePreviousButtonClick = () => {
    undo();
  };

  return (
    rightSidebar && (
      <>
        {!characterSelection && !characterSelectionScreen && (
          <div className={styles.avatarList}>
            {metadata[category].map((item, index) => (
              <div
                key={index}
                style={{
                  display: item.key.includes(characterCode) ? '' : 'none'
                }}
                className={
                  selectedButton === index
                    ? `${styles.avaterItem} ${styles.active}`
                    : styles.avaterItem
                }
                onClick={() =>
                  !assetsLoading &&
                  selectedButton !== index &&
                  handleButtonClick(index)
                }
              >
                <img src={item.icon} alt='icon' />
              </div>
            ))}
          </div>
        )}

        {characterSelectionScreen && (
          <div className={styles.avatarList}>
            {characters.map((character, idx) => (
              <div
                key={idx}
                className={`${styles.avaterItem} ${
                  selectedButton === idx ? styles.active : ''
                }`}
                onClick={() => {
                  if (!assetsLoading) {
                    setSelectedButton(idx);
                    handleButtonClickChar(character);
                  }
                }}
              >
                <img
                  src={character.icon}
                  alt='icon'
                  style={{
                    marginTop: character.icon.includes('charR') ? '10px' : '5px'
                  }}
                />
              </div>
            ))}
          </div>
        )}

        {!characterSelectionScreen &&
          metadata.categoryVariants[category].length > 0 && (
            <div className={styles.avatarNoVarient}>
              <h1>Variants</h1>
              <div className={styles.list}>
                {metadata.categoryVariants[category].map((item, index) => (
                  <img
                    className={styles.item}
                    key={index}
                    src={item}
                    alt={`Variant ${index}`}
                  />
                ))}
              </div>
            </div>
          )}
      </>
    )
  );
};
