import React, { useState, useEffect } from 'react';
import styles from '../../styles/builder/nftbuildermob.module.scss';

const ForwardButtonArrow = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      style={{ cursor: 'pointer' }}
    >
      <path
        d='M10.6974 3.19741C10.3108 2.81077 9.68518 2.80667 9.29351 3.18821C8.89518 3.57623 8.89023 4.21478 9.28248 4.60893L14.15 9.5H3C2.44772 9.5 2 9.94772 2 10.5C2 11.0523 2.44772 11.5 3 11.5H14.15L9.27874 16.3652C8.88751 16.7559 8.89458 17.3969 9.28597 17.7875C9.67662 18.1773 10.3161 18.1839 10.7064 17.7936L17.2929 11.2071C17.6834 10.8166 17.6834 10.1834 17.2929 9.79289L10.6974 3.19741Z'
        fill='white'
        fillOpacity='0.3'
      />
    </svg>
  );
};

const BackButtonArrow = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      style={{ cursor: 'pointer' }}
    >
      <path
        d='M10.3026 3.19741C10.6892 2.81077 11.3148 2.80667 11.7065 3.18821C12.1048 3.57623 12.1098 4.21478 11.7175 4.60893L6.85 9.5H18C18.5523 9.5 19 9.94772 19 10.5C19 11.0523 18.5523 11.5 18 11.5H6.85L11.7213 16.3652C12.1125 16.7559 12.1054 17.3969 11.714 17.7875C11.3234 18.1773 10.6839 18.1839 10.2936 17.7936L3.70711 11.2071C3.31658 10.8166 3.31658 10.1834 3.70711 9.79289L10.3026 3.19741Z'
        fill='white'
        fillOpacity='0.3'
      />
    </svg>
  );
};

export const LeftSidebarMob = ({
  leftSidebar,
  categories,
  selectedCategory,
  handleButtonClick,
  assetsLoading,
  characterSelectionScreen
}) => {
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const initialVisibleItemCount = 2;

  useEffect(() => {
    const selectedIndex = categories.indexOf(selectedCategory);
    if (selectedIndex !== -1) {
      const halfVisibleItems = Math.floor(initialVisibleItemCount / 2);
      const newStartIndex = Math.max(
        0,
        Math.min(
          selectedIndex - halfVisibleItems, // Center the selected item
          categories.length - initialVisibleItemCount
        )
      );
      setVisibleStartIndex(newStartIndex);
    }
  }, [selectedCategory, categories]);

  const handleBackClick = () => {
    const selectedIndex = categories.indexOf(selectedCategory);
    if (selectedIndex > 0) {
      const newSelectedIndex = selectedIndex - 1;
      handleButtonClick(newSelectedIndex);
      setVisibleStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
  };

  const handleForwardClick = () => {
    const selectedIndex = categories.indexOf(selectedCategory);
    if (selectedIndex < categories.length - 1) {
      const newSelectedIndex = selectedIndex + 1;
      handleButtonClick(newSelectedIndex);
      setVisibleStartIndex((prevIndex) =>
        Math.min(prevIndex + 1, categories.length - initialVisibleItemCount)
      );
    }
  };

  const visibleItemCount = characterSelectionScreen
    ? initialVisibleItemCount
    : 3;

  const visibleCategories = categories.slice(
    visibleStartIndex,
    visibleStartIndex + visibleItemCount
  );

  const getButtonText = (button) => {
    if (!button) {
      return 'Character';
    }

    switch (button) {
      case 'Arm':
        return 'Body';
      case 'Characters':
        return 'Character';
      default:
        return button;
    }
  };

  return (
    leftSidebar && (
      <div className={styles.categoriesNav}>
        <BackButtonArrow
          onClick={characterSelectionScreen ? null : handleBackClick}
        />
        <div
          className={
            characterSelectionScreen
              ? styles.intialItemList
              : styles.afterIntialItemList
          }
          style={{
            display: 'flex',
            justifyContent:
              visibleCategories.length === 2 && !characterSelectionScreen
                ? 'flex-start'
                : ''
          }}
        >
          {visibleCategories.map((button, index) => {
            const actualIndex = visibleStartIndex + index;
            return (
              <div
                key={actualIndex}
                onClick={
                  characterSelectionScreen
                    ? null
                    : () => !assetsLoading && handleButtonClick(actualIndex)
                }
                className={
                  categories[actualIndex] === selectedCategory
                    ? styles.item
                    : styles.itemInactive
                }
              >
                {getButtonText(button)}
              </div>
            );
          })}
        </div>
        <ForwardButtonArrow
          onClick={characterSelectionScreen ? null : handleForwardClick}
        />
      </div>
    )
  );
};
